import React from "react";

function USDFigure({ amount }) {
  if (!amount) amount = 0;
  return (
    <p style={{ color: amount > 0 ? "green" : "red" }}>
      {/* {amount?.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })} */}
      USD {amount?.toFixed(2)?.toLocaleString("en-US")}
    </p>
  );
}

export default USDFigure;
