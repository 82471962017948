import { NavLink } from 'react-router-dom';

export const CardComponent = ({ link, title, icon, count }) => {
  return (
    <div className="col-lg-3 col-sm-6 col-12">
      <NavLink to={link} className="w-100">
        <div className="dash-widget">
          <div className="dash-widgetimg">
            <span>
              <img src={icon} alt="img" />
            </span>
          </div>
          <div className="dash-widgetcontent">
            <h5>
              <span className="counters" data-count={count}>
                {count}
              </span>
            </h5>
            <h6>{title}</h6>
          </div>
        </div>
      </NavLink>
    </div>
  );
};
