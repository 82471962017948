import React, { useContext, useState } from 'react';
import { permit } from '../../helpers/Authorize';
import { Edit } from '../../Components/Icons/Buttons';
import { userPermissions } from '../../config/EnVariables';
import { AuthContext } from '../../Auth/Context/AuthContext';
import ProjectModal from './Components/ProjectModal';
import { colors } from '../../config/theme';

function BranchesList(props) {
  const { branches } = useContext(AuthContext);
  const [updatingProject, setUpdatingProject] = useState();
  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-success"
              data-target="#projectModal"
              data-toggle="modal"
              data-whatever="@mdo"
            >
              <i className="ion-plus" /> Create New Branch
            </button>
          </div>
          <ProjectModal
            updatingProject={updatingProject}
            onClose={() => setUpdatingProject(null)}
            setUpdatingProject={setUpdatingProject}
          />
          <div className="row">
            <div className="col-sm-12">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead style={{ background: colors.primary }}>
                    <tr>
                      <th>Project ID</th>
                      <th>Project Name</th>
                      <th>Base Currency</th>
                      <th>Business Type</th>
                      <th>Created Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {branches?.map((item, i) => {
                      return (
                        <tr key={i + 1}>
                          <td>{item.id}</td>
                          <td>
                            <a
                              className="col-lg-3 col-sm-6 col-12 d-flex"
                              href={`/branch-details/${item.id}`}
                            >
                              {item.headQuarter ? (
                                <p style={{ color: 'green' }}>(HQ)</p>
                              ) : (
                                ''
                              )}
                              &nbsp;&nbsp;
                              {item.name}
                            </a>
                          </td>
                          <td>
                            {item.baseCurrencyName === 'USD' ? (
                              <span className="badge badge-pill badge-info">
                                {item.baseCurrencyName}
                              </span>
                            ) : (
                              <span className="badge badge-pill badge-secondary">
                                {item.baseCurrencyName}
                              </span>
                            )}
                          </td>
                          <td>{item.businessType}</td>
                          <td>{item.createdDate}</td>
                          <td>
                            {permit(userPermissions.update) && (
                              <a
                                data-target="#projectModal"
                                data-toggle="modal"
                                data-whatever="@mdo"
                                onClick={() => setUpdatingProject(item)}
                              >
                                <Edit />
                              </a>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BranchesList;
