import React, { useEffect, useState } from 'react';
import Account from '../../../Services/accountServices';
import USDFigure from '../../../Components/USDFigure';
import AFNFigure from '../../../Components/AFNFigure';
import TitleHeader from '../Components/TitleHeader';
import Loader from '../../../Components/Loader';
function JournelLedger(props) {
  const [ledgers, setLedgers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ledgersCopy, setLedgersCopy] = useState([]);
  const [searchedValue, setSearchedValue] = useState();
  const fetchLedgers = async () => {
    setSearchedValue('');
    setLoading(true);
    await Account.getGeneralLedger().then((ledger) => {
      setLedgers(ledger.data);
      setLedgersCopy(ledger.data);
      setLoading(false);
    });
  };
  useEffect(() => {
    fetchLedgers();
  }, []);
  const filterAccount = async () => {
    if (!searchedValue) return;
    setLoading(true);
    await Account.filterAccount(searchedValue)
      .then((response) => {
        setLedgers(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const filterLocalAccounts = (value) => {
    setSearchedValue(value);
    if (value === '') {
      setLedgers(ledgersCopy);
    } else {
      const filteredAccounts = ledgersCopy.filter((op) =>
        op.accountName.toLowerCase().includes(value.toLowerCase()),
      );
      setLedgers(filteredAccounts);
    }
  };
  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <TitleHeader title={`General Ledger-(${ledgers?.length})`} />

        <div className="row">
          <div className="col-sm-12">
            <div className="table-responsive">
              <div className="form-group d-flex">
                <input
                  type="text"
                  name="search"
                  placeholder="Type account name you want . . ."
                  value={searchedValue}
                  onChange={(e) => filterLocalAccounts(e.target.value)}
                />

                <button className="btn btn-primary" onClick={filterAccount}>
                  <i className="ion-search" /> Search
                </button>
                <button className="btn btn-dark" onClick={fetchLedgers}>
                  <i className="ion-loop" /> Reset
                </button>
              </div>
              <table className="w-100 table-striped">
                <thead className="table-bg">
                  <tr>
                    <th>Account</th>
                    <th>Balance</th>
                  </tr>
                </thead>
                <Loader loading={loading} />
                <tbody>
                  {ledgers.length > 0 ? (
                    ledgers?.map((item, i) => {
                      return (
                        <tr key={i + 1}>
                          <td>{item.accountName || item.name}</td>
                          <td>
                            {item.accountName.includes('AFN') ? (
                              <AFNFigure amount={item.balance} />
                            ) : (
                              <USDFigure amount={item.balance} />
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <p>No Data</p>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JournelLedger;
