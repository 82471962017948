import React from "react";

function AreaUnit({ area }) {
  return (
    <div>
      {area}m<sup>2</sup>
    </div>
  );
}

export default AreaUnit;
