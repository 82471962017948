import { Popconfirm } from 'antd';
import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Delete } from './Icons/Buttons';

function DeleteConfirmation({ onConfirm, itemName }) {
  return (
    <Popconfirm
      title="Delete Record"
      description={`Are you sure to delete ${itemName}?`}
      onConfirm={onConfirm}
      okText="Yes"
      okType="btn btn-danger"
      cancelText="No"
      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
    >
      <Delete />
    </Popconfirm>
  );
}

export default DeleteConfirmation;
