import React, { useEffect, useState } from 'react';
import Account from '../../Services/accountServices';
import { NavLink } from 'react-router-dom';
import TitleHeader from './Components/TitleHeader';
import { message } from 'antd';
import { branchId, userPermissions } from '../../config/EnVariables';
import { permit } from '../../helpers/Authorize';
import { amount } from '../../helpers/quick';
import AccountModal from './Components/AccountModal';
import DeleteConfirmation from '../../Components/DeleteConfirmation';
import Loader from '../../Components/Loader';
function AccountsList() {
  const [accounts, setAccounts] = useState([]);
  const [accountsCopy, setAccountsCopy] = useState([]);
  const [searchedValue, setSearchedValue] = useState();
  const [loading, setLoading] = useState(false);
  const [expandedAccounts, setExpandedAccounts] = useState([]);
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const [updatingAccount, setUpdatingAccount] = useState();
  const fetchAllAccounts = async () => {
    setLoading(true);
    await Account.getExpandingAccounts(branchId).then((acc) => {
      setAccounts(acc.data);
      setAccountsCopy(acc.data);
      setLoading(false);
      toggleAllRows(acc.data);
    });
  };
  useEffect(() => {
    fetchAllAccounts();
  }, []);
  const filterAccount = async () => {
    if (!searchedValue) return;
    setLoading(true);
    await Account.filterAccount(searchedValue)
      .then((response) => {
        setLoading(false);
        setAccounts(response.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const deleteAccount = (account) => {
    if (account.balance > 0)
      return message.warning(
        `You can not delete the account. as it has balance of ${account.balance}`,
      );
    else
      message.success(
        `${account.id} Account will be deleted once the feature is added`,
      );
  };
  const filterLocalAccounts = (value) => {
    setSearchedValue(value);
    if (value === '') {
      setAccounts(accountsCopy);
    } else {
      const filteredAccounts = accountsCopy.filter((op) =>
        op.name.toLowerCase().includes(value.toLowerCase()),
      );
      setAccounts(filteredAccounts);
    }
  };

  const toggleAllRows = (data) => {
    const list = data || accounts;
    if (isAllExpanded) {
      setExpandedAccounts([]);
    } else {
      const allAccountIds = list?.map((acc) => acc.id);
      setExpandedAccounts(allAccountIds);
    }
    setIsAllExpanded(!isAllExpanded);
  };
  const toggleAccount = (accountId) => {
    if (expandedAccounts.includes(accountId)) {
      setExpandedAccounts(expandedAccounts.filter((id) => id !== accountId));
    } else {
      setExpandedAccounts([...expandedAccounts, accountId]);
    }
  };
  const renderAccounts = (accounts, level = 0) => {
    return accounts?.map((acc) => (
      <>
        <tr>
          <td style={{ paddingLeft: level * 20 }}>
            <a key={acc.id} onClick={() => toggleAccount(acc.id)}>
              {acc.subAccountArray?.length > 0 && (
                <i
                  class={
                    expandedAccounts.includes(acc.id)
                      ? 'ion-arrow-down-b'
                      : 'ion-arrow-right-b'
                  }
                ></i>
              )}
            </a>
            &nbsp;&nbsp;
            <NavLink
              style={{ color: '#000' }}
              to={`/account-quick-report/${acc.id}`}
            >
              {acc?.code}-{acc.name}
            </NavLink>
          </td>
          <td>
            <p>{acc.generalAccountCategory}</p>
          </td>
          <td>
            {acc.currencyName === 'USD' ? (
              <span className="badge badge-pill badge-info">
                {acc.currencyName}
              </span>
            ) : (
              <span className="badge badge-pill badge-secondary">
                {acc.currencyName}
              </span>
            )}
          </td>
          <td>
            {acc.balance > 0 ? (
              <p style={{ color: 'green' }}>{amount(acc.balance)}</p>
            ) : (
              <p style={{ color: 'red' }}>{amount(acc.balance)}</p>
            )}
          </td>
          <td>
            <td>
              {permit(userPermissions.update) && (
                // <Edit onClick={() => navigate(`/add-account/${acc.id}`)} />
                <a
                  data-target="#printModal"
                  data-toggle="modal"
                  data-whatever="@mdo"
                  onClick={() => {
                    setUpdatingAccount(acc);
                  }}
                >
                  <img src="assets/img/icons/edit.svg" />
                </a>
              )}
              {permit(userPermissions.delete) && (
                // <Popconfirm
                //   title="Delete the Account"
                //   description={`Are you sure to delete ${acc.name}?`}
                //   onConfirm={() => deleteAccount(acc)}
                //   okText="Yes"
                //   cancelText="No"
                //   icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                // >
                //   <Delete />
                // </Popconfirm>
                <DeleteConfirmation
                  onConfirm={() => deleteAccount(acc)}
                  itemName={acc.name}
                />
              )}
            </td>
          </td>
        </tr>
        {expandedAccounts.includes(acc.id) &&
          acc.subAccountArray?.map((sub) => (
            <>
              <tr key={sub.id}>
                <td style={{ paddingLeft: (level + 1) * 20 }}>
                  {sub.subAccountArray?.length > 0 && (
                    <i
                      class={
                        expandedAccounts.includes(sub.id)
                          ? 'ion-arrow-down-b'
                          : 'ion-arrow-right-b'
                      }
                    ></i>
                  )}
                  &nbsp;&nbsp;
                  <NavLink
                    to={`/account-quick-report/${sub.id}`}
                    style={{ color: '#000' }}
                  >
                    {sub?.code}-{sub.name}
                  </NavLink>
                </td>
                <td>
                  <p>{sub.generalAccountCategory}</p>
                </td>
                <td>
                  {sub.currencyName === 'USD' ? (
                    <span className="badge badge-pill badge-info">
                      {sub.currencyName}
                    </span>
                  ) : (
                    <span className="badge badge-pill badge-secondary">
                      {sub.currencyName}
                    </span>
                  )}
                </td>
                <td>
                  {sub.balance > 0 ? (
                    <p style={{ color: 'green' }}>{amount(sub.balance)}</p>
                  ) : (
                    <p style={{ color: 'red' }}>{amount(sub.balance)}</p>
                  )}
                </td>
                <td>
                  {permit(userPermissions.update) && (
                    <a
                      data-target="#printModal"
                      data-toggle="modal"
                      data-whatever="@mdo"
                      onClick={() => {
                        setUpdatingAccount(sub);
                      }}
                    >
                      <img src="assets/img/icons/edit.svg" />
                    </a>
                  )}
                  {permit(userPermissions.delete) && (
                    <DeleteConfirmation
                      onConfirm={() => deleteAccount(sub)}
                      itemName={sub.name}
                    />
                  )}
                </td>
              </tr>
              {sub.subAccountArray &&
                renderAccounts(sub.subAccountArray, level + 2)}
            </>
          ))}
      </>
    ));
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <TitleHeader title={`Charts of Account-(${accounts.length})`} />
          <div className="row">
            <AccountModal
              updatingAccount={updatingAccount}
              setUpdatingAccount={setUpdatingAccount}
              getAccounts={fetchAllAccounts}
              accounts={accounts}
            />
            <div className="col-sm-12">
              <div className="table-responsive">
                <div className="form-group d-flex">
                  <input
                    type="text"
                    name="search"
                    placeholder="Type account name you want . . ."
                    onChange={(e) => filterLocalAccounts(e.target.value)}
                  />
                  <button className="btn btn-primary" onClick={filterAccount}>
                    <i className="ion-search" /> Search
                  </button>
                  <button className="btn btn-dark" onClick={fetchAllAccounts}>
                    <i className="ion-loop" /> Reset
                  </button>
                  <button
                    // to="/add-account"
                    className="btn btn-success"
                    data-target="#printModal"
                    data-toggle="modal"
                    data-whatever="@mdo"
                  >
                    + Add
                  </button>
                  <button
                    onClick={() => toggleAllRows()}
                    className="btn btn-dark"
                  >
                    {isAllExpanded ? 'Collapse All' : 'Expand All'}
                  </button>
                </div>
                <Loader loading={loading} />
                <table className="w-100 table-striped">
                  <thead className="table-bg">
                    <tr>
                      <th>Account</th>
                      <th>Type</th>
                      <th>Currency</th>
                      <th>Balance</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {accounts.length > 0 ? (
                      renderAccounts(accounts)
                    ) : (
                      <p>No Data</p>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountsList;
