import React, { useContext, useEffect, useState } from 'react';

import ExportComponents from '../../../Components/ExportComponents';
import Account from '../../../Services/accountServices';
import USDFigure from '../../../Components/USDFigure';
import { baseUrl, userRoles } from '../../../config/EnVariables';
import { AuthContext } from '../../../Auth/Context/AuthContext';
import AFNFigure from '../../../Components/AFNFigure';
import TitleHeader from '../Components/TitleHeader';
import { Edit } from '../../../Components/Icons/Buttons';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../Components/Loader';
function GenralJournel(props) {
  const { user } = useContext(AuthContext);
  const [downloadableData, setDownloadableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [journals, setJournals] = useState([]);
  const navigate = useNavigate();
  const fetchJournals = async () => {
    setLoading(true);
    await Account.getJournals().then((jls) => {
      const jours = jls.data.map((journal) => {
        const filteredLines = journal.lines.filter((line) => {
          const accountName = line.accountName || '';
          return !accountName.includes('Position Account');
        });
        return { ...journal, lines: filteredLines };
      });
      const downloadingData = jours.map((item) =>
        item.lines.map((line) => ({
          transactionID: item.name,
          date: line.date,
          account: line.accountName,
          memo: line.note,
          debit: line.debit,
          credit: line.credit,
        })),
      );

      let arr = [];
      for (let i = 0; i < downloadingData.length; i++) {
        for (let j = 0; j < downloadingData[i].length; j++) {
          arr.push(downloadingData[i][j]);
        }
      }
      setLoading(false);
      setDownloadableData(arr);
      setJournals(jours);
    });
  };
  useEffect(() => {
    fetchJournals();
  }, []);

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <TitleHeader title="General Journal" />
        <div className="row">
          <div className="col-sm-12">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex' }}></div>
              <ExportComponents
                excelData={downloadableData}
                fileName={new Date() + 'Journals.xlsx'}
              />
            </div>
            <div className="table-responsive">
              <table className="w-100 table-striped">
                <thead className="table-bg">
                  <tr>
                    <th>
                      <i
                        className="ion-link"
                        data-bs-toggle="tooltip"
                        title="Attachment"
                      />{' '}
                      Attachment
                    </th>
                    <th>Transaction</th>
                    <th>Type</th>
                    <th>Date</th>
                    <th>Memo</th>
                    <th>Accounts</th>
                    <th>Dr</th>
                    <th>Cr</th>
                    {/* {user.role === userRoles.superadmin && <th>Action</th>} */}
                  </tr>
                </thead>
                {loading ? (
                  <Loader loading={loading} />
                ) : (
                  <tbody>
                    {journals.length > 0 ? (
                      journals?.map((parentRecord, parentIndex) => (
                        <React.Fragment key={parentIndex}>
                          {/* First sub-record */}
                          <tr>
                            <td>
                              {parentRecord.attachment ||
                              parentRecord.attachmentName ? (
                                <th>
                                  <a
                                    href={`${baseUrl}/download?fileName=${parentRecord.attachmentName}&entity=general%20journal`}
                                    target="_blank"
                                  >
                                    <i
                                      className="ion-link"
                                      data-bs-toggle="tooltip"
                                      title="Attachment"
                                    ></i>
                                  </a>
                                </th>
                              ) : (
                                <th></th>
                              )}
                            </td>
                            <td>{parentRecord.name}</td>
                            <td>{parentRecord.transactionType}</td>

                            <td>{parentRecord.date}</td>
                            <td>{parentRecord.note}</td>
                            <td>{parentRecord.lines[0]?.accountName}</td>
                            <td>
                              {parentRecord.lines[0]?.debit > 0 ? (
                                parentRecord.lines[0].accountName?.includes(
                                  'AFN',
                                ) ? (
                                  <AFNFigure
                                    amount={parentRecord.lines[0]?.debit}
                                  />
                                ) : (
                                  <USDFigure
                                    amount={parentRecord.lines[0]?.debit}
                                  />
                                )
                              ) : (
                                ''
                              )}
                            </td>
                            <td>
                              {parentRecord.lines[0]?.credit > 0 ? (
                                parentRecord.lines[0].accountName?.includes(
                                  'AFN',
                                ) ? (
                                  <AFNFigure
                                    amount={parentRecord.lines[0]?.credit}
                                  />
                                ) : (
                                  <USDFigure
                                    amount={parentRecord.lines[0]?.credit}
                                  />
                                )
                              ) : (
                                ''
                              )}
                            </td>
                            {user.role === userRoles.superadmin && false && (
                              <td>
                                <Edit
                                  onClick={() =>
                                    navigate(
                                      `/journel-entry/${parentRecord.id}`,
                                    )
                                  }
                                />
                              </td>
                            )}
                          </tr>

                          {/* Second sub-record */}
                          {parentRecord.lines?.length > 0 &&
                            parentRecord.lines?.map(
                              (rec, index) =>
                                index != 0 && (
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>{rec?.accountName}</td>
                                    <td>
                                      {rec?.debit > 0 ? (
                                        rec.accountName?.includes('AFN') ? (
                                          <AFNFigure amount={rec?.debit} />
                                        ) : (
                                          <USDFigure amount={rec?.debit} />
                                        )
                                      ) : (
                                        ''
                                      )}
                                    </td>
                                    <td>
                                      {rec?.credit > 0 ? (
                                        rec.accountName?.includes('AFN') ? (
                                          <AFNFigure amount={rec?.credit} />
                                        ) : (
                                          <USDFigure amount={rec?.credit} />
                                        )
                                      ) : (
                                        ''
                                      )}
                                    </td>
                                  </tr>
                                ),
                            )}
                        </React.Fragment>
                      ))
                    ) : (
                      <p>No Data</p>
                    )}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenralJournel;
