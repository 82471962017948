import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import {
  AntdSelectField,
  InputField,
} from '../../../Components/InputFormFields';
import { MyButton } from '../../../Components/MyButton';
import Watermark from '../../../Components/Watermark';
import { branchId } from '../../../config/EnVariables';
import { colors } from '../../../config/theme';
import { message } from 'antd';
import Shop from '../../../Services/shopServices';
import { addContractSchemaValidation } from '../../../config/Validations';
import { getContractEndDate, getTodayDate } from '../../../helpers/quick';
import Shopkeeper from '../../../Services/shopkeeperServices';
import { contractTypeOptions } from '../../../Utils/options';

function ContractModal({ getContracts, shop }) {
  const [loading, setLoading] = useState(false);
  const [shopkeepers, setShopkeepers] = useState([]);

  const initialValues = {
    shopkeeperId: shop?.shopkeeperId || '',
    shopId: shop?.id || '',
    contractType: '',
    note: '',
    totalPrice: null,
    monthlyRent: null,
    monthlyMaintenanceFee: null,
    feePerSquareMeter: null,
    date: getTodayDate(),
    startDate: '',
    endDate: '',
  };

  const addContract = (values) => {
    setLoading(true);
    const data = {
      ...values,
      shopId: shop?.id || values.shopId,
      contractDate: new Date(values.date).toISOString(),
      startDate: new Date(values.startDate).toISOString(),
      endDate:
        (values.endDate && new Date(values.endDate).toISOString()) || null,
      branchId: branchId,
    };
    Shop.addContract(data)
      .then((data) => {
        message.success('Contract Made Successfully');
        setLoading(false);
        getContracts();
      })
      .catch((err) => {
        message.error('Contract Not Made');
        setLoading(false);
        console.log(err);
      });
  };

  const getAllShopkeepers = async () => {
    await Shopkeeper.getAll(99999999).then((shops) => {
      const available = shops.data?.filter((op) => !op.activeShopsList);
      const updatedData = available?.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setShopkeepers(updatedData);
    });
  };
  useEffect(() => {
    getAllShopkeepers();
  }, []);

  return (
    <div
      className="modal fade"
      id="contractModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="contractModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header" style={{ background: colors.blue }}>
            <h5 className="modal-title" id="contractModalLabel">
              Make New Contract for {shop?.name || ''}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Watermark />
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={addContract}
              validationSchema={addContractSchemaValidation}
            >
              {({ errors, touched, values, setFieldValue, handleSubmit }) => (
                <>
                  <div className="row">
                    <AntdSelectField
                      label="Shopkeeper"
                      name="shopkeeperId"
                      options={shopkeepers}
                      onChange={(value) => setFieldValue('shopkeeperId', value)}
                      required={true}
                      errors={errors['shopkeeperId']}
                      touched={touched['shopkeeperId']}
                    />

                    <AntdSelectField
                      label="Contract Type"
                      name="contractType"
                      options={contractTypeOptions}
                      onChange={(value) => {
                        setFieldValue('contractType', value);
                        setFieldValue('endDate', getContractEndDate(value));
                      }}
                      required={true}
                      errors={errors['contractType']}
                      touched={touched['contractType']}
                    />

                    <InputField
                      name="date"
                      label="Date"
                      type="date"
                      value={values.date}
                      required={true}
                      errors={errors['date']}
                      touched={touched['date']}
                    />

                    <InputField
                      name="startDate"
                      label="Contract Start Date"
                      type="date"
                      value={values.startDate}
                      onChange={(e) => {
                        setFieldValue('startDate', e.target.value);
                        setFieldValue(
                          'endDate',
                          getContractEndDate(
                            values.contractType,
                            e.target.value,
                          ),
                        );
                      }}
                      required={true}
                      errors={errors['startDate']}
                      touched={touched['startDate']}
                    />
                    {values.contractType != contractTypeOptions[4].value && ( //Permanent
                      <>
                        <InputField
                          name="endDate"
                          label="Contract End Date"
                          type="date"
                          value={values.endDate}
                          required={true}
                          errors={errors['endDate']}
                          touched={touched['endDate']}
                        />
                        <InputField
                          name="totalPrice"
                          label="Contract Value"
                          type="number"
                          required={true}
                          errors={errors['totalPrice']}
                          touched={touched['totalPrice']}
                        />
                        <InputField
                          name="monthlyRent"
                          label="Monthly Rent"
                          type="number"
                          required={true}
                          errors={errors['monthlyRent']}
                          touched={touched['monthlyRent']}
                        />
                        <InputField
                          name="monthlyMaintenanceFee"
                          label="Monthly Maintenance Fee"
                          type="number"
                          errors={errors['monthlyMaintenanceFee']}
                          touched={touched['monthlyMaintenanceFee']}
                        />

                        <InputField
                          name="feePerSquareMeter"
                          label="Fee per Square Meter"
                          type="number"
                          errors={errors['feePerSquareMeter']}
                          touched={touched['feePerSquareMeter']}
                        />
                      </>
                    )}

                    <InputField
                      name="note"
                      label="Note"
                      type="text"
                      placeholder="Note"
                      errors={errors['note']}
                      touched={touched['note']}
                    />
                  </div>

                  <br />

                  <div className="modal-footer">
                    <MyButton
                      label="Save"
                      icon="ion-plus"
                      type="success"
                      loading={loading}
                      onClick={handleSubmit}
                    />

                    <button
                      type="button"
                      className="btn btn-danger"
                      data-dismiss="modal"
                    >
                      <i className="ion-close"></i> Cancel
                    </button>
                  </div>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContractModal;
