import React, { useContext, useEffect, useState } from 'react';
import { Formik } from 'formik';
import Account from '../../Services/accountServices';
import { message } from 'antd';
import Loader from '../../Components/Loader';
import { addAccountSchemaValidation } from '../../config/Validations';
import TitleHeader from './Components/TitleHeader';
import { NavLink, useParams } from 'react-router-dom';
import { branchId } from '../../config/EnVariables';
import {
  AntdSelectField,
  CheckBox,
  InputField,
} from '../../Components/InputFormFields';
import { MyButton } from '../../Components/MyButton';
import { AuthContext } from '../../Auth/Context/AuthContext';
function AddAccount() {
  const { branches } = useContext(AuthContext);
  const { id } = useParams();
  const [accountTypes, setAccountTypes] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [fieldError, setFieldError] = useState();
  const [accounts, setAccounts] = useState([]);
  const [singleAccount, setSingleAccount] = useState();

  const fetchAccounts = async () => {
    await Account.getAllAccounts().then((acc) => {
      setAccounts(acc.data);
    });
  };
  const addAccount = async (formValues, { resetForm }) => {
    if (!formValues.parentId && !formValues.accountType) {
      return setFieldError('Field is Required');
    }
    if (!formValues.parentId && !formValues.currency) {
      return setFieldError('Field is Required');
    }
    setFieldError(null);
    setIsloading(true);
    const data = {
      name: formValues.name,
      note: formValues.note,
      currencyId: parseInt(formValues.currency),
      currencyName: currencies.find(
        (op) => op.id === parseInt(formValues.currency),
      )?.name,
      accountTypeId: parseInt(formValues.accountType),
      accountTypeName: accountTypes.find(
        (op) => op.id === parseInt(formValues.accountType),
      )?.name,
      branchId: branchId,
      branchName: branches?.find((op) => op.id === branchId)?.name,
      parentId: parseInt(formValues.parentId),
      parentName: accounts.find((op) => op.id === parseInt(formValues.parentId))
        ?.name,
      code: formValues.code,
    };

    if (formValues.parentId) {
      const acc = accounts.find(
        (op) => op.id === parseInt(formValues.parentId),
      );
      data.accountTypeId = acc.accountTypeId;
      data.accountTypeName = acc.accountTypeName;
      data.currencyId = acc.currencyId;
      data.currencyName = acc.currencyName;
    }
    if (id) {
      data.id = id;
      await Account.updateAccount(data)
        .then((response) => {
          if (response.data) {
            setIsloading(false);
            resetForm();
            fetchSingleAccountForUpdate();
            return message.success('Account Updated Successfully');
          }
        })
        .catch((err) => {
          message.error('Failed to Update Account');
          setIsloading(false);
        });
    } else
      await Account.createAccount(data)
        .then((response) => {
          if (response.data) {
            setIsloading(false);
            resetForm();
            message.success('Account Created Successfully');
          }
        })
        .catch((err) => {
          message.error('Failed to Create Account');
          setIsloading(false);
        });
  };

  const fetchAccountTypes = async () => {
    Account.getAccountTypes().then((types) => {
      setAccountTypes(types.data);
    });
  };
  const fetchCurrencies = async () => {
    Account.getActivatedCurrencies().then((curr) => {
      setCurrencies(curr.data);
    });
  };

  const fetchSingleAccountForUpdate = () => {
    setIsloading(true);
    Account.getOne(id).then((res) => {
      setSingleAccount(res.data);
      setIsloading(false);
    });
  };
  useEffect(() => {
    fetchAccounts();
    fetchAccountTypes();
    fetchCurrencies();
    if (id) {
      fetchSingleAccountForUpdate();
    }
  }, []);
  const initialValues = {
    name: singleAccount?.name || '',
    accountType: singleAccount?.accountTypeId || '',
    currency: singleAccount?.currencyId || '',
    code: singleAccount?.code || '',
    note: singleAccount?.note || '',
    parentId: singleAccount?.parentId || '',
    isSubAccount: false,
  };
  return (
    <>
      {/* <div className=""> */}
      <div className="page-wrapper">
        <div className="content">
          <TitleHeader
            title={`Chart of Accounts - ${
              id ? 'Update' : 'Create'
            } New Account`}
          />

          <div className="card">
            <div className="card-body">
              <Loader loading={isLoading} />
              <div className="row">
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={addAccount}
                  validationSchema={addAccountSchemaValidation}
                >
                  {({
                    errors,
                    touched,
                    values,
                    setFieldValue,
                    handleSubmit,
                  }) => (
                    <>
                      <InputField
                        name="name"
                        label="Account Name"
                        type="text"
                        required={true}
                        errors={errors['name']}
                        touched={touched['name']}
                      />

                      <InputField
                        name="code"
                        label="Account Number"
                        type="number"
                        required={true}
                        errors={errors['code']}
                        touched={touched['code']}
                      />
                      <CheckBox
                        label="Is Sub Account?"
                        name="isSubAccount"
                        value={values.isSubAccount}
                        onChange={(e) => {
                          setFieldValue('isSubAccount', e.target.checked);
                          setFieldValue('parentId', null);
                        }}
                      />

                      {values.isSubAccount && (
                        <AntdSelectField
                          name="parentId"
                          label="Sub Account Of"
                          value={values.parentId}
                          options={accounts?.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                          onChange={(e) => setFieldValue('parentId', e)}
                          errors={errors['parentId']}
                          touched={touched['parentId']}
                        />
                      )}

                      {!values.parentId && (
                        <>
                          <AntdSelectField
                            name="accountType"
                            label="Account Type"
                            value={values.accountType}
                            options={accountTypes?.map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))}
                            onChange={(e) => setFieldValue('accountType', e)}
                          />
                          {fieldError && touched.accountType && (
                            <p style={{ color: 'red' }}>{fieldError}</p>
                          )}
                          <AntdSelectField
                            name="currency"
                            label="Currency"
                            value={values.currency}
                            options={currencies?.map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))}
                            onChange={(e) => setFieldValue('currency', e)}
                          />
                          {fieldError && touched.currency && (
                            <p style={{ color: 'red' }}>{fieldError}</p>
                          )}
                        </>
                      )}

                      <InputField
                        name="note"
                        label="Note"
                        type="text"
                        errors={errors['note']}
                        touched={touched['note']}
                      />

                      <div>
                        <MyButton
                          type="success"
                          label="Save and New"
                          icon="ion-plus"
                          loading={isLoading}
                          onClick={handleSubmit}
                        />
                        &nbsp;
                        <NavLink
                          to="/accounts-list"
                          className="btn btn-secondary"
                        >
                          <i className="ion-upload"></i> Cancel
                        </NavLink>
                      </div>
                    </>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddAccount;
