import React from 'react';
import CountUp from 'react-countup';
import { Statistic } from 'antd';
export const CounterUp = ({ value, title, icon }) => {
  const formatter = (value) => {
    if (value >= 3000) {
      const kValue = (value / 1000).toFixed(2);
      const integerPart = parseInt(kValue, 10);
      const decimalPart = (parseFloat(kValue) - integerPart) * 100;
      return (
        <span style={{ color: 'green' }}>
          <CountUp end={integerPart} separator="," />.
          <CountUp end={decimalPart} separator="," />k
        </span>
      );
    } else {
      return (
        <CountUp
          end={value}
          separator=","
          style={{ color: value > 50 ? 'green' : 'red' }}
        />
      );
    }
  };

  return (
    <div className="col-lg-3 col-sm-6 col-12">
      <div className="dash-widget">
        <div className="dash-widgetimg">
          <span>
            <img
              src={
                value > 50
                  ? '/assets/img/icons/dash4.svg'
                  : '/assets/img/icons/dash3.svg'
              }
              alt="img"
            />
          </span>
        </div>
        <div className="dash-widgetcontent">
          <Statistic
            title={title}
            value={value}
            precision={2}
            formatter={formatter}
          />
        </div>
      </div>
    </div>
  );
};
