export const contractTypeOptions = [
  { value: 'ONE_MONTH', label: 'One Month' },
  { value: 'THREE_MONTHS', label: 'Quarter' },
  { value: 'SIX_MONTHS', label: 'Six Months' },
  { value: 'TWELVE_MONTHS', label: 'One Year' },
  { value: 'PERMANENT', label: 'Permanent' },
];
export const paymentTypeOptions = [
  { value: 'bank', label: 'Bank Transfer' },
  { value: 'cash', label: 'Cash' },
];
export const ownershipTypeOptions = [
  { value: 'sold', label: 'Sold' },
  { value: 'rent', label: 'Rent' },
];
export const businessTypeOptions = [
  { value: 'SHOP', label: 'SHOP' },
  { value: 'APARTMENT', label: 'APARTMENT' },
];
export const booleanOptions = [
  { value: true, label: 'YES' },
  { value: false, label: 'NO' },
];
export const paymentSourceOptions = [
  { value: 'RENT', label: 'RENT' },
  { value: 'MAINTENANCE', label: 'MAINTENANCE' },
  { value: 'UTILITIES', label: 'UTILITIES' },
  { value: 'SERVICES', label: 'SERVICES' },
  { value: 'OTHER', label: 'OTHER' },
];

export const transactionTypeOpions = {
  received_payment: 'RECEIVED_PAYMENT',
  general_journal: 'GENERAL_JOURNAL',
};
export const accountTypes = {
  bankAndCash: 'Bank and Cash',
};
