import React from 'react';
import View from './View';
import { useParams } from 'react-router-dom';

function Detail(props) {
  const invoiceId = useParams().id;
  const now = new Date();
  // const data = {
  //   invoiceId,
  //   date: `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()}`,
  //   status: 'unpaid',
  //   to: {
  //     name: 'Someone',
  //     address: 'Kabul, Afghanistan',
  //     phone: '0711111111',
  //   },

  //   lines: [
  //     {
  //       id: 1,
  //       description: 'something as descriptive',
  //       qty: 3,
  //       unitPrice: 30,
  //       amount: 90,
  //     },
  //     {
  //       id: 1,
  //       description: 'something as descriptive',
  //       qty: 3,
  //       unitPrice: 30,
  //       amount: 90,
  //     },
  //     {
  //       id: 1,
  //       description: 'something as descriptive',
  //       qty: 3,
  //       unitPrice: 30,
  //       amount: 90,
  //     },
  //     {
  //       id: 1,
  //       description: 'something as descriptive',
  //       qty: 3,
  //       unitPrice: 30,
  //       amount: 90,
  //     },
  //   ],
  // };
  return (
    <div className="page-wrappers">
      <View id={invoiceId} />
    </div>
  );
}

export default Detail;
