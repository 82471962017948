import React, { useState } from 'react';
import { Formik } from 'formik';
import { InputField } from '../../../Components/InputFormFields';
import { addFloorSchemaValidation } from '../../../config/Validations';
import { MyButton } from '../../../Components/MyButton';
import Watermark from '../../../Components/Watermark';
import Floor from '../../../Services/floorServices';
import { branchId } from '../../../config/EnVariables';
import { colors } from '../../../config/theme';
import { message } from 'antd';

function FloorModal({
  updatingRecord,
  setUpdatingRecord,
  floorId,
  floors,
  getFloors,
}) {
  const [loading, setLoading] = useState(false);

  const initialValues = {
    floorNumber: updatingRecord?.floorNumber || '',
    area: updatingRecord?.area || 0,
  };
  const onSubmit = (values) => {
    let apiMethod = 'POST';
    setLoading(true);
    values.branchId = branchId;
    const exists = floors.find(
      (op) =>
        op.floorNumber === values.floorNumber &&
        op.branchId === branchId &&
        updatingRecord?.id !== op.id,
    );
    if (exists) {
      setLoading(false);
      return message.error(
        'Floor with Floor# ' + values.floorNumber + ' already exists',
      );
    }
    if (floorId) {
      values.id = Number(floorId);
      apiMethod = 'PUT';
    }
    Floor.addFloor(values, apiMethod)
      .then((data) => {
        getFloors();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div
      className="modal fade"
      id="floorModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="floorModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header" style={{ background: colors.blue }}>
            <h5 className="modal-title" id="floorModalLabel">
              {updatingRecord?.id
                ? 'Update Floor - ' + updatingRecord?.floorNumber
                : 'Create New Floor'}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setUpdatingRecord(null);
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Watermark />
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={addFloorSchemaValidation}
            >
              {({ errors, touched, handleSubmit }) => (
                <div>
                  <InputField
                    size="full"
                    label="Floor Number"
                    name="floorNumber"
                    type="number"
                    required={true}
                    errors={errors['floorNumber']}
                    touched={touched['floorNumber']}
                  />
                  <InputField
                    size="full"
                    label={
                      <>
                        Shop Area (m<sup>2</sup>)
                      </>
                    }
                    name="area"
                    type="number"
                    required={true}
                    errors={errors['area']}
                    touched={touched['area']}
                  />

                  <div className="col-lg-12">
                    <MyButton
                      label={floorId ? 'Update' : 'Save'}
                      icon="ion-plus"
                      type="success"
                      loading={loading}
                      onClick={handleSubmit}
                    />
                    <button
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      onClick={() => setUpdatingRecord(null)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FloorModal;
