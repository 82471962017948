import React from "react";

function AFNFigure({ amount }) {
  if (!amount) amount = 0;
  return (
    <p style={{ color: amount > 0 ? "green" : "red" }}>
      {amount?.toLocaleString("en-US", {
        style: "currency",
        currency: "AFN",
      })}
    </p>
  );
}

export default AFNFigure;
