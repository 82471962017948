import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Floor from '../../Services/floorServices';

function FloorDetails(props) {
  const shopKeeperId = useParams().id;

  const [floor, setFloor] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const getFloor = async () => {
    setLoading(true);
    await Floor.getOne(shopKeeperId)
      .then((data) => {
        console.log('One Floor', data.data);
        setFloor(data.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getFloor();
  }, []);

  return (
    <>
      {loading && (
        <div id="global-loader">
          <div className="whirly-loader"> </div>
        </div>
      )}
      {/* <div className=" "> */}
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Floor Details</h4>
              <h6>Full details of a floor</h6>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-8 col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="productdetails">
                    <ul className="product-bar">
                      <li>
                        <h4>Project</h4>
                        <h6>{floor.branchId} </h6>
                      </li>
                      <li>
                        <h4>Floor ID</h4>
                        <h6>{floor.id} </h6>
                      </li>
                      <li>
                        <h4>Floor Number</h4>
                        <h6>{floor.floorNumber} </h6>
                      </li>
                      <li>
                        <h4>Area</h4>
                        <h6>{floor.area}</h6>
                      </li>
                      <li>
                        <h4>Free Space</h4>
                        <h6>{floor.freeSpace}</h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FloorDetails;
