import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { Link, useNavigate } from 'react-router-dom';

export const MyGraph = () => {
  // const svgRef = useRef(null);
  // const navigate = useNavigate();
  // useEffect(() => {
  //   // Set up the SVG container
  //   const svg = d3.select(svgRef.current);
  //   const width = window.innerWidth;
  //   const height = window.innerHeight;

  //   svg.attr("width", width).attr("height", height);

  //   const nodes = [
  //     { id: 2, label: "Add Shop", link: "/add-shop" },
  //     { id: 3, label: "List Shop", link: "/shop-list" },

  //     { id: 4, label: "Add Shopkeeper", link: "/add-shopkeeper" },
  //     { id: 5, label: "List Shopkeepers", link: "/shopkeeper-list" },

  //     { id: 6, label: "Add Floor", link: "/add-floor" },
  //     { id: 7, label: "Floors", link: "/floor-list" },
  //     // Add more nodes as needed
  //   ];

  //   const links = [];

  //   // Create random edges between nodes
  //   for (let i = 0; i < nodes.length; i++) {
  //     for (let j = i + 1; j < nodes.length; j++) {
  //       links.push({ source: nodes[i], target: nodes[j] });
  //     }
  //   }

  //   // Create the force simulation
  //   const simulation = d3
  //     .forceSimulation(nodes)
  //     .force(
  //       "link",
  //       d3
  //         .forceLink(links)
  //         .id((d) => d.id)
  //         .distance(380)
  //     )
  //     .force("charge", d3.forceManyBody().strength(-200))
  //     .force("center", d3.forceCenter(width / 2, height / 2));

  //   // Draw links
  //   const link = svg
  //     .selectAll(".link")
  //     .data(links)
  //     .enter()
  //     .append("line")
  //     .attr("class", "link")
  //     .style("stroke", "#ccc"); // Link color

  //   // Draw nodes
  //   const node = svg
  //     .selectAll(".node")
  //     .data(nodes)
  //     .enter()
  //     .append("circle")
  //     .attr("class", "node")
  //     .attr("r", 30)
  //     .style("fill", "skyblue") // Node color
  //     .on("click", (event, d) => {
  //       navigate(d.link);
  //     });

  //   // Add labels to nodes
  //   const labels = svg
  //     .selectAll(".label")
  //     .data(nodes)
  //     .enter()
  //     .append("text")
  //     .attr("class", "label")
  //     .attr("text-anchor", "middle")
  //     .attr("dy", "0.4em")
  //     .text((d) => d.label)
  //     .on("click", (event, d) => {
  //       navigate(d.link);
  //     });

  //   // Update positions on each tick
  //   simulation.on("tick", () => {
  //     link
  //       .attr("x1", (d) => d.source.x)
  //       .attr("y1", (d) => d.source.y)
  //       .attr("x2", (d) => d.target.x)
  //       .attr("y2", (d) => d.target.y);

  //     node.attr("cx", (d) => d.x).attr("cy", (d) => d.y);

  //     // Update label positions
  //     labels.attr("x", (d) => d.x).attr("y", (d) => d.y);
  //   });

  //   // Cleanup function to stop simulation on component unmount
  //   return () => simulation.stop();
  // }, []);

  // return <svg ref={svgRef}></svg>;

  const nodes = [
    {
      label: 'Home',
      coords: '155,49,255,130',
      alt: 'Clickable area',
      link: '/',
      labelPosition: {
        top: '30px',
        left: '280px',
      },
    },
    {
      label: 'Shops',
      coords: '360,49,460,130',
      alt: 'Clickable area',
      link: '/shop-list',
      labelPosition: {
        top: '30px',
        left: '490px',
      },
    },
    {
      label: 'Contracts',
      coords: '580,49,680,130',
      alt: 'Clickable area',
      link: '/contract-list',
      labelPosition: {
        top: '30px',
        left: '720px',
      },
    },
    {
      label: 'Shopkeepers',
      coords: '260,390,360,470',
      alt: 'Clickable area',
      link: '/shopkeeper-list',
      labelPosition: {
        top: '370px',
        left: '370px',
      },
    },
    {
      label: 'Floors',
      coords: '465,390,565,470',
      alt: 'Clickable area',
      link: '/floor-list',
      labelPosition: {
        top: '370px',
        left: '570px',
      },
    },
  ];
  const [imageSize, setImageSize] = useState({ width: 800, height: 500 });
  const imgRef = useRef(null);

  const scaleCoords = (
    coords,
    originalWidth,
    originalHeight,
    newWidth,
    newHeight,
  ) => {
    const scaleX = newWidth / originalWidth;
    const scaleY = newHeight / originalHeight;
    return coords
      .split(',')
      .map((coord, i) =>
        Math.round(i % 2 === 0 ? coord * scaleX : coord * scaleY),
      )
      .join(',');
  };

  const updateImageSize = () => {
    if (imgRef.current) {
      const { width, height } = imgRef.current.getBoundingClientRect();
      setImageSize({ width, height });
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateImageSize);
    updateImageSize();
    return () => {
      window.removeEventListener('resize', updateImageSize);
    };
  }, []);

  return (
    // <>
    //   <div className="page-wrapper" style={{ background: '#E7E7E7' }}>
    //     <div
    //       className="text-center"
    //       style={{ display: 'flex', justifyContent: 'center' }}
    //     >
    //       <img
    //         src="assets/admin.gif"
    //         width={800}
    //         height={500}
    //         useMap="#admin"
    //         alt="Admin Dashboard"
    //       />
    //       <map name="admin">
    //         {nodes?.map((node, i) => (
    //           <Link to={node.link} key={i + 1}>
    //             <area shape="rect" coords={node.coords} alt={node.alt} />
    //           </Link>
    //         ))}
    //       </map>
    //       {/* Labels for areas */}
    //       {nodes?.map((node, i) => (
    //         <div
    //           key={i + 1}
    //           className="label"
    //           style={{
    //             position: 'absolute',
    //             top: node.labelPosition.top,
    //             left: node.labelPosition.left,
    //           }}
    //         >
    //           {node.label}
    //         </div>
    //       ))}
    //     </div>
    //     <hr />
    //   </div>
    // </>
    <div className="page-wrapper" style={{ background: '#E7E7E7' }}>
      <div
        className="text-center"
        style={{
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <img
          ref={imgRef}
          src="assets/admin.gif"
          width="100%"
          height="auto"
          useMap="#admin"
          alt="Admin Dashboard"
          onLoad={updateImageSize}
          style={{ maxWidth: '800px' }}
        />
        <map name="admin">
          {nodes.map((node, i) => (
            <Link to={node.link} key={i + 1}>
              <area
                title={node.label}
                shape="rect"
                coords={scaleCoords(
                  node.coords,
                  800,
                  500,
                  imageSize.width,
                  imageSize.height,
                )}
                alt={node.alt}
              />
            </Link>
          ))}
        </map>
      </div>
      <hr />
    </div>
  );
};
