import { Spin } from 'antd';
import React from 'react';
import { colors } from '../config/theme';

function Loader({ loading }) {
  return (
    // <div
    //   hidden={!loading}
    //   id="global-loader"
    //   style={{
    //     background: 'none',
    //     position: 'absolute',
    //     top: '50%',
    //     left: '50%',
    //   }}
    // >
    //   <div class="whirly-loader"> </div>
    // </div>
    loading && (
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
        }}
      >
        <Spin />
      </div>
    )
  );
}

export default Loader;
