import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Shopkeeper from '../../Services/shopkeeperServices';
import ExportComponents from '../../Components/ExportComponents';
import { Table as TB } from 'antd';
import { permit } from '../../helpers/Authorize';
import { branchId, userPermissions, userRoles } from '../../config/EnVariables';
import { AuthContext } from '../../Auth/Context/AuthContext';
import callApi from '../../helpers/callApi';
import DeleteConfirmation from '../../Components/DeleteConfirmation';
import ShopkeeperModal from './Components/ShopkeeperModal';
function ShopKeeperList() {
  const { user } = useContext(AuthContext);
  const columns = [
    {
      title: 'S.no',
      dataIndex: 'sno',
    },
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
    },
    {
      title: 'Address',
      dataIndex: 'address',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
    },
  ];
  const navigate = useNavigate();
  const [shopKeeperList, setShopKeeperList] = useState([]);
  const [updatingRecord, setUpdatingRecord] = useState();
  const [loading, setLoading] = useState(false);
  let pageSizeValue = 20;
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: pageSizeValue,
      showSizeChanger: true,
      pageSizeOptions: [10, 15, 20, 25, 30, 35, 40, 45, 50, 100],
    },
  });
  const handleTableChange = async (pagination, filter, sorter) => {
    pageSizeValue = pagination.pageSize;
    setTableParams({ pagination, filter, ...sorter });
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setShopKeeperList([]);
    }
    const params = { size: pagination.pageSize };
    paging.current = pagination.current;

    await fetchData(params);
  };

  const deleteShopKeeper = async (id) => {
    setLoading(true);
    await Shopkeeper.remove(id)
      .then(() => {
        setLoading(false);
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  let paging = { current: 1 };
  async function fetchData(params = {}) {
    setLoading(true);
    let endpoint = `shopkeepers/?branchId=${branchId}`;
    const params1 = {
      ...params,
      page: paging.current - 1 || 0,
      size: pageSizeValue || null,
    };
    try {
      const response = await callApi(endpoint, null, null, params1);
      if (response.data && response.status === 200) {
        setShopKeeperList(response.data);
        const totalStudents = response.headers.get('x-total-count');
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: totalStudents,
            current: params1.page + 1,
            pageSize: pageSizeValue,
          },
        });
      } else {
        console.log('Shopkeeper error');
      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="d-flex justify-content-end">
            <div className="no-print">
              <NavLink to="/add-shopkeeper" className="btn btn-success">
                <i className="ion-plus"></i> Add
              </NavLink>
            </div>
            <ExportComponents
              excelData={shopKeeperList}
              fileName="Shopkeepers.xlsx"
            />
          </div>

          <ShopkeeperModal
            updatingRecord={updatingRecord}
            setUpdatingRecord={setUpdatingRecord}
            shopkeeperId={updatingRecord?.id}
            getShopkeepers={fetchData}
          />
          <TB
            className="table-striped"
            size="small"
            columns={columns}
            scroll={{ x: 1000 }}
            pagination={tableParams.pagination}
            onChange={handleTableChange}
            loading={loading}
            dataSource={shopKeeperList?.map((item, index) => ({
              key: item.id,
              sno: index + 1,
              id: item.id,
              name: <p>{item.name}</p>,
              phone: item.phone,
              address: item.address,
              status: item.activeShopsList ? (
                <span className="badge badge-pill badge-danger">
                  Busy in Shop#id{' '}
                  {JSON.parse(item.activeShopsList).map((op) => op.id + ',')}
                </span>
              ) : (
                <span className="badge badge-pill badge-success">Free</span>
              ),
              shop: '-',
              action: (
                <div className="no-print">
                  <button
                    style={{
                      backgroundColor: 'transparent',
                      border: '0px',
                    }}
                    onClick={() => navigate(`/shopkeeper-details/${item.id}`)}
                  >
                    <img src="/assets/img/icons/eye.svg" alt="img" />
                  </button>
                  {(permit(userPermissions.update) ||
                    user.role === userRoles.superadmin) && (
                    <button
                      style={{
                        backgroundColor: 'transparent',
                        border: '0px',
                      }}
                      data-target="#shopkeeperModal"
                      data-toggle="modal"
                      data-whatever="@mdo"
                      onClick={() => {
                        setUpdatingRecord(item);
                      }}
                    >
                      <img src="assets/img/icons/edit.svg" alt="img" />
                    </button>
                  )}

                  {(permit(userPermissions.delete) ||
                    user.role === userRoles.superadmin) && (
                    <DeleteConfirmation
                      onConfirm={() => deleteShopKeeper(item.id)}
                      itemName={item.name}
                    />
                  )}
                </div>
              ),
            }))}
          />
        </div>
      </div>
    </>
  );
}

export default ShopKeeperList;
