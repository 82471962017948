import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Shop from '../../Services/shopServices';
import { Table as TB, Tabs, Upload, message } from 'antd';
import callApi from '../../helpers/callApi';
import { AuthContext } from '../../Auth/Context/AuthContext';
import USDFigure from '../../Components/USDFigure';
import AreaUnit from '../../Components/AreaUnit';
import ExportComponents from '../../Components/ExportComponents';
import { permit } from '../../helpers/Authorize';
import { branchId, userPermissions } from '../../config/EnVariables';
import { Formik } from 'formik';
import { AntdSelectField } from '../../Components/InputFormFields';
import DeleteConfirmation from '../../Components/DeleteConfirmation';
import ShopModal from './Components/ShopModal';
import { Delete } from '../../Components/Icons/Buttons';
import { colors } from '../../config/theme';
function ShopList() {
  const { floors } = useContext(AuthContext);
  const columns = [
    {
      dataIndex: 'sno',
      title: 'Sno',
    },
    {
      dataIndex: 'id',
      title: 'ID',
    },
    {
      dataIndex: 'name',
      title: 'Shop',
    },
    {
      dataIndex: 'floor',
      title: 'Floor #',
    },
    {
      dataIndex: 'area',
      title: 'Shop Area',
    },
    {
      dataIndex: 'rental_rate',
      title: 'Rental Rate',
    },
    {
      dataIndex: 'isSpliteable',
      title: 'Is Spliteable',
    },
    {
      dataIndex: 'occupied',
      title: 'Status',
      filters: [
        {
          text: 'Occupied',
          value: 'Yes',
        },
        {
          text: 'Free Shops',
          value: 'No',
        },
      ],
      filterMode: 'tree',
      filterSearch: true,
      onFilter: (value, record) => record.occupied.startsWith(value),
    },
    {
      dataIndex: 'action',
      title: 'Action',
    },
  ];

  const navigate = useNavigate();
  const [shopList, setShopList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updatingRecord, setUpdatingRecord] = useState();
  const [importFile, setImportFile] = useState(null);
  const [activeTab, setActiveTab] = useState('all');
  let pageSizeValue = 20;
  const [localPagination, setLocalPagination] = useState(
    JSON.parse(window.localStorage.getItem('shopsPagination')),
  );
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: localPagination?.current || 1,
      pageSize: localPagination?.pageSize || pageSizeValue,
      showSizeChanger: true,
      pageSizeOptions: [10, 15, 20, 25, 30, 35, 40, 45, 50, 100],
    },
  });

  let paging = { current: localPagination?.current || 1 };
  const handleTableChange = async (pagination, filter, sorter) => {
    window.localStorage.setItem('shopsPagination', JSON.stringify(pagination));
    setLocalPagination(pagination);
    setTableParams({ pagination: localPagination });
    const params = { size: pagination.pageSize };
    paging.current = pagination.current;
    await fetchData(params);
  };

  async function fetchData(params = {}) {
    setLoading(true);
    let endpoint = `building-shops/branch-shops/?branchId=${branchId}`;
    if (activeTab === 'sold')
      endpoint = `building-shops/sold-shops-by-branch/?branchId=${branchId}`;
    if (activeTab === 'free')
      endpoint = `building-shops/non-occupied-shops/?branchId=${branchId}`;
    const params1 = {
      ...params,
      page: paging.current - 1 || 0,
      size: tableParams.pagination.pageSize || null,
    };
    try {
      const response = await callApi(endpoint, null, null, params1);
      if (response.data && response.status === 200) {
        setShopList(response.data);

        const totalStudents = response.headers.get('x-total-count');
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: totalStudents,
            current: params1.page + 1,
            pageSize: localPagination?.pageSize || pageSizeValue,
          },
        });
      } else {
        console.log('students error');
      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  }

  const deleteShop = async (id) => {
    setLoading(true);
    await Shop.remove(id)
      .then(() => {
        setLoading(false);
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const fetchFloorShops = async (values) => {
    if (!values.floor) return;
    await Shop.getFloorShops(values.floor)
      .then((response) => {
        setShopList(response.data);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: response.data?.length,
            current: 1,
            pageSize: pageSizeValue,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, [activeTab]);

  const onChangeFile = (info) => {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    }
    setImportFile(info.file?.originFileObj);
  };

  const uploadShops = async () => {
    if (!importFile) return message.warning('Please Select a file first');
    setLoading(true);
    const formData = new FormData();
    formData.append('file', importFile);
    Shop.importShops(formData)
      .then((response) => {
        setImportFile(null);
        message.success('Shops imported Successfully');
        console.log(response.data);
        fetchData();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error('Failed to Import');
        console.log('Network Error', error);
      });
  };

  const TabItems = [
    {
      key: 'all',
      label: 'All',
    },
    {
      key: 'sold',
      label: 'Sold',
    },
    {
      key: 'free',
      label: 'Available',
    },
    {
      key: 'total',
      disabled: true,
      label: `Total (${tableParams.pagination?.total || 0})`,
    },
  ];
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="no-print">
            <Formik
              enableReinitialize
              initialValues={{ floor: '' }}
              onSubmit={fetchFloorShops}
            >
              {({ values, setFieldValue, handleSubmit, resetForm }) => (
                <div className="d-flex justify-content-end ">
                  <div className="d-flex">
                    <div>
                      <AntdSelectField
                        size="full"
                        name="floor"
                        placeholder="Filter Floor #"
                        value={values.parentId}
                        options={floors?.map((item) => ({
                          value: item.id,
                          label: `Floor # ${item.floorNumber}`,
                        }))}
                        onChange={(e) => setFieldValue('floor', e)}
                      />
                    </div>
                    <div>
                      <button
                        className="btn btn-primary"
                        onClick={handleSubmit}
                      >
                        <i className="ion-search" /> Search
                      </button>
                    </div>
                    <div>
                      <button
                        className="btn btn-dark"
                        onClick={() => {
                          resetForm({ values: { floor: [] } });
                          window.localStorage.removeItem('shopsPagination');
                          setLocalPagination(null);
                          setTableParams({
                            ...tableParams,
                            pagination: {
                              ...tableParams.pagination,
                              current: 1,
                              pageSize: pageSizeValue,
                            },
                          });
                          fetchData();
                        }}
                      >
                        <i className="ion-loop" /> Reset
                      </button>
                    </div>
                  </div>
                  <div className="no-print">
                    <button
                      className="btn btn-success"
                      data-target="#shopModal"
                      data-toggle="modal"
                      data-whatever="@mdo"
                    >
                      <i className="ion-plus" /> Add
                    </button>
                    {!importFile ? (
                      <Upload name="file" onChange={onChangeFile}>
                        <button className="btn btn-light">
                          <i className="ion-upload" /> Import
                        </button>
                      </Upload>
                    ) : (
                      <>
                        <button className="btn btn-light" onClick={uploadShops}>
                          <i className="ion-upload" /> Upload Now
                        </button>
                        <div className="d-flex align-items-center">
                          <Delete onClick={() => setImportFile(null)} />
                          <p style={{ color: colors.red }}>
                            {importFile?.name}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                  <ExportComponents
                    excelData={shopList}
                    fileName={'Shops.xlsx'}
                  />
                </div>
              )}
            </Formik>
            <ShopModal
              updatingRecord={updatingRecord}
              setUpdatingRecord={setUpdatingRecord}
              shopId={updatingRecord?.id}
              getShops={fetchData}
            />
          </div>
          <Tabs
            defaultActiveKey={activeTab}
            items={TabItems}
            onChange={(key) => setActiveTab(key)}
          />
          <TB
            className="table-striped"
            size="small"
            columns={columns}
            scroll={{ x: 1000 }}
            pagination={tableParams.pagination}
            onChange={handleTableChange}
            loading={loading}
            dataSource={shopList?.map((item, index) => ({
              key: item.id,
              sno: index + 1,
              id: item.id,
              name: <p>{item.name}</p>,
              floor: item.buildingFloorFloorNumber,
              area: <AreaUnit area={item.area} />,
              rental_rate: <USDFigure amount={item.monthlyRent} />,
              isSpliteable: item.isSpliteable ? (
                <span className="badge badge-pill badge-info">Yes</span>
              ) : (
                <span className="badge badge-pill badge-secondary">No</span>
              ),
              occupied: item.isOccupied ? (
                <span className="badge badge-pill badge-danger">Occupied</span>
              ) : (
                <span className="badge badge-pill badge-success">
                  Available
                </span>
              ),
              action: (
                <div className="no-print">
                  <button
                    style={{
                      backgroundColor: 'transparent',
                      border: '0px',
                    }}
                    onClick={() => navigate(`/shop-details/${item.id}`)}
                  >
                    <img src="/assets/img/icons/eye.svg" alt="img" />
                  </button>
                  {permit(userPermissions.update) && (
                    <button
                      style={{
                        backgroundColor: 'transparent',
                        border: '0px',
                      }}
                      data-target="#shopModal"
                      data-toggle="modal"
                      data-whatever="@mdo"
                      onClick={() => {
                        setUpdatingRecord(item);
                      }}
                    >
                      <img src="assets/img/icons/edit.svg" alt="img" />
                    </button>
                  )}
                  {permit(userPermissions.delete) && (
                    <DeleteConfirmation
                      onConfirm={() => deleteShop(item.id)}
                      itemName={item.name}
                    />
                  )}
                </div>
              ),
            }))}
          />
        </div>
      </div>
    </>
  );
}

export default ShopList;
