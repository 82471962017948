import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Shopkeeper from '../../Services/shopkeeperServices';
import TitleHeader from '../accounts/Components/TitleHeader';
import { LabelHead } from '../../Components/LableHead';
import Loader from '../../Components/Loader';
import { AuthContext } from '../../Auth/Context/AuthContext';
function ShopkeeperFullDetails(props) {
  const { user } = useContext(AuthContext);
  const { shopkeeperId } = useParams();
  const [shop, setShop] = useState();
  const [shopkeeperShops, setShopkeeperShops] = useState();
  const [rents, setRents] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const getShopkeeper = async () => {
    setLoading(true);
    await Shopkeeper.getOne(shopkeeperId)
      .then((data) => {
        setShop(data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const getShopkeeperRents = async () => {
    setLoading(true);
    await Shopkeeper.getRents(shopkeeperId)
      .then((data) => {
        setRents(data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const getShopkeeperShops = async () => {
    setLoading(true);
    await Shopkeeper.getContracts(shopkeeperId)
      .then((data) => {
        setShopkeeperShops(data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    getShopkeeper();
    getShopkeeperRents();
    getShopkeeperShops();
  }, []);
  const addRent = () => {
    navigate('/add-payment', { state: { shop: shopkeeperShops[0] } });
  };
  return (
    <>
      {shop && (
        <div className={user ? 'page-wrapper' : ''}>
          <div className="content">
            <TitleHeader title="Shopkeeper Details" />
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <div className="productdetails">
                      <ul className="product-bar">
                        <li>
                          <h4>Branch</h4>
                          <h6>{shop.branchId} </h6>
                        </li>
                        <li>
                          <h4>Name</h4>
                          <h6>{shop.name} </h6>
                        </li>
                        <li>
                          <h4>Email</h4>
                          <h6>{shop.email}</h6>
                        </li>
                        <li>
                          <h4>Address</h4>
                          <h6>{shop.address}</h6>
                        </li>
                        <li>
                          <h4>Phone</h4>
                          <h6>{shop.phone}</h6>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Loader loading={loading} />
            <div>
              <LabelHead label="Rents History">
                {user && (
                  <a onClick={addRent} style={{ color: '#fff' }}>
                    Create Payment
                  </a>
                )}
              </LabelHead>
              <table className="table">
                <thead>
                  <th>Transaction</th>
                  <th>Date</th>
                  <th>Shop</th>
                  <th>Amount</th>
                  <th>Type</th>
                  <th>Exc Rate</th>
                </thead>
                <tbody>
                  {rents?.map((item, i) => (
                    <tr key={i + 1}>
                      <td>{item.name}</td>
                      <td>{item.date}</td>
                      <td>{item.shopName}</td>
                      <td>{item.amount || '$0.00'}</td>
                      <td>{item.transactionType}</td>
                      <td>{item.currencyRate}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <h2>Shops History</h2>
              <table className="table">
                <thead>
                  <th>Transaction</th>
                  <th>Date</th>
                  <th>Shop</th>
                  <th>Contract</th>
                  <th>Status</th>
                </thead>
                <tbody>
                  {shopkeeperShops?.map((item, i) => (
                    <tr key={i + 1}>
                      <td>{item.name}</td>
                      <td>{item.contractDate}</td>
                      <td>{item.shopName}</td>
                      <td>{item.contractType}</td>
                      <td>{item.state}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ShopkeeperFullDetails;
