import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Floor from '../../Services/floorServices';
import { Table } from 'antd';
import AreaUnit from '../../Components/AreaUnit';
import { permit } from '../../helpers/Authorize';
import { userPermissions } from '../../config/EnVariables';
import FloorModal from './Components/FloorModal';
import DeleteConfirmation from '../../Components/DeleteConfirmation';
function FloorList(props) {
  const columns = [
    {
      title: 'Floor Number',
      dataIndex: 'floorNumber',
    },
    {
      title: 'Area',
      dataIndex: 'area',
    },
    {
      title: 'Free Space',
      dataIndex: 'freeSpace',
    },
    {
      dataIndex: 'actions',
      title: 'Action',
    },
  ];
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [updatingRecord, setUpdatingRecord] = useState();
  const [floors, setFloors] = useState();
  const getFloors = () => {
    setLoading(true);
    Floor.getAllFloors().then((res) => {
      setFloors(res.data);
      setLoading(false);
    });
  };
  const deleteFloor = async (id) => {
    setLoading(true);
    await Floor.remove(id)
      .then(() => {
        setLoading(false);
        getFloors();
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getFloors();
  }, []);
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-btn d-flex justify-content-end">
            <button
              className="btn btn-success"
              data-target="#floorModal"
              data-toggle="modal"
              data-whatever="@mdo"
            >
              <i className="ion-plus"></i> Add
            </button>
          </div>
          <FloorModal
            floorId={updatingRecord?.id}
            updatingRecord={updatingRecord}
            setUpdatingRecord={setUpdatingRecord}
            floors={floors}
            getFloors={getFloors}
          />

          <Table
            className="table-striped"
            size="small"
            loading={loading}
            columns={columns}
            scroll={{ x: 1000 }}
            dataSource={floors?.map((item, index) => ({
              key: index + 1,
              floorNumber: item.floorNumber,
              freeSpace: <AreaUnit area={item.freeSpace} />,
              area: <AreaUnit area={item.area} />,

              actions: (
                <div>
                  <button
                    style={{
                      backgroundColor: 'transparent',
                      border: '0px',
                    }}
                    onClick={() => navigate(`/floor-details/${item.id}`)}
                  >
                    <img src="/assets/img/icons/eye.svg" alt="img" />
                  </button>
                  {permit(userPermissions.update) && (
                    <button
                      style={{
                        backgroundColor: 'transparent',
                        border: '0px',
                      }}
                      data-target="#floorModal"
                      data-toggle="modal"
                      data-whatever="@mdo"
                      onClick={() => {
                        setUpdatingRecord(item);
                      }}
                    >
                      <img src="assets/img/icons/edit.svg" alt="img" />
                    </button>
                  )}

                  {permit(userPermissions.update) && (
                    <DeleteConfirmation
                      onConfirm={() => deleteFloor(item.id)}
                      itemName={'Floor#: ' + item.floorNumber}
                    />
                  )}
                </div>
              ),
            }))}
          />
        </div>
      </div>
    </>
  );
}

export default FloorList;
