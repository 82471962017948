import React, { useContext, useState } from 'react';
import { Formik } from 'formik';
import User from '../../Services/userServices';
import { message } from 'antd';
import { InputField } from '../../Components/InputFormFields';
import { resetPasswordSchemaValidation } from '../../config/Validations';
import { MyButton } from '../../Components/MyButton';
import Watermark from '../../Components/Watermark';
import { AuthContext } from '../../Auth/Context/AuthContext';
import { browserData } from '../../config/EnVariables';

function PasswordResetModal() {
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useContext(AuthContext);
  const initialValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const registerUser = (values) => {
    const data = {
      ...user,
      password: values.newPassword,
    };
    setLoading(true);

    User.signin(user?.username, values.oldPassword)
      .then((res) => {
        User.update(data)
          .then((data) => {
            message.success('Password Reset Successfully');
            setLoading(false);
            window.location.replace('/');
            setUser(null);
            window.localStorage.removeItem(browserData.user);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        setLoading(false);
        if (err.response?.data.status === 400)
          return message.error('Invalid Old Password');
      });
  };

  return (
    <div
      className="modal fade"
      id="passwordResetModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="passwordResetModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="passwordResetModalLabel">
              Reset Password
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Watermark />
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={registerUser}
              validationSchema={resetPasswordSchemaValidation}
            >
              {({ errors, touched, values, handleSubmit }) => (
                <>
                  <InputField
                    size="full"
                    label="Old Password"
                    name="oldPassword"
                    type="password"
                    required={true}
                    value={values.oldPassword}
                    errors={errors['oldPassword']}
                    touched={touched['oldPassword']}
                  />

                  <InputField
                    size="full"
                    label="New Password"
                    name="newPassword"
                    type="text"
                    required={true}
                    value={values.newPassword}
                    errors={errors['newPassword']}
                    touched={touched['newPassword']}
                  />

                  <InputField
                    size="full"
                    label="Confirm New Password"
                    name="confirmPassword"
                    type="text"
                    required={true}
                    value={values.confirmPassword}
                    errors={errors['confirmPassword']}
                    touched={touched['confirmPassword']}
                  />

                  <div className="modal-footer">
                    <MyButton
                      label="Save"
                      icon="ion-plus"
                      type="success"
                      loading={loading}
                      onClick={handleSubmit}
                    />

                    <button
                      type="button"
                      className="btn btn-danger"
                      data-dismiss="modal"
                    >
                      <i className="ion-close"></i> Close
                    </button>
                  </div>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordResetModal;
