import React, { useContext } from 'react';
import { branchId, branchName } from '../../../config/EnVariables';
import { getTodayDate } from '../../../helpers/quick';
function TitleHeader({ title }) {
  return (
    <div
      className="page-header"
      style={{ display: 'flex', justifyContent: 'center' }}
    >
      <div className="row text-center">
        <div className="col">
          <h3 className="page-title">
            {branchId === 1 ? 'SUN SKY' : branchName}
          </h3>
          <ul
            className="breadcrumb"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <li className="breadcrumb-item">
              <p className="breadcrumb-item active">{title}</p>
            </li>
            <li className="breadcrumb-item">{getTodayDate()}</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default TitleHeader;
