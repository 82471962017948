import React, { useEffect, useState } from 'react';
import Account from '../../Services/accountServices';
import { Input, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import TitleHeader from './Components/TitleHeader';
import { SETTINGS } from '../../config/settings';
import { AntdSelectField } from '../../Components/InputFormFields';
import { branchId } from '../../config/EnVariables';
import { getDates } from '../../helpers/quick';
import { transactionTypeOpions } from '../../Utils/options';
import Loader from '../../Components/Loader';
import callApi from '../../helpers/callApi';
import { evaluate } from 'mathjs';
import { MyButton } from '../../Components/MyButton';
function JournelEntry() {
  const navigate = useNavigate();
  const { journalId } = useParams();
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [isAfnAccount, setIsAfnAccount] = useState(false);
  const [exchangeRate, setExchangeRate] = useState();
  const [date, setDate] = useState(getDates('today')?.startDate);
  const [attachment, setAttachmentName] = useState();
  const [updatingRecord, setUpdatingRecord] = useState();
  const initialRows = [
    { id: 1, debit: '', credit: '', accountId: null, memo: '' },
    { id: 2, debit: '', credit: '', accountId: null, memo: '' },
    { id: 3, debit: '', credit: '', accountId: null, memo: '' },
    { id: 4, debit: '', credit: '', accountId: null, memo: '' },
  ];

  const [rowData, setRowData] = useState(initialRows);
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
  const addRow = () => {
    const newRow = {
      id: rowData.length + 1,
      debit: '',
      credit: '',
      account: '',
      memo: '',
    };
    setRowData([...rowData, newRow]);
  };

  const handleNumberChange = (id, field, value) => {
    const updatedData = rowData.map((row, index) => {
      if (row.id === id) {
        // return { ...row, [field]: parseInt(value) };
        return { ...row, [field]: value };
      }
      // Auto-fill the memo attribute of the next row if the current row is filled
      else {
        const nextRow = rowData[index + 1];
        if (index === id) {
          if (field === 'debit')
            return { ...row, ...(nextRow && { credit: value }) };
          if (field === 'credit')
            return { ...row, ...(nextRow && { debit: value }) };
        }
      }
      //end next row
      return row;
    });
    setRowData(updatedData);
  };
  const handleSelectChange = (id, value) => {
    const updatedData = rowData.map((row, index) => {
      if (row.id === id) {
        return { ...row, accountId: value };
      }
      return row;
    });
    fetchAccounts(value);
    const isAFNExists = updatedData.filter(
      (op) =>
        accounts.find((o) => o.id === op.accountId)?.currencyName === 'AFN',
    );
    if (isAFNExists.length > 0) setIsAfnAccount(true);
    else setIsAfnAccount(false);
    setRowData(updatedData);
  };

  const handleTextChange = (id, value) => {
    const updatedData = rowData.map((row, index) => {
      if (row.id === id) {
        return { ...row, memo: value };
      }
      // Auto-fill the memo attribute of the next row if the current row is filled
      else {
        const nextRow = rowData[index + 1];
        if (index === id) return { ...row, ...(nextRow && { memo: value }) };
      }
      //end Next row
      return row;
    });
    setRowData(updatedData);
  };

  const handleFormSubmit = async (action) => {
    let apiMethod = 'POST';
    setLoading(true);
    let totalCredit = 0;
    let totalDebit = 0;

    const lineItems = rowData.filter((op) => op.credit || op.debit);

    const lines = lineItems.map((item) => {
      const { id, ...rest } = item;
      return rest;
    });
    //see if account is selected for every row
    for (let i = 0; i < lines.length; i++) {
      if (!lines[i].debit) lines[i].debit = null;
      if (!lines[i].credit) lines[i].credit = null;
      if (lines[i].credit) totalCredit += parseInt(lines[i].credit);
      if (lines[i].debit) totalDebit += parseInt(lines[i].debit);
      if (!lines[i].accountId) {
        setLoading(false);
        return message.error('Please Select Account');
      }
    }
    const filteredAccounts = accounts.filter((account) => {
      return (
        account.currencyName === 'AFN' &&
        lines.some((item) => item.accountId === account.id)
      );
    });
    if (filteredAccounts.length > 0) setIsAfnAccount(true);
    // both side must be balanced and if AFN account exists then exchange rate is required
    if (totalCredit === totalDebit && totalCredit !== 0 && totalDebit !== 0) {
      if (isAfnAccount && !exchangeRate) {
        setLoading(false);
        return message.error('Exchange Rate must be provided for AFN accounts');
      }
      const data = {
        currencyRate: parseInt(exchangeRate) || null,
        transactionType: transactionTypeOpions.general_journal,
        date: new Date(date).toISOString(),
        lines,
        branchId: branchId,
        attachment: attachment ? await toBase64(attachment) : null,
      };
      if (journalId) {
        data.lines = lines?.map((line) => ({
          accountId: line.accountId,
          debit: line.debit,
          credit: line.credit,
          memo: line.memo,
        }));
        apiMethod = 'PUT';
        data.id = Number(journalId);
      }
      await Account.addJournelEntry(apiMethod, data)
        .then((response) => {
          if (response.data) message.success('Entry Saved');
          setLoading(false);
          if (action === 'close') navigate('/genral-journel');
          else {
            if (journalId) navigate('/genral-journel');
            setRowData(initialRows);
          }
        })
        .catch((error) => {
          setLoading(false);
          message.error('Network Error');
        });
    } else {
      setLoading(false);
      message.error('Transaction is not Balanced');
    }
  };

  const fetchAccounts = async (accId) => {
    await Account.getAllAccounts().then((acc) => {
      if (accId)
        setAccounts(
          accounts?.filter(
            (account) => !rowData.some((row) => row.accountId === account.id),
          ),
        );
      else setAccounts(acc.data);
    });
  };
  const getRecordForUpdate = () => {
    setLoading(true);
    callApi(`general-journals/${journalId}`)
      .then((res) => {
        setUpdatingRecord(res.data);
        setRowData(res.data?.lines);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const resetForm = () => {
    setRowData(initialRows);
  };
  useEffect(() => {
    fetchAccounts();
    if (journalId) getRecordForUpdate();
  }, []);
  const calculateResult = (id, field, value) => {
    try {
      const calculatedResult = evaluate(value);
      handleNumberChange(id, field, calculatedResult);
    } catch (error) {
      message.error('Please enter a valid expression or value');
    }
  };
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <TitleHeader
            title={`General Journal - ${
              journalId
                ? 'Updating ' + updatingRecord?.name + `(${updatingRecord?.id})`
                : 'New Entry'
            }`}
          />

          <div className="card">
            <div className="card-body">
              <div className="row">
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <strong>Date: </strong>
                    <input
                      className="form-control "
                      type="date"
                      value={date}
                      name="date"
                      onChange={(val) => setDate(val.target.value)}
                    />
                  </div>
                  <div>
                    <strong>Exchange Rate</strong>
                    <Input
                      disabled={!isAfnAccount}
                      className="form-group"
                      type="number"
                      placeholder="Exchange Rate"
                      name="exchangeRate"
                      onChange={(e) => setExchangeRate(e.target.value)}
                    />
                  </div>
                  <div className="no-print">
                    <strong>Receipt</strong>
                    <br />
                    <Input
                      type="file"
                      name="attachment"
                      onChange={(e) => setAttachmentName(e.target.files[0])}
                    />
                  </div>
                </div>
                <Loader loading={loading} />
                <table>
                  <thead>
                    <th>
                      <strong>Account</strong>
                    </th>
                    <th>
                      <strong>Memo</strong>
                    </th>
                    <th>
                      <strong>
                        Debit{' '}
                        <span style={{ color: 'green' }}>
                          (
                          {isAfnAccount
                            ? SETTINGS.currencies.afn
                            : SETTINGS.currencies.usd}
                          )
                        </span>
                      </strong>
                    </th>
                    <th>
                      <strong>
                        Credit{' '}
                        <span style={{ color: 'green' }}>
                          (
                          {isAfnAccount
                            ? SETTINGS.currencies.afn
                            : SETTINGS.currencies.usd}
                          )
                        </span>
                      </strong>
                    </th>
                  </thead>
                  <tbody>
                    {rowData.map((row) => (
                      <tr key={row.id}>
                        <td style={{ width: 300, margin: 0 }}>
                          <AntdSelectField
                            size="full"
                            name="accountId"
                            value={row.accountId}
                            options={accounts.map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))}
                            onChange={(e) => handleSelectChange(row.id, e)}
                          />
                        </td>
                        <td className="w-50">
                          <Input
                            style={{ height: 40 }}
                            type="text"
                            value={row.memo}
                            onChange={(e) =>
                              handleTextChange(row.id, e.target.value)
                            }
                          />
                        </td>
                        <td style={{ width: '150px' }}>
                          <Input
                            type="text"
                            style={{ height: 40 }}
                            value={row.debit}
                            onChange={(e) =>
                              handleNumberChange(
                                row.id,
                                'debit',
                                e.target.value,
                              )
                            }
                            onBlur={() =>
                              calculateResult(row.id, 'debit', row.debit)
                            }
                            disabled={
                              rowData.find((op) => op.id === row.id).credit > 0
                            }
                          />
                        </td>
                        <td style={{ width: '150px' }}>
                          <Input
                            type="text"
                            style={{ height: 40 }}
                            value={row.credit}
                            onChange={(e) =>
                              handleNumberChange(
                                row.id,
                                'credit',
                                e.target.value,
                              )
                            }
                            onBlur={() =>
                              calculateResult(row.id, 'credit', row.credit)
                            }
                            disabled={
                              rowData.find((op) => op.id === row.id).debit > 0
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '20px',
                  }}
                >
                  <div className="no-print">
                    <MyButton
                      icon="ion-upload"
                      label={journalId ? 'Update and New' : 'Save and New'}
                      loading={loading}
                      type="success"
                      onClick={() => handleFormSubmit('new')}
                    />
                    &nbsp;
                    <MyButton
                      icon="ion-upload"
                      label={journalId ? 'Update and Close' : 'Save and Close'}
                      loading={loading}
                      type="dark"
                      onClick={() => handleFormSubmit('close')}
                    />
                  </div>
                  <div className="no-print">
                    <button
                      className="btn btn-success"
                      onClick={() => window.print()}
                    >
                      <img src="/assets/img/icons/printer.svg" alt="img" />
                      Print
                    </button>
                    &nbsp;
                    <button className="btn btn-dark" onClick={resetForm}>
                      <i className="ion-loop"></i> Clear
                    </button>
                    &nbsp;
                    <button className="btn btn-primary" onClick={addRow}>
                      <i className="ion-plus"></i> Row
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default JournelEntry;
