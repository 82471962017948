import React, { useEffect, useState } from 'react';
import Loader from '../../Components/Loader';
import './style.css';
import { invoices } from './DummyData';
function View({ id }) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const subTotal = data?.lines?.reduce(
    (acc, line) => acc + (line.amount || 0),
    0,
  );
  const fetchData = () => {
    setLoading(true);
    const filterData = invoices.find((op) => op.invoiceId == id);
    setData(filterData);
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="page-wrapper">
      <Loader loading={loading} />
      <div class="page-content container">
        <div class="page-header text-blue-d2">
          <div class="row">
            <div class="col-12">
              <div class="text-center text-150">
                <img src="/assets/img/logo.png" width={150} />
              </div>
            </div>
          </div>
          <h1 class="page-title text-secondary-d1">
            Invoice
            <small class="page-info">
              <i class="fa fa-angle-double-right text-80"></i>
              ID: #{data?.invoiceId}
            </small>
          </h1>

          <div class="page-tools no-print">
            <div class="action-buttons">
              <a
                class="btn bg-white btn-light mx-1px text-95"
                data-title="Print"
                onClick={() => window.print()}
              >
                <i class="mr-1 fa fa-print text-primary-m1 text-120 w-2"></i>
                Print
              </a>
              {/* <a
                class="btn bg-white btn-light mx-1px text-95"
                href="#"
                data-title="PDF"
              >
                <i class="mr-1 fa fa-file-pdf-o text-danger-m1 text-120 w-2"></i>
                Export
              </a> */}
            </div>
          </div>
        </div>
        <div class="container px-0">
          <div class="row mt-4">
            <div class="col-12 col-lg-12">
              <div class="row">
                <div class="col-sm-6">
                  <div>
                    <span class="text-sm text-grey-m2 align-middle">To: </span>
                    <span class="text-600 text-110 text-blue align-middle">
                      {data?.to?.name}
                    </span>
                  </div>
                  <div class="text-grey-m2">
                    <div class="my-1">{data?.to?.address}</div>
                    <div class="my-1">
                      <i class="fa fa-phone fa-flip-horizontal text-secondary"></i>{' '}
                      <b class="text-600">{data?.to?.phone}</b>
                    </div>
                  </div>
                </div>

                <div class="text-95 col-sm-6 align-self-start d-sm-flex justify-content-end">
                  <hr class="d-sm-none" />
                  <div class="text-grey-m2">
                    <div class="mt-1 mb-2 text-secondary-m1 text-600 text-125">
                      Invoice
                    </div>

                    <div class="my-2">
                      <i class="fa fa-circle text-blue-m2 text-xs mr-1"></i>{' '}
                      <span class="text-600 text-90">ID:</span> #
                      {data?.invoiceId}
                    </div>

                    <div class="my-2">
                      <i class="fa fa-circle text-blue-m2 text-xs mr-1"></i>{' '}
                      <span class="text-600 text-90">Issue Date:</span>{' '}
                      {data?.date}
                    </div>

                    <div class="my-2">
                      <i class="fa fa-circle text-blue-m2 text-xs mr-1"></i>{' '}
                      <span class="text-600 text-90">Status:</span>{' '}
                      <span class="badge badge-warning badge-pill px-25">
                        {data?.status}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-4">
                <div class="row text-600 text-white bgc-default-tp1 py-25">
                  <div class="d-none d-sm-block col-1">#</div>
                  <div class="col-9 col-sm-5">Description</div>
                  <div class="d-none d-sm-block col-4 col-sm-2">Qty</div>
                  <div class="d-none d-sm-block col-sm-2">Unit Price</div>
                  <div class="col-2">Amount</div>
                </div>
                <div class="text-95 text-secondary-d3">
                  {data?.lines?.map((op) => (
                    <div class="row mb-2 mb-sm-0 py-25">
                      <div class="d-none d-sm-block col-1">{op.id}</div>
                      <div class="col-9 col-sm-5">{op.description}</div>
                      <div class="d-none d-sm-block col-2">{op.qty}</div>
                      <div class="d-none d-sm-block col-2 text-95">
                        ${op.unitPrice}
                      </div>
                      <div class="col-2 text-secondary-d2">${op.amount}</div>
                    </div>
                  ))}
                </div>
                <div class="row border-b-2 brc-default-l2"></div>
                <div class="row text-600 text-white bgc-default-tp1 py-25">
                  <div class="d-none d-sm-block col-1">#</div>
                  <div class="col-9 col-sm-5">Description</div>
                  <div class="d-none d-sm-block col-4 col-sm-2">Qty</div>
                  <div class="d-none d-sm-block col-sm-2">Unit Price</div>
                  <div class="col-2">Amount</div>
                </div>

                <div class="row mt-3">
                  <div class="col-12 col-sm-7 text-grey-d2 text-95 mt-2 mt-lg-0">
                    {data?.note}
                  </div>

                  <div class="col-12 col-sm-5 text-grey text-90 order-first order-sm-last">
                    <div class="row my-2">
                      <div class="col-7 text-right">SubTotal</div>
                      <div class="col-5">
                        <span class="text-120 text-secondary-d1">
                          ${subTotal}
                        </span>
                      </div>
                    </div>

                    <div class="row my-2">
                      <div class="col-7 text-right">Tax (10%)</div>
                      <div class="col-5">
                        <span class="text-110 text-secondary-d1">
                          ${subTotal * 0.1}
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div class="row my-2 align-items-center bgc-primary-l3 p-2">
                      <div class="col-7 text-right">Total Amount</div>
                      <div class="col-5">
                        <span class="text-150 text-success-d3 opacity-2">
                          ${subTotal - subTotal * 0.1}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default View;
