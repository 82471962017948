import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table as TB } from 'antd';
import callApi from '../../helpers/callApi';
import ExportComponents from '../../Components/ExportComponents';
import { branchId } from '../../config/EnVariables';
import { amount } from '../../helpers/quick';
function ContractList(props) {
  const columns = [
    {
      dataIndex: 'sno',
      title: 'Sno',
    },
    {
      dataIndex: 'id',
      title: 'ID',
    },
    {
      dataIndex: 'name',
      title: 'Transaction',
    },
    {
      dataIndex: 'shopkeeper',
      title: 'Shopkeeper',
    },
    {
      dataIndex: 'shop',
      title: 'Shop',
    },
    {
      dataIndex: 'contractType',
      title: 'Contract Type',
    },
    {
      dataIndex: 'totalPrice',
      title: 'Contract Value',
    },
    {
      dataIndex: 'startDate',
      title: 'Start Date',
    },
    {
      dataIndex: 'endDate',
      title: 'EndDate',
    },
    {
      dataIndex: 'status',
      title: 'Status',
    },
  ];

  const navigate = useNavigate();
  const [shopList, setShopList] = useState([]);
  const [loading, setLoading] = useState(false);
  let pageSizeValue = 15;
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: pageSizeValue,
      showSizeChanger: true,
      pageSizeOptions: [10, 15, 20, 25, 30, 35, 40, 45, 50, 100],
    },
  });

  let paging = { current: 1 };
  const handleTableChange = async (pagination, filter, sorter) => {
    pageSizeValue = pagination.pageSize;
    setTableParams({ pagination, filter, ...sorter });
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setShopList([]);
    }
    const params = { size: pagination.pageSize };
    paging.current = pagination.current;

    await fetchData(params);
  };

  async function fetchData(params = {}) {
    setLoading(true);
    let endpoint = `shop-contracts/branch-shops-contracts?branchId=${branchId}`;
    const params1 = {
      ...params,
      page: paging.current || 1,
      size: pageSizeValue || null,
    };
    await callApi(endpoint, null, null)
      .then((response) => {
        setLoading(false);
        if (response.data && response.status === 200) {
          setShopList(response.data);
          const totalStudents = response.headers.get('x-total-count');
          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              total: totalStudents,
              current: params1.page,
              pageSize: pageSizeValue,
            },
          });
        } else {
          console.log('students error');
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  const editShop = (id) => {
    navigate(`/edit-shop/${id}`);
  };

  const deleteShop = async (id) => {
    alert('Item will be deleted');
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="d-flex justify-content-end">
            <ExportComponents
              excelData={shopList}
              fileName={'Contracts.xlsx'}
            />
          </div>

          <TB
            className="table-striped"
            size="small"
            columns={columns}
            scroll={{ x: 1000 }}
            pagination={tableParams.pagination}
            onChange={handleTableChange}
            loading={loading}
            dataSource={shopList?.map((item, index) => ({
              key: item.id,
              sno: index + 1,
              id: item.id,
              name: <p>{item.name}</p>,
              shopkeeper: item.shopkeeperName,
              shop: item.shopName,
              contractType: item.contractType,
              totalPrice: item.totalPrice ? amount(item.totalPrice) : 'N/A',
              startDate: item.startDate,
              endDate: item.endDate || <p style={{ color: 'green' }}>Active</p>,
              status:
                item.state == 'ACTIVE' ? (
                  <span className="badge badge-pill badge-success">
                    {item.state}
                  </span>
                ) : (
                  <span className="badge badge-pill badge-danger">
                    {item.state}
                  </span>
                ),
              action: (
                <div>
                  <button
                    style={{
                      backgroundColor: 'transparent',
                      border: '0px',
                    }}
                    onClick={() => navigate(`/shop-details/${item.id}`)}
                  >
                    <img src="/assets/img/icons/eye.svg" alt="img" />
                  </button>
                  <button
                    style={{
                      backgroundColor: 'transparent',
                      border: '0px',
                    }}
                    onClick={() => editShop(item.id)}
                  >
                    <img src="assets/img/icons/edit.svg"></img>
                  </button>
                  <button
                    style={{
                      backgroundColor: 'transparent',
                      border: '0px',
                    }}
                    onClick={() => deleteShop(item.id)}
                  >
                    <img src="assets/img/icons/delete.svg"></img>
                  </button>
                </div>
              ),
            }))}
          />
        </div>
      </div>
    </>
  );
}

export default ContractList;
