import React, { useContext, useEffect, useState } from 'react';
import ExportComponents from '../../../Components/ExportComponents';
import Account from '../../../Services/accountServices';
import USDFigure from '../../../Components/USDFigure';
import { NavLink } from 'react-router-dom';
import TitleHeader from '../Components/TitleHeader';

import {
  getDates,
  getStartMonthDate,
  getTodayDate,
} from '../../../helpers/quick';
import { branchId, userRoles } from '../../../config/EnVariables';
import { AuthContext } from '../../../Auth/Context/AuthContext';
import { Table } from 'antd';
function IncomeStatement(props) {
  const { user } = useContext(AuthContext);
  const [startDate, setStartDate] = useState(getStartMonthDate);
  const [endDate, setEndDate] = useState(getTodayDate);
  const quickOptions = [
    { value: 'today', label: 'Today' },
    { value: 'week', label: 'Last Week' },
    { value: 'month', label: 'Last Month' },
    { value: 'months-3', label: 'Last Three Months' },
    { value: 'year', label: 'Last Year' },
  ];
  const [incomeStatement, setIncomeStatement] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedAccounts, setExpandedAccounts] = useState([]);
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const filter = async () => {
    fetchIncomeStatement();
  };
  const fetchIncomeStatement = async (date) => {
    setLoading(true);
    let data = {
      startDate: date || startDate,
      endDate: endDate,
    };
    await Account.getIncomeStatement(data, branchId)
      .then((ist) => {
        setIncomeStatement(ist.data);
        const data = ist.data;
        setLoading(false);
        //Expand all accounts by default
        if (isAllExpanded) {
          setExpandedAccounts([]);
        } else {
          const allAccountIds = data.map((acc) => acc.id);
          setExpandedAccounts(allAccountIds);
        }
        setIsAllExpanded(true);
        //End Expansion
      })
      .catch((err) => console.log('Error', err));
  };
  useEffect(() => {
    fetchIncomeStatement();
  }, []);
  const handleQuickOption = (value) => {
    const dates = getDates(value);
    setStartDate(dates.startDate);
    fetchIncomeStatement(dates.startDate);
  };

  const toggleAccount = (accountId) => {
    if (expandedAccounts.includes(accountId)) {
      setExpandedAccounts(expandedAccounts.filter((id) => id !== accountId));
    } else {
      setExpandedAccounts([...expandedAccounts, accountId]);
    }
  };

  const renderAccounts = (accounts, level = 0) => {
    return accounts?.map((acc) => (
      <>
        <tr>
          <td style={{ paddingLeft: level * 20 }}>
            <a key={acc.id} onClick={() => toggleAccount(acc.id)}>
              {acc.accounts?.length > 0 && (
                <i
                  class={
                    expandedAccounts.includes(acc.id)
                      ? 'ion-arrow-down-b'
                      : 'ion-arrow-right-b'
                  }
                ></i>
              )}
            </a>
            &nbsp;&nbsp;
            {acc.id ? (
              <NavLink
                style={{ color: '#000' }}
                to={
                  user.role != userRoles.superadmin
                    ? `/account-quick-report/${acc.id}`
                    : ''
                }
              >
                {acc.name}
              </NavLink>
            ) : (
              acc.name
            )}
          </td>
          {acc.balance > 0 ? (
            <p style={{ color: 'green' }}>
              <USDFigure amount={acc.balance} />
            </p>
          ) : (
            <p style={{ color: 'red' }}>
              <USDFigure amount={acc.balance} />
            </p>
          )}
        </tr>
        {expandedAccounts.includes(acc.id) &&
          acc.accounts?.map((sub) => (
            <>
              <tr>
                <td style={{ paddingLeft: (level + 1) * 20 }}>
                  <a key={sub.id} onClick={() => toggleAccount(sub.id)}>
                    {sub.subAccountArray?.length > 0 && (
                      <i
                        class={
                          expandedAccounts.includes(sub.id)
                            ? 'ion-arrow-down-b'
                            : 'ion-arrow-right-b'
                        }
                      ></i>
                    )}
                  </a>
                  &nbsp;&nbsp;
                  <NavLink
                    style={{ color: '#000' }}
                    to={
                      user.role != userRoles.superadmin
                        ? `/account-quick-report/${sub.id}`
                        : ''
                    }
                  >
                    {sub.name}
                  </NavLink>
                </td>
                {sub.balanceTotal > 0 ? (
                  <p style={{ color: 'green' }}>
                    <USDFigure amount={sub.balanceTotal} />
                  </p>
                ) : (
                  <p style={{ color: 'red' }}>
                    <USDFigure amount={sub.balanceTotal} />
                  </p>
                )}
              </tr>
              {sub.subAccountArray &&
                renderAccounts(sub.subAccountArray, level + 3)}
            </>
          ))}
      </>
    ));
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      render: (balance) =>
        balance > 0 ? (
          <p style={{ color: 'green' }}>
            <USDFigure amount={balance} />
          </p>
        ) : (
          <p style={{ color: 'red' }}>
            <USDFigure amount={balance} />
          </p>
        ),
    },
  ];
  function replaceSubAccountArrayWithChildren(data, prefix = '') {
    if (data)
      return data?.map((item, index) => {
        const key = `${prefix}${index + 1}`;
        const newItem = { ...item, key };
        if (newItem.accounts) {
          newItem.children = replaceSubAccountArrayWithChildren(
            newItem.accounts,
            `${key}-`,
          );
          delete newItem.accounts;
        }
        return newItem;
      });
  }
  return (
    // <div className="">
    <div className="page-wrapper">
      <div className="content container-fluid">
        <TitleHeader title="Income Statement" />
        <div className="row">
          <div className="col-sm-12">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="income-statement-filter no-print">
                <div>
                  Quick Option
                  <select
                    className="form-control"
                    onChange={(e) => handleQuickOption(e.target.value)}
                  >
                    {quickOptions?.map((op) => (
                      <option value={op.value}>{op.label}</option>
                    ))}
                  </select>
                </div>
                <div>
                  Start Date
                  <input
                    className="form-control "
                    type="date"
                    value={startDate}
                    name="start_date"
                    onChange={(val) => setStartDate(val.target.value)}
                  />
                </div>
                <div>
                  End Date
                  <input
                    className="form-control "
                    type="date"
                    value={endDate}
                    name="end_date"
                    onChange={(val) => setEndDate(val.target.value)}
                  />
                </div>

                <div>
                  <br />
                  <a href="#" className="btn btn-dark" onClick={filter}>
                    <i className="ion-ios7-search-strong"></i> Search
                  </a>
                </div>
              </div>

              <ExportComponents
                excelData={incomeStatement}
                fileName={endDate + 'IncomeStatement.xlsx'}
              />
            </div>

            <Table
              className="table-striped"
              loading={loading}
              columns={columns}
              dataSource={replaceSubAccountArrayWithChildren(incomeStatement)}
              size="small"
              expandable={{
                defaultExpandAllRows: true,
              }}
            />
            {/* <div className="table-responsive">
              <table className="w-100 table-striped">
                <thead className="table-bg">
                  <tr>
                    <th>Account</th>
                    <th>Balance</th>
                  </tr>
                </thead>
                <Loader loading={loading} />
                <tbody>{renderAccounts(incomeStatement)}</tbody>
              </table>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default IncomeStatement;
