import React from 'react';
import { SETTINGS } from '../config/settings';
function Footer(props) {
  return (
    <footer style={{ position: 'fixed', bottom: 0, left: '50%' }}>
      &copy; Copyright {new Date().getFullYear()}{' '}
      <a href={SETTINGS.developer.company} target="_blank">
        {SETTINGS.TITLE}
      </a>{' '}
      all rights reserved
    </footer>
  );
}

export default Footer;
