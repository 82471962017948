import Main from '../Components/Main';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';

import PageNotFound from '../Pages/PageNotFound';

import AddUser from '../Pages/users/AddUser';
import UserList from '../Pages/users/UserList';
import AccountsList from '../Pages/accounts/AccountsList';
import AddAccount from '../Pages/accounts/AddAccount';
import ShopKeeperList from '../Pages/shopKeeper/ShopKeeperList';
import AddShopKeeper from '../Pages/shopKeeper/AddShopKeeper';
import Profile from '../Pages/users/Profile';
import ShopList from '../Pages/shop/ShopList';
import AddShop from '../Pages/shop/AddShop';
import ShopKeeperDetails from '../Pages/shopKeeper/ShopKeeperDetails';
import ShopDetails from '../Pages/shop/ShopDetails';
import AuditLogs from '../Pages/Audit/AudtLogs-list';
import AddFloor from '../Pages/floor/AddFloor';
import FloorList from '../Pages/floor/FloorList';
import FloorDetails from '../Pages/floor/FloorDetails';
import ShopkeeperFullDetails from '../Pages/shopKeeper/ShopkeeperFullDetails';
import AddRent from '../Pages/rents/AddRent';
import RentList from '../Pages/rents/RentList';
import GenralJournel from '../Pages/accounts/reports/GenralJournel';
import JournelLedger from '../Pages/accounts/reports/JournelLedger';
import BalanceSheet from '../Pages/accounts/reports/BalanceSheet';
import IncomeStatement from '../Pages/accounts/reports/IncomeStatement';
import TrialBalance from '../Pages/accounts/reports/TrialBalance';
import AddBranch from '../Pages/Branches/AddBranch';
import BranchesList from '../Pages/Branches/Projects';
import JournelEntry from '../Pages/accounts/JournelEntry';
import AccountQuickReport from '../Pages/accounts/reports/AccountQuickReport';
import AddContract from '../Pages/shop/AddContract';
import ContractList from '../Pages/shop/ContractList';
import { userRoles } from '../config/EnVariables';
import { useContext } from 'react';
import { AuthContext } from './Context/AuthContext';
import { Authorize } from '../helpers/Authorize';
import AssetTransfer from '../Pages/Superadmin/AssetTransfer';
import List from '../Pages/Invoice/List';
import Detail from '../Pages/Invoice/Detail';
import SideBar from '../Components/SideBar';
import Header from '../Components/Header';
function Application() {
  const { controlSideBar, user } = useContext(AuthContext);
  return (
    <Router>
      <Header />
      <SideBar visible={controlSideBar} />
      <Routes>
        {/* {!user && <Redirect from="/*" to="/" />} */}
        {!user ? (
          <Route path="/*" element={<Navigate to="/" />} />
        ) : (
          <Route element={<PageNotFound />} path="*" />
        )}
        <Route exact path="/" element={<Main />} />

        {/* Super Admin Specific Routes */}
        {Authorize(userRoles.superadmin) && (
          <>
            <Route element={<AuditLogs />} path="/admin/audit-logs" />
            <Route element={<BranchesList />} path="/projects" />

            <Route element={<AddBranch />} path="/createBranch" />
            <Route element={<AddBranch />} path="/createBranch/:projectId" />
            <Route element={<BranchesList />} path="/branches" />

            <Route element={<AddUser />} path="add-user" />
            <Route element={<AddUser edit={true} />} path="edit-user/:id" />
            <Route element={<UserList />} path="user-list" />

            <Route element={<AssetTransfer />} path="transfer-assets" />

            <Route element={<List />} path="invoices" />
            <Route element={<Detail />} path="invoices/:id" />
          </>
        )}
        {/* Admin Roles */}
        {Authorize(userRoles.admin) ||
        Authorize(userRoles.admin_finance) ||
        Authorize(userRoles.superadmin) ? (
          <>
            {/* Shop Keeper */}
            <Route element={<ShopKeeperList />} path="shopkeeper-list" />
            <Route exact element={<AddShopKeeper />} path="add-shopkeeper" />
            <Route path="add-shopkeeper/:id" element={<AddShopKeeper />} />
            <Route
              element={<ShopKeeperDetails />}
              path="/shopkeeper-details/:id"
            />
            <Route
              element={<ShopkeeperFullDetails />}
              path="/shopkeeper/:shopkeeperId/full-details"
            />

            {/* Shop */}
            <Route element={<ShopList />} path="shop-list" />
            <Route path="/add-shop" element={<AddShop />} />
            <Route path="/add-contract" element={<AddContract />} />
            <Route path="/contract-list" element={<ContractList />} />
            <Route path="edit-shop/:id" element={<AddShop edit={true} />} />
            <Route path="/shop-details/:id" element={<ShopDetails />} />

            {/* Floor */}
            <Route element={<FloorList />} path="floor-list" />
            <Route element={<AddFloor />} path="add-floor" />
            <Route element={<AddFloor edit={true} />} path="edit-floor/:id" />
            <Route element={<FloorDetails />} path="/floor-details/:id" />
          </>
        ) : null}
        {/* Finance Roles */}
        {Authorize(userRoles.finance) ||
        Authorize(userRoles.admin_finance) ||
        Authorize(userRoles.superadmin) ? (
          <>
            {/* Accounts */}
            <Route element={<AccountsList />} path="/accounts-list" />
            <Route element={<AddAccount />} path="/add-account" />
            <Route element={<AddAccount />} path="/add-account/:id" />
            <Route element={<JournelEntry />} path="/journel-entry" />
            <Route
              element={<JournelEntry />}
              path="/journel-entry/:journalId"
            />
            {/* Financial Reports */}
            <Route
              element={<AccountQuickReport />}
              path="/account-quick-report/:accountId"
            />
            <Route element={<GenralJournel />} path="/genral-journel" />
            <Route element={<JournelLedger />} path="/journel-ledger" />
            <Route element={<BalanceSheet />} path="/balance-sheet" />
            <Route element={<IncomeStatement />} path="/income-statement" />
            <Route
              element={<IncomeStatement />}
              path="/income-statement/:branchId"
            />
            <Route element={<TrialBalance />} path="/trial-balance" />

            {/* Rents */}
            <Route element={<AddRent />} path="/add-payment" />
            <Route element={<RentList />} path="/payments" />
          </>
        ) : null}
        <Route element={<Profile />} path="user-profile" />
      </Routes>
    </Router>
  );
}
export default Application;
