import React, { useEffect, useState } from 'react';
import Account from '../../../Services/accountServices';
import ExportComponents from '../../../Components/ExportComponents';
import TitleHeader from '../Components/TitleHeader';
import AntdTrialBalance from './AntdTrialBalance';
// import "../style.css";
function TrialBalance(props) {
  const [trialBalances, setTrialBalances] = useState([]);
  const [downloadingData, setDownloadingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchTrialBalace = () => {
    setLoading(true);
    Account.getTrialBalance().then((line) => {
      setTrialBalances(line.data);

      const excelData = line.data.map((item) => ({
        account: item.accountName,
        debit: item.debit,
        credit: item.credit,
      }));
      setDownloadingData(excelData);
      setLoading(false);
    });
  };
  useEffect(() => {
    fetchTrialBalace();
  }, []);
  return (
    // <div className="">
    <div className="page-wrapper">
      <div className="content container-fluid">
        <TitleHeader title="Trial Balance" />

        <div className="row">
          <div className="col-sm-12">
            <ExportComponents
              excelData={downloadingData}
              fileName="TrialBalance.xlsx"
            />
            <AntdTrialBalance data={trialBalances} loading={loading} />
            {/* <div className="table-responsive">
              <table className="w-100 table-striped">
                <thead className="table-bg">
                  <th>
                    <strong>Account Name</strong>
                  </th>
                  <th>
                    <strong>Dr</strong>
                  </th>
                  <th>
                    <strong>Cr</strong>
                  </th>
                </thead>
                <Loader loading={loading} />
                <tbody>
                  {trialBalances.length > 0 ? (
                    trialBalances?.map((item, i) => {
                      return (
                        <tr key={i + 1}>
                          <td>
                            <NavLink
                              to={`/account-quick-report/${item.accountId}`}
                              style={{ color: '#000' }}
                            >
                              {item.accountName}
                            </NavLink>
                          </td>
                          <td>{item.debit ? amount(item.debit) : ''}</td>
                          <td>{item.credit ? amount(item.credit) : ''}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <p>No Data</p>
                  )}
                </tbody>
                <tfoot className="table-bg">
                  <tr>
                    <td>
                      <strong>Total USD</strong>
                    </td>
                    <td>
                      <strong>
                        {amount(
                          trialBalances.reduce((accumulator, currentObject) => {
                            return accumulator + currentObject.debit;
                          }, 0),
                        )}
                      </strong>
                    </td>
                    <td>
                      <strong>
                        {amount(
                          trialBalances.reduce((accumulator, currentObject) => {
                            return accumulator + currentObject.credit;
                          }, 0),
                        )}
                      </strong>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrialBalance;
