import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import Shopkeeper from '../../Services/shopkeeperServices';
import TitleHeader from '../accounts/Components/TitleHeader';
import { LabelHead } from '../../Components/LableHead';
import '../style.css';
import VisitingCard from './Components/VisitingCard';
import PrintModal from '../../Components/PrintModal';
import Loader from '../../Components/Loader';
import { Table } from 'antd';
function ShopKeeperDetails(props) {
  const shopKeeperId = useParams().id;
  const [shopKeeper, setShopKeeper] = useState({});
  const [shopkeeperContracts, setShopkeeperContracts] = useState();
  const [loading, setLoading] = useState(false);
  const [rents, setRents] = useState();
  const getShopKeeper = async () => {
    setLoading(true);
    await Shopkeeper.getOne(shopKeeperId)
      .then((data) => {
        setShopKeeper(data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const getShopkeeperRents = async () => {
    setLoading(true);
    await Shopkeeper.getRents(shopKeeperId)
      .then((data) => {
        setRents(data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getShopkeeperContracts = async () => {
    setLoading(true);
    await Shopkeeper.getContracts(shopKeeperId)
      .then((data) => {
        setShopkeeperContracts(data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getShopKeeper();
    getShopkeeperRents();
    getShopkeeperContracts();
  }, []);
  const rentsColumns = [
    {
      title: 'Sno',
      dataIndex: 'sno',
      key: 'sno',
      responsive: ['sm'],
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Shop',
      dataIndex: 'shopName',
      key: 'shopName',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Transaction Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
    },
  ];
  const contractsColumns = [
    {
      title: 'Sno',
      dataIndex: 'sno',
      key: 'sno',
      responsive: ['sm'],
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Contract Date',
      dataIndex: 'contractDate',
      key: 'contractDate',
    },
    {
      title: 'Shop',
      dataIndex: 'shopName',
      key: 'shopName',
    },
    {
      title: 'Contract Type',
      dataIndex: 'contractType',
      key: 'contractType',
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
    },
  ];
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="no-print">
            <TitleHeader title="Full details of a shopkeeper" />
          </div>

          <div className="text-end">
            <a
              class="btn bg-white btn-light mx-1px text-95"
              data-target="#printModal"
              data-toggle="modal"
            >
              <i class="mr-1 fa fa-print text-primary-m1 text-120 w-2"></i>
              Print Card
            </a>
          </div>

          <VisitingCard
            shopKeeper={shopKeeper}
            shopKeeperId={shopKeeperId}
            activeShop={shopkeeperContracts && shopkeeperContracts[0]?.shopName}
          />
          <PrintModal
            content={
              <VisitingCard
                shopKeeper={shopKeeper}
                shopKeeperId={shopKeeperId}
                activeShop={
                  shopkeeperContracts && shopkeeperContracts[0]?.shopName
                }
              />
            }
          />
          <Loader loading={loading} />
          <div className="">
            <LabelHead label="Rents History" />
            <Table
              size="small"
              loading={loading}
              columns={rentsColumns}
              dataSource={rents?.map((item, i) => ({
                sno: i + 1,
                name: item.name,
                date: item.date,
                shopName: item.shopName,
                amount: item.amount,
                transactionType: item.transactionType,
              }))}
            />

            <LabelHead label="Contracts" />
            <Table
              size="small"
              loading={loading}
              columns={contractsColumns}
              dataSource={shopkeeperContracts?.map((item, i) => ({
                sno: i + 1,
                name: item.name,
                contractDate: item.contractDate,
                shopName: item.shopName,
                contractType: item.contractType,
                state: item.state,
              }))}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ShopKeeperDetails;
