import React, { useContext, useEffect, useState } from 'react';
import Account from '../../Services/accountServices';
import { Input, message } from 'antd';
import { AuthContext } from '../../Auth/Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import TitleHeader from '../accounts/Components/TitleHeader';
import { SETTINGS } from '../../config/settings';
import { AntdSelectField } from '../../Components/InputFormFields';
import { getDates } from '../../helpers/quick';
import Loader from '../../Components/Loader';
import { evaluate } from 'mathjs';
import { MyButton } from '../../Components/MyButton';
function AssetTransfer(props) {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [isAfnAccount, setIsAfnAccount] = useState(false);
  const [exchangeRate, setExchangeRate] = useState();
  const [date, setDate] = useState(getDates('today')?.startDate);
  const [attachment, setAttachmentName] = useState();
  const initialRows = [
    { id: 1, debit: '', credit: '', accountId: null, memo: '' },
    { id: 2, debit: '', credit: '', accountId: null, memo: '' },
  ];

  const [rowData, setRowData] = useState(initialRows);
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const handleNumberChange = (id, field, value) => {
    const updatedData = rowData.map((row, index) => {
      if (row.id === id) {
        // return { ...row, [field]: parseInt(value) };
        return { ...row, [field]: value };
      }
      // Auto-fill the memo attribute of the next row if the current row is filled
      else {
        const nextRow = rowData[index];
        if (index === id) {
          if (field === 'debit')
            return { ...row, ...(nextRow && { credit: value }) };
          if (field === 'credit')
            return { ...row, ...(nextRow && { debit: value }) };
        }
      }
      //end next row
      return row;
    });
    setRowData(updatedData);
  };
  const handleSelectChange = (id, value) => {
    const updatedData = rowData.map((row, index) => {
      if (row.id === id) {
        return { ...row, accountId: value };
      }
      return row;
    });
    fetchAccounts(value);
    const isAFNExists = updatedData.filter(
      (op) =>
        accounts.find((o) => o.id === op.accountId)?.currencyName === 'AFN',
    );
    if (isAFNExists.length > 0) setIsAfnAccount(true);
    else setIsAfnAccount(false);
    setRowData(updatedData);
  };

  const handleTextChange = (id, value) => {
    const updatedData = rowData.map((row, index) => {
      if (row.id === id) {
        return { ...row, memo: value };
      }
      // Auto-fill the memo attribute of the next row if the current row is filled
      else {
        const nextRow = rowData[index];
        if (index === id) return { ...row, ...(nextRow && { memo: value }) };
      }
      //end Next row
      return row;
    });
    setRowData(updatedData);
  };

  const handleFormSubmit = async (action) => {
    setLoading(true);
    let totalCredit = 0;
    let totalDebit = 0;

    const lineItems = rowData.filter((op) => op.credit || op.debit);
    const lines = lineItems.map((item) => {
      const { id, ...rest } = item;
      return rest;
    });
    //see if account is selected for every row
    for (let i = 0; i < lines.length; i++) {
      if (!lines[i].debit) lines[i].debit = null;
      if (!lines[i].credit) lines[i].credit = null;
      if (lines[i].credit) totalCredit += parseInt(lines[i].credit);
      if (lines[i].debit) totalDebit += parseInt(lines[i].debit);
      if (!lines[i].accountId) {
        setLoading(false);
        return message.error('Please Select Account');
      } else {
        if (lines[i]?.accountId === lines[i + 1]?.accountId) {
          setLoading(false);
          return message.error('Accounts must be different');
        }
      }
    }
    const filteredAccounts = accounts.filter((account) => {
      return (
        account.currencyName === 'AFN' &&
        lines.some((item) => item.accountId === account.id)
      );
    });
    if (filteredAccounts.length > 0) setIsAfnAccount(true);

    // both side must be balanced and if AFN account exists then exchange rate is must
    if (totalCredit === totalDebit && totalCredit !== 0 && totalDebit !== 0) {
      if (isAfnAccount && !exchangeRate) {
        setLoading(false);
        return message.error('Exchange Rate must be provided for AFN accounts');
      }
      const data = {
        currencyRate: parseInt(exchangeRate) || null,
        transactionType: 'GENERAL_JOURNAL',
        date: new Date(date).toISOString(),
        lines,
        branchId: user.branchId,
        attachment: attachment ? await toBase64(attachment) : null,
      };
      await Account.transferAssets(data)
        .then((response) => {
          message.success('Entry Saved');
          setLoading(false);
          if (action === 'close') navigate('/genral-journel');
          else window.location.reload();
        })
        .catch((error) => {
          setLoading(false);
          message.error('Network Error');
          console.log('Network Error', error);
        });
    } else {
      setLoading(false);
      message.error('Transaction is not Balanced');
    }
  };

  const fetchAccounts = async (accId) => {
    await Account.getAllBankCashAccounts().then((res) => {
      if (accId)
        setAccounts(accounts.filter((account) => account.id !== accId));
      else setAccounts(res.data);
    });
  };

  const resetForm = () => {
    setRowData(initialRows);
    const updatedRowData = initialRows.map((row) => ({
      ...row,
      accountId: '',
    }));
    setRowData(updatedRowData);
  };
  useEffect(() => {
    fetchAccounts();
  }, []);
  const calculateResult = (id, field, value) => {
    try {
      const calculatedResult = evaluate(value);
      handleNumberChange(id, field, calculatedResult);
    } catch (error) {
      message.error('Please enter a valid expression or value');
    }
  };
  return (
    <>
      {/* <div className=" "> */}
      <div className="page-wrapper">
        <Loader loading={loading} />
        <div className="content">
          <TitleHeader title="Asset transfer between the porjects" />
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <strong>Date: </strong>
                    <input
                      className="form-control "
                      type="date"
                      value={date}
                      name="date"
                      onChange={(val) => setDate(val.target.value)}
                    />
                  </div>

                  <div>
                    <strong>Exchange Rate</strong>
                    <Input
                      disabled={!isAfnAccount}
                      className="form-group"
                      type="number"
                      placeholder="Exchange Rate"
                      name="exchangeRate"
                      onChange={(e) => setExchangeRate(e.target.value)}
                    />
                  </div>
                  <div className="no-print">
                    <strong>Receipt</strong>
                    <Input
                      type="file"
                      name="attachment"
                      onChange={(e) => setAttachmentName(e.target.files[0])}
                    />
                  </div>
                </div>
                <table>
                  <thead>
                    <th>
                      <strong>Account</strong>
                    </th>
                    <th>
                      <strong>Memo</strong>
                    </th>
                    <th>
                      <strong>
                        Debit{' '}
                        <span style={{ color: 'green' }}>
                          (
                          {isAfnAccount
                            ? SETTINGS.currencies.afn
                            : SETTINGS.currencies.usd}
                          )
                        </span>
                      </strong>
                    </th>
                    <th>
                      <strong>
                        Credit{' '}
                        <span style={{ color: 'green' }}>
                          (
                          {isAfnAccount
                            ? SETTINGS.currencies.afn
                            : SETTINGS.currencies.usd}
                          )
                        </span>
                      </strong>
                    </th>
                  </thead>
                  <tbody>
                    {rowData.map((row) => (
                      <tr key={row.id}>
                        <td>
                          <AntdSelectField
                            size="full"
                            name="accountId"
                            value={row.accountId}
                            options={accounts.map((item) => ({
                              value: item.id,
                              label: `${item.name}-(${
                                item.branchName || 'PR-' + item.branchId
                              })`,
                            }))}
                            onChange={(e) => handleSelectChange(row.id, e)}
                          />
                        </td>
                        <td className="w-50">
                          <Input
                            style={{ height: 40 }}
                            type="text"
                            value={row.memo}
                            onChange={(e) =>
                              handleTextChange(row.id, e.target.value)
                            }
                          />
                        </td>
                        <td style={{ width: '150px' }}>
                          <Input
                            style={{ height: 40 }}
                            type="text"
                            value={row.debit}
                            onChange={(e) =>
                              handleNumberChange(
                                row.id,
                                'debit',
                                e.target.value,
                              )
                            }
                            onBlur={() =>
                              calculateResult(row.id, 'debit', row.debit)
                            }
                            disabled={
                              rowData.find((op) => op.id === row.id).credit > 0
                            }
                          />
                        </td>
                        <td style={{ width: '150px' }}>
                          <Input
                            style={{ height: 40 }}
                            type="text"
                            value={row.credit}
                            onChange={(e) =>
                              handleNumberChange(
                                row.id,
                                'credit',
                                e.target.value,
                              )
                            }
                            onBlur={() =>
                              calculateResult(row.id, 'credit', row.credit)
                            }
                            disabled={
                              rowData.find((op) => op.id === row.id).debit > 0
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '20px',
                  }}
                >
                  <div className="no-print">
                    <MyButton
                      type="success"
                      label="Save and New"
                      icon="ion-upload"
                      loading={loading}
                      onClick={() => handleFormSubmit('new')}
                    />
                    &nbsp;
                    <MyButton
                      type="dark"
                      label="Save and Close"
                      icon="ion-upload"
                      loading={loading}
                      onClick={() => handleFormSubmit('close')}
                    />
                  </div>
                  <div className="no-print">
                    <button
                      className="btn btn-success"
                      onClick={() => window.print()}
                    >
                      <img src="/assets/img/icons/printer.svg" alt="img" />
                      Print
                    </button>
                    &nbsp;
                    <button className="btn btn-dark" onClick={resetForm}>
                      <i className="ion-loop"></i> Clear
                    </button>
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AssetTransfer;
