import React, { useContext, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { message } from 'antd';
import { branchId } from '../../../config/EnVariables';
import {
  AntdSelectField,
  InputField,
} from '../../../Components/InputFormFields';
import { addAccountSchemaValidation } from '../../../config/Validations';
import { MyButton } from '../../../Components/MyButton';
import Watermark from '../../../Components/Watermark';
import Account from '../../../Services/accountServices';
import { AuthContext } from '../../../Auth/Context/AuthContext';
import { colors } from '../../../config/theme';
import { booleanOptions } from '../../../Utils/options';

function AccountModal({
  updatingAccount,
  setUpdatingAccount,
  accounts,
  getAccounts,
}) {
  const { branches } = useContext(AuthContext);

  const [accountTypes, setAccountTypes] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  const addAccount = async (formValues) => {
    setIsloading(true);
    const data = {
      name: formValues.name,
      note: formValues.note,
      currencyId: parseInt(formValues.currency),
      currencyName: currencies.find(
        (op) => op.id === parseInt(formValues.currency),
      )?.name,
      accountTypeId: parseInt(formValues.accountType),
      accountTypeName: accountTypes.find(
        (op) => op.id === parseInt(formValues.accountType),
      )?.name,
      branchId: branchId,
      branchName: branches?.find((op) => op.id === branchId)?.name,
      parentId: parseInt(formValues.parentId),
      parentName: accounts.find((op) => op.id === parseInt(formValues.parentId))
        ?.name,
      code: formValues.code,
    };
    if (formValues.parentId) {
      const acc = accounts.find(
        (op) => op.id === parseInt(formValues.parentId),
      );
      data.accountTypeId = acc.accountTypeId;
      data.accountTypeName = acc.accountTypeName;
      data.currencyId = acc.currencyId;
      data.currencyName = acc.currencyName;
    }

    if (updatingAccount?.id) {
      data.id = updatingAccount?.id;
      await Account.updateAccount(data)
        .then((response) => {
          if (response.data) {
            setIsloading(false);
            getAccounts();
            return message.success('Account Updated Successfully');
          }
        })
        .catch((err) => {
          message.error('Failed to Update Account');
          setIsloading(false);
        });
    } else {
      await Account.createAccount(data)
        .then((response) => {
          if (response.data) {
            setIsloading(false);
            message.success('Account Created Successfully');
            getAccounts();
          }
        })
        .catch((err) => {
          message.error('Failed to Create Account');
          setIsloading(false);
        });
    }
  };
  const fetchAccountTypes = async () => {
    Account.getAccountTypes().then((types) => {
      setAccountTypes(types.data);
    });
  };
  const fetchCurrencies = async () => {
    Account.getActivatedCurrencies().then((curr) => {
      setCurrencies(curr.data);
    });
  };

  useEffect(() => {
    fetchAccountTypes();
    fetchCurrencies();
  }, []);
  const initialValues = {
    name: updatingAccount?.name || '',
    accountType: updatingAccount?.accountTypeId || '',
    currency: updatingAccount?.currencyId || '',
    code: updatingAccount?.code || '',
    parentId: updatingAccount?.parentId || '',
    isSubAccount: updatingAccount?.parentId ? true : false || false,
  };
  return (
    <div
      className="modal fade"
      id="printModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="printModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header" style={{ background: colors.blue }}>
            <h5 className="modal-title" id="printModalLabel">
              {updatingAccount?.id
                ? `Updating Account ${updatingAccount?.code}-${updatingAccount?.name}`
                : 'Account Registeration'}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setUpdatingAccount(null);
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Watermark />
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={addAccount}
              validationSchema={addAccountSchemaValidation}
            >
              {({ errors, touched, values, setFieldValue, handleSubmit }) => (
                <>
                  <InputField
                    size="full"
                    name="name"
                    label="Account Name"
                    type="text"
                    required={true}
                    errors={errors['name']}
                    touched={touched['name']}
                  />

                  <InputField
                    size="full"
                    name="code"
                    label="Account Number"
                    type="number"
                    required={true}
                    errors={errors['code']}
                    touched={touched['code']}
                  />
                  {/* <CheckBox
                    size="full"
                    label="Is Sub Account?"
                    name="isSubAccount"
                    value={values.isSubAccount}
                    checked={values.isSubAccount ? true : false}
                    onChange={(e) => {
                      setFieldValue('isSubAccount', e.target.checked);
                      setFieldValue('parentId', null);
                      setFieldValue('accountType', null);
                    }}
                  /> */}
                  <AntdSelectField
                    size="full"
                    name="isSubAccount"
                    label="Is Sub Account?"
                    value={values.isSubAccount}
                    options={booleanOptions}
                    onChange={(e) => {
                      setFieldValue('isSubAccount', e);
                      setFieldValue('parentId', null);
                      setFieldValue('accountType', null);
                    }}
                  />

                  {values.isSubAccount && (
                    <AntdSelectField
                      size="full"
                      name="parentId"
                      label="Sub Account Of"
                      value={values.parentId}
                      options={accounts?.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                      onChange={(e) => setFieldValue('parentId', e)}
                      errors={errors['parentId']}
                      touched={touched['parentId']}
                    />
                  )}

                  {!values.parentId && !values.isSubAccount && (
                    <>
                      <AntdSelectField
                        size="full"
                        name="accountType"
                        label="Account Type"
                        value={values.accountType}
                        options={accountTypes?.map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))}
                        onChange={(e) => setFieldValue('accountType', e)}
                        errors={errors['accountType']}
                        touched={touched['accountType']}
                      />
                      <AntdSelectField
                        size="full"
                        name="currency"
                        label="Currency"
                        value={values.currency}
                        options={currencies?.map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))}
                        onChange={(e) => setFieldValue('currency', e)}
                        errors={errors['currency']}
                        touched={touched['currency']}
                      />
                    </>
                  )}

                  <div className="modal-footer">
                    <MyButton
                      type="success"
                      label={updatingAccount?.id ? 'Update' : 'Save'}
                      icon="ion-plus"
                      loading={isLoading}
                      onClick={handleSubmit}
                    />
                    &nbsp;
                    <button
                      className="btn btn-danger"
                      data-dismiss="modal"
                      onClick={() => {
                        setUpdatingAccount(null);
                      }}
                    >
                      <i className="ion-close"></i> Cancel
                    </button>
                  </div>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountModal;
