import React from 'react';
import { Table } from 'antd';
import TitleHeader from '../accounts/Components/TitleHeader';
import { Link } from 'react-router-dom';
import { invoices } from './DummyData';

function List(props) {
  const columns = [
    {
      title: 'Invoice',
      dataIndex: 'invoiceId',
      key: 'invoiceId',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'To',
      dataIndex: 'to',
      key: 'to',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, item) => <Link to={`/invoices/${item.invoiceId}`}>View</Link>,
    },
  ];
  return (
    <div className="page-wrapper">
      <TitleHeader title="Invoices" />
      <Table
        columns={columns}
        dataSource={invoices.map((item) => ({
          invoiceId: item.invoiceId,
          date: item.date,
          to: item.to?.name,
          amount: item.total,
        }))}
      />
    </div>
  );
}

export default List;
