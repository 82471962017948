import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { message } from 'antd';
import Loader from '../../Components/Loader';
import Branch from '../../Services/branchServices';
import { businessTypeOptions } from '../../Utils/options';
import { NavLink, useParams } from 'react-router-dom';
import TitleHeader from '../accounts/Components/TitleHeader';
import callApi from '../../helpers/callApi';
import { AntdSelectField, InputField } from '../../Components/InputFormFields';
import { addProjectSchemaValidation } from '../../config/Validations';
function AddBranch(props) {
  const { projectId } = useParams();
  const [isLoading, setIsloading] = useState(false);
  const [updatingBranch, setUpdatingBranch] = useState();
  const getOneBranch = () => {
    Branch.getOne(projectId)
      .then((response) => {
        setUpdatingBranch(response.data);
      })
      .catch((err) => console.log(err));
  };
  const AddBranch = async (formValues, { resetForm }) => {
    let reqMethod = 'POST';
    if (projectId) {
      reqMethod = 'PATCH';
      return message.warning('Update feature will be added soon');
    }
    setIsloading(true);
    callApi('branches', reqMethod, formValues)
      .then((response) => {
        if (response.data) {
          setIsloading(false);
          resetForm();
          message.success('Branch Created Successfully');
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
        message.error('Error in Branch Creation');
        setIsloading(false);
      });
  };

  useEffect(() => {
    if (projectId) getOneBranch();
  }, []);

  return (
    <>
      {/* <div className=""> */}
      <div className="page-wrapper">
        <div className="content">
          <TitleHeader title="Create new Project" />
          <div className="container card">
            <div className="card-body">
              <Loader loading={isLoading} />
              <div className="row">
                <Formik
                  enableReinitialize
                  initialValues={{
                    name: updatingBranch?.name || '',
                    businessType: updatingBranch?.businessType || '',
                    description: updatingBranch?.description || '',
                  }}
                  onSubmit={AddBranch}
                  validationSchema={addProjectSchemaValidation}
                >
                  {({
                    errors,
                    touched,
                    values,
                    setFieldValue,
                    handleSubmit,
                  }) => (
                    <div className="row">
                      <InputField
                        label="Project Name"
                        name="name"
                        type="text"
                        required={true}
                        value={values.name}
                        onChange={(value) => setFieldValue('name', value)}
                        errors={errors['name']}
                        touched={touched['name']}
                      />

                      <AntdSelectField
                        label="Business Type"
                        name="businessType"
                        value={values.businessType}
                        options={businessTypeOptions}
                        required={true}
                        onChange={(value) =>
                          setFieldValue('businessType', value)
                        }
                        errors={errors['businessType']}
                        touched={touched['businessType']}
                      />

                      <InputField
                        label="Description"
                        name="description"
                        value={values.description}
                        type="text"
                        onChange={(value) =>
                          setFieldValue('description', value)
                        }
                        errors={errors['description']}
                        touched={touched['description']}
                      />
                      <div className="col-lg-12">
                        <a
                          href="#"
                          className="btn btn-primary me-2"
                          onClick={handleSubmit}
                        >
                          {projectId ? 'Update' : 'Save'}
                        </a>
                        <NavLink to="/branches" className="btn btn-secondary">
                          Cancel
                        </NavLink>
                      </div>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddBranch;
