import * as Yup from 'yup';

export const loginValidation = Yup.object().shape({
  email: Yup.string().required('Email is Required'),
  password: Yup.string().required('Password is Required'),
});

export const addProjectSchemaValidation = Yup.object().shape({
  name: Yup.string().required('Project Name is Required'),
  businessType: Yup.string().required('Business Type is Required'),
});

export const addAccountSchemaValidation = Yup.object().shape({
  name: Yup.string().required('Account Name is Required'),
  code: Yup.string().required('Account Number is Required'),
  accountType: Yup.string()
    .nullable()
    .when('isSubAccount', {
      is: (value) => value === false,
      then: (schema) => schema.required('Account Type is Required'),
      otherwise: (schema) => schema,
    }),
  parentId: Yup.number()
    .nullable()
    .when('isSubAccount', {
      is: (value) => value === true,
      then: (schema) => schema.required('Parent Account is Required'),
      otherwise: (schema) => schema,
    }),
});
export const addShopSchemaValidation = Yup.object().shape({
  name: Yup.string().required('Shop Number is Required'),
  area: Yup.number().required('Required').positive(),
  floor: Yup.string().required('Floor is Required'),
});

export const addShopkeeperSchemaValidation = Yup.object().shape({
  name: Yup.string().required('Name is Required'),
  phone: Yup.string().required('Phone is Required'),
  address: Yup.string().required('Address is Required'),
});

export const addContractSchemaValidation = Yup.object().shape({
  shopkeeperId: Yup.string().required('Required'),
  shopId: Yup.string().required('Required'),
  contractType: Yup.string().required('Required'),
  startDate: Yup.string().required('Required'),
  totalPrice: Yup.number()
    .nullable()
    .when('contractType', {
      is: (value) => value !== 'PERMANENT',
      then: (schema) => schema.required('Required'),
      otherwise: (schema) => schema,
    }),
  monthlyRent: Yup.number()
    .nullable()
    .when('contractType', {
      is: (value) => value !== 'PERMANENT',
      then: (schema) => schema.required('Required'),
      otherwise: (schema) => schema,
    }),
  endDate: Yup.string()
    .nullable()
    .when('contractType', {
      is: (value) => value !== 'PERMANENT',
      then: (schema) => schema.required('Required'),
      otherwise: (schema) => schema,
    }),
});

export const addFloorSchemaValidation = Yup.object().shape({
  floorNumber: Yup.string().required('Floor Number is Required'),
  area: Yup.number().positive('Area is Required'),
});

export const addPaymentSchemaValidation = Yup.object().shape({
  shopkeeperId: Yup.string().required('Shopkeeper is Required'),
  shopId: Yup.string().required('shop is Required'),
  paymentSource: Yup.string().required('Source of Payment is Required'),
  accountId: Yup.string().required('Account is Required'),
  amount: Yup.number().required('Amount is Required'),
  date: Yup.string().required('Date is Required'),
  memo: Yup.string().required('Memo is Required'),
});

export const addUserSchemaValidation = Yup.object().shape({
  branchId: Yup.number().required('Project is Required'),
  username: Yup.string().required('Username is Required'),
  email: Yup.string().required('Email type is Required'),
  role: Yup.string().required('Role is Required'),
});

export const resetPasswordSchemaValidation = Yup.object().shape({
  oldPassword: Yup.string().required('Old Password is Required'),
  newPassword: Yup.string().required('Type New Password'),
  confirmPassword: Yup.string()
    .required('Repeat Your Password')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});
