const now = new Date();
export const invoices = [
  {
    invoiceId: 1,
    date: `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()}`,
    status: 'unpaid',
    to: {
      name: 'Ahmad',
      address: 'Kabul, Afghanistan',
      phone: '0711111111',
    },
    total: 360,
    lines: [
      {
        id: 1,
        description: 'something as descriptive 1',
        qty: 4,
        unitPrice: 30,
        amount: 120,
      },
      {
        id: 2,
        description: 'something as descriptive 2',
        qty: 12,
        unitPrice: 23,
        amount: 234,
      },
      {
        id: 3,
        description: 'something as descriptive 3',
        qty: 21,
        unitPrice: 12,
        amount: 534,
      },
      {
        id: 4,
        description: 'something as descriptive 4',
        qty: 10,
        unitPrice: 20,
        amount: 234,
      },
    ],
  },
  {
    invoiceId: 2,
    date: `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()}`,
    status: 'paid',
    to: {
      name: 'Ali',
      address: 'Kabul, Afghanistan',
      phone: '0722222222',
    },
    total: 360,
    lines: [
      {
        id: 1,
        description: 'something as descriptive 1',
        qty: 19,
        unitPrice: 15,
        amount: 445,
      },
      {
        id: 2,
        description: 'something as descriptive 2',
        qty: 16,
        unitPrice: 44,
        amount: 760,
      },
      {
        id: 3,
        description: 'something as descriptive 3',
        qty: 8,
        unitPrice: 34,
        amount: 221,
      },
    ],
  },
];
