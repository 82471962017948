import React, { useContext, useEffect, useState } from 'react';
import { browserData } from '../config/EnVariables';
import { AuthContext } from './Context/AuthContext';
import Shopkeeper from '../Services/shopkeeperServices';
import { colors } from '../config/theme';

const logout = () => {
  localStorage.removeItem(browserData.access_token);
  localStorage.removeItem(browserData.user);
  localStorage.removeItem(browserData.globalProject);
  window.location.replace('/');
};
export const ShopkeeperView = () => {
  const { user } = useContext(AuthContext);
  const [rents, setRents] = useState();
  const [loading, setLoading] = useState();
  const getRent = async () => {
    setLoading(true);
    await Shopkeeper.getRents(user.shopkeeperId.toString())
      .then((data) => {
        setRents(data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getRent();
  }, []);
  return (
    <div className="container">
      <h3 className="text-center">Shopkeeper View </h3>
      <table className="table">
        <thead style={{ background: colors.primary }}>
          <th>Name</th>
          <th>Email</th>
          <th>Phone</th>
          <th>Building</th>
        </thead>
        <tbody>
          <tr>
            <td>{user.shopkeeperName}</td>
            <td>{user.email}</td>
            <td>{user.phone}</td>
            <td>{user.branchName || user.branchId}</td>
          </tr>
        </tbody>
      </table>

      <h2>Payment History</h2>
      <table className="table">
        <thead style={{ background: colors.primary }}>
          <th>Transaction</th>
          <th>Date</th>
          <th>Shop</th>
          <th>Amount</th>
          <th>Type</th>
          <th>Exc Rate</th>
        </thead>
        <tbody>
          {rents?.map((item, i) => (
            <tr key={i + 1}>
              <td>{item.name}</td>
              <td>{item.date}</td>
              <td>{item.shopName}</td>
              <td>{item.amount || '$0.00'}</td>
              <td>{item.transactionType}</td>
              <td>{item.currencyRate}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="w-100 d-flex justify-content-end">
        <button className="btn btn-light" onClick={logout}>
          <img src="/assets/img/icons/log-out.svg" className="me-2" alt="img" />
          Logout
        </button>
      </div>
    </div>
  );
};
