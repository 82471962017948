import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { AntdSelectField, InputField } from '../../Components/InputFormFields';
import { AuthContext } from '../../Auth/Context/AuthContext';
import Shop from '../../Services/shopServices';
import { Upload, message } from 'antd';
import TitleHeader from '../accounts/Components/TitleHeader';
import Loader from '../../Components/Loader';
import { branchId } from '../../config/EnVariables';
import { MyButton } from '../../Components/MyButton';
import { addShopSchemaValidation } from '../../config/Validations';
function AddShop(props) {
  const { floors, accounts } = useContext(AuthContext);
  const shopId = useParams().id;
  const [loading, setLoading] = useState(false);
  const [updatingRecord, setUpdatingRecord] = useState();

  const getShopEdit = async () => {
    if (shopId) {
      setLoading(true);
      await Shop.getOne(shopId)
        .then((data) => {
          setUpdatingRecord(data.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const navigate = useNavigate();
  const initialValues = {
    name: updatingRecord?.name || '',
    floor: updatingRecord?.buildingFloorId || '',
    area: updatingRecord?.area || 0,
    revenueAccountId: updatingRecord?.revenueAccountId || [],
    maintenanceAccountId: updatingRecord?.maintenanceAccountId || [],
    servicesAccountId: updatingRecord?.servicesAccountId || [],
    utilitiesAccountId: updatingRecord?.utilitiesAccountId || [],
    isSpliteable: updatingRecord?.isSpliteable || false,
  };
  const addShop = (values) => {
    setLoading(true);
    values.branchId = branchId;
    values.buildingFloorId = parseInt(values.floor) || 1;

    values.maintenanceAccountName = accounts.find(
      (op) => op.id === parseInt(values.maintenanceAccountId),
    )?.name;
    values.servicesAccountName = accounts.find(
      (op) => op.id === parseInt(values.servicesAccountId),
    )?.name;
    values.utilitiesAccountName = accounts.find(
      (op) => op.id === parseInt(values.utilitiesAccountId),
    )?.name;
    values.revenueAccountName = accounts.find(
      (op) => op.id === parseInt(values.revenueAccountId),
    )?.name;
    values.buildingFloorFloorNumber = floors.find(
      (op) => op.id === parseInt(values.floor),
    )?.floorNumber;
    const data = {
      ...values,
      revenueAccountId: parseInt(values.revenueAccountId),
      maintenanceAccountId: parseInt(values.maintenanceAccountId),
      serviceAccountId: parseInt(values.serviceAccountId),
      utilitiesAccountId: parseInt(values.utilitiesAccountId),

      monthlyRent: 0,
    };
    if (shopId) {
      data.id = shopId;
      Shop.update(data, shopId)
        .then((data) => {
          message.success('Updated Successfully');
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          message.error('Faild to Save Shop');
        });
    } else
      Shop.add(data)
        .then((data) => {
          setLoading(false);
          if (data.data.status === 'Fail')
            return message.error('Failed to save');
          else message.success('Shop Saved Successfully');
          navigate(`/shop-details/${data.data.id}`);
        })
        .catch((err) => {
          setLoading(false);
          message.error(err.response.data?.message);
        });
  };

  const importFile = async (file) => {
    if (!file) return message.warning('Please Select a file first');
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    Shop.importShops(formData)
      .then((response) => {
        message.success('Shops imported Successfully');
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error('Failed to Import');
      });
  };
  useEffect(() => {
    getShopEdit();
  }, []);

  return (
    <>
      {/* <div className=" "> */}
      <div className="page-wrapper">
        <div className="content">
          <TitleHeader title="Add New Shop" />
          <Loader loading={loading} />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Upload onChange={(e) => importFile(e.file.originFileObj)}>
              <a className="btn btn-light">
                <i
                  className="ion-ios7-cloud-download"
                  data-bs-toggle="tooltip"
                  title="Import File"
                ></i>{' '}
                Import
              </a>
            </Upload>
          </div>
          <div className="card">
            <div className="card-body">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={addShop}
                validationSchema={addShopSchemaValidation}
              >
                {({ errors, touched, values, setFieldValue, handleSubmit }) => (
                  <>
                    <div className="d-flex">
                      <InputField
                        label="Shop Number"
                        name="name"
                        type="text"
                        required={true}
                        errors={errors['name']}
                        touched={touched['name']}
                      />

                      <InputField
                        label={
                          <>
                            Shop Area (m<sup>2</sup>)
                          </>
                        }
                        name="area"
                        type="number"
                        required={true}
                        errors={errors['area']}
                        touched={touched['area']}
                      />
                    </div>
                    <div className="d-flex">
                      <AntdSelectField
                        name="floor"
                        required={true}
                        label="Floor #"
                        value={values.floor}
                        options={floors?.map((item) => ({
                          value: item.id,
                          label: 'Floor #' + item.floorNumber,
                        }))}
                        onChange={(e) => setFieldValue('floor', e)}
                        errors={errors['floor']}
                        touched={touched['floor']}
                      />

                      <AntdSelectField
                        name="revenueAccountId"
                        label="Revenue Account"
                        value={values.revenueAccountId}
                        options={accounts
                          .filter(
                            (op) => op.generalAccountCategory === 'Income',
                          )
                          ?.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                        onChange={(e) => setFieldValue('revenueAccountId', e)}
                        errors={errors['revenueAccountId']}
                        touched={touched['revenueAccountId']}
                      />

                      <AntdSelectField
                        name="maintenanceAccountId"
                        label="Maintenance Account"
                        value={values.maintenanceAccountId}
                        options={accounts
                          .filter(
                            (op) => op.generalAccountCategory === 'Income',
                          )
                          ?.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                        onChange={(e) =>
                          setFieldValue('maintenanceAccountId', e)
                        }
                        errors={errors['maintenanceAccountId']}
                        touched={touched['maintenanceAccountId']}
                      />
                    </div>
                    <div className="d-flex">
                      <AntdSelectField
                        name="servicesAccountId"
                        label="Service Account"
                        value={values.servicesAccountId}
                        options={accounts
                          .filter(
                            (op) => op.generalAccountCategory === 'Income',
                          )
                          ?.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                        onChange={(e) => setFieldValue('servicesAccountId', e)}
                        errors={errors['servicesAccountId']}
                        touched={touched['servicesAccountId']}
                      />

                      <AntdSelectField
                        name="utilitiesAccountId"
                        label="Utility Account"
                        value={values.utilitiesAccountId}
                        options={accounts
                          .filter(
                            (op) => op.generalAccountCategory === 'Income',
                          )
                          ?.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                        onChange={(e) => setFieldValue('utilitiesAccountId', e)}
                        errors={errors['utilitiesAccountId']}
                        touched={touched['utilitiesAccountId']}
                      />

                      <AntdSelectField
                        name="isSpliteable"
                        label="Utility Account"
                        value={values.isSpliteable}
                        options={[
                          { value: true, label: 'Yes' },
                          { value: false, label: 'No' },
                        ]}
                        onChange={(e) => setFieldValue('isSpliteable', e)}
                        errors={errors['isSpliteable']}
                        touched={touched['isSpliteable']}
                      />
                    </div>
                    <br />
                    <div className="col-lg-12">
                      <MyButton
                        icon="ion-plus"
                        loading={loading}
                        label="Save"
                        type="success"
                        onClick={handleSubmit}
                      />
                      <NavLink to="/shop-list" className="btn btn-secondary">
                        Cancel
                      </NavLink>
                    </div>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddShop;
