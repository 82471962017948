import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import Shopkeeper from '../../Services/shopkeeperServices';
import Shop from '../../Services/shopServices';
import Account from '../../Services/accountServices';
import TitleHeader from '../accounts/Components/TitleHeader';
import { message } from 'antd';
import { branchId } from '../../config/EnVariables';
import {
  accountTypes,
  paymentSourceOptions,
  transactionTypeOpions,
} from '../../Utils/options';
import { AntdSelectField, InputField } from '../../Components/InputFormFields';
import { LabelHead } from '../../Components/LableHead';
import { addPaymentSchemaValidation } from '../../config/Validations';
function AddRent(props) {
  const shopKeeperId = useParams().id;
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [shops, setShops] = useState([]);
  const [shopkeepers, setShopkeepers] = useState([]);
  const [accounts, setAccounts] = useState([]);
  let shop = location.state?.shop;
  const navigate = useNavigate();
  const initialValues = {
    shopkeeperId: shop?.shopkeeperId || shop?.secondShopkeeperId || '',
    accountId: '',
    memo: '',
    amount: '',
    shopId: shop?.id || '',
    currencyRate: '',
    paymentSource: '',
    date: '',
  };
  const submitPayment = async (values) => {
    if (values.amount < 0 || values.currencyRate < 0)
      return message.warning('Amount must be a positive number');
    values.destAccountId =
      values.paymentSource == 'RENT'
        ? shop?.revenueAccountId
        : values.paymentSource == 'MAINTENANCE'
        ? shop?.maintenanceAccountId
        : values.paymentSource == 'UTILITIES'
        ? shop?.utilitiesAccountId
        : values.paymentSource === 'SERVICES'
        ? shop?.servicesAccountId
        : values.accountId;
    const data = {
      ...values,
      accountName: accounts?.find((op) => op.id === parseInt(values.accountId))
        ?.name,
      shopkeeperName: shopkeepers?.find(
        (op) => op.id === parseInt(values.shopkeeperId),
      )?.name,
      shopName:
        shop?.name ||
        shops?.find((op) => op.id === parseInt(values.shopId))?.name,
      date: new Date(values.date).toISOString(),
      branchId,
      transactionType: transactionTypeOpions.received_payment,
    };
    const lineItem = [
      // source
      {
        memo: values.memo,
        debit: values.amount,
        credit: null,
        accountId: data.accountId,
      },
      // distination
      {
        memo: values.memo,
        debit: null,
        credit: values.amount,
        accountId: data.destAccountId,
      },
    ];
    for (let i = 0; i < lineItem.length; i++) {
      if (!lineItem[i].accountId)
        return message
          .error(
            'Destination account is not specified for amount: ' + values.amount,
          )
          .then(() => {
            return message.warning(
              'Please add destination account to the selected shop first',
            );
          });
    }

    data.lines = lineItem;

    setLoading(true);
    await Account.addJournelEntry('POST', data)
      .then((response) => {
        message.success('Entry Saved');
        setLoading(false);
        navigate('/genral-journel');
      })
      .catch((error) => {
        setLoading(false);
        message.error('Network Error');
      });
  };

  const getAllShops = async () => {
    await Shop.getAllShops()
      .then((shops) => {
        setShops(shops?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getAllShopkeepers = async () => {
    await Shopkeeper.getAll()
      .then((shops) => {
        setShopkeepers(shops?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getAccounts = async () => {
    await Account.getAccountsByType(accountTypes.bankAndCash)
      .then((response) => {
        setAccounts(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getAllShops();
    getAllShopkeepers();
    getAccounts();
  }, []);

  return (
    <>
      {loading && (
        <div id="global-loader">
          <div className="whirly-loader"> </div>
        </div>
      )}
      <div className="page-wrapper">
        <div className="content">
          <TitleHeader title="Add Payment" />
          {shop && (
            <LabelHead
              label={`Payment for Shop: ${
                ('F#' && shop?.buildingFloorFloorNumber && '-') || ''
              }${shop.shopName || shop?.name}-(${
                shop?.shopkeeperName || shop?.secondShopkeeperName
              })`}
            />
          )}

          <div className="card">
            <div className="card-body">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={submitPayment}
                validationSchema={addPaymentSchemaValidation}
              >
                {({ errors, touched, handleSubmit, setFieldValue, values }) => (
                  <>
                    <div className="row">
                      {!location.state?.shop?.shopkeeperId ? (
                        <AntdSelectField
                          name={'shopkeeperId'}
                          label={'Shopkeeper'}
                          value={values.shopkeeperId}
                          required={true}
                          options={shopkeepers?.map((shopkeeper) => ({
                            value: shopkeeper.id,
                            label: shopkeeper.name,
                          }))}
                          onChange={(e) => setFieldValue('shopkeeperId', e)}
                          errors={errors['shopkeeperId']}
                          touched={touched['shopkeeperId']}
                        />
                      ) : (
                        ''
                      )}
                      {!location.state?.shop?.id ? (
                        <AntdSelectField
                          name={'shopId'}
                          label={'Shop'}
                          required={true}
                          value={values.shopId}
                          options={shops?.map((shop) => ({
                            value: shop.id,
                            label: shop.name,
                          }))}
                          onChange={(e) => {
                            setFieldValue('shopId', e);
                            shop = shops.find((op) => op.id === e);
                          }}
                          errors={errors['shopId']}
                          touched={touched['shopId']}
                        />
                      ) : (
                        ''
                      )}
                      <AntdSelectField
                        name="paymentSource"
                        required={true}
                        label="Payment Source"
                        value={values.paymentSource}
                        options={paymentSourceOptions}
                        onChange={(e) => setFieldValue('paymentSource', e)}
                        errors={errors['paymentSource']}
                        touched={touched['paymentSource']}
                      />

                      <InputField
                        label="Amount"
                        name="amount"
                        type="number"
                        required={true}
                        errors={errors['amount']}
                        touched={touched['amount']}
                      />

                      <AntdSelectField
                        name={'accountId'}
                        label={'Account'}
                        required={true}
                        value={values.accountId}
                        options={accounts.map((acc) => ({
                          value: acc.id,
                          label: acc.name,
                        }))}
                        onChange={(e) => setFieldValue('accountId', e)}
                        errors={errors['accountId']}
                        touched={touched['accountId']}
                      />

                      {accounts.find((op) => op.id === values.accountId)
                        ?.currencyName === 'AFN' ? (
                        <InputField
                          name="currencyRate"
                          label="Currency Rate"
                          type="number"
                          required={true}
                          placeholder="Currency Rate e.g (83.5)"
                          errors={errors['currencyRate']}
                        />
                      ) : null}

                      <InputField
                        name="date"
                        label="Date"
                        type="date"
                        required={true}
                        placeholder="Date"
                        errors={errors['date']}
                        touched={touched['date']}
                      />
                      <InputField
                        name="memo"
                        label="Memo"
                        type="text"
                        required={true}
                        placeholder="Memo"
                        errors={errors['memo']}
                        touched={touched['memo']}
                      />
                    </div>
                    <div className="col-lg-12">
                      <Button
                        className="btn btn-submit me-2"
                        onClick={handleSubmit}
                      >
                        {shopKeeperId ? 'Update' : 'Save'}
                      </Button>
                      <NavLink to="/payments" className="btn btn-secondary">
                        Cancel
                      </NavLink>
                    </div>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddRent;
