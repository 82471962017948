import { NavLink } from 'react-router-dom';

export const CardDashboard = ({ link, title, icon, count, className }) => {
  return (
    <div className="col-lg-3 col-sm-6 col-12 d-flex">
      <NavLink to={link} className="w-100">
        <div class={className}>
          <div className="dash-counts">
            <h4>{count}</h4>
            <h5>{title}</h5>
          </div>
          <div className="dash-imgs">
            <i data-feather={icon}></i>
          </div>
        </div>
      </NavLink>
    </div>
  );
};
