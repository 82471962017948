import { branchId } from '../config/EnVariables';
import callApi from '../helpers/callApi';

const Floor = {
  getAllFloors: () => callApi(`building-floors/?branchId=${branchId}`),

  getOne: (ID) => callApi(`building-floors/${ID}`),

  getAccounts: () => callApi('/transfer/accounts'),

  addFloor: (data, method) =>
    callApi(
      `building-floors/${method === 'POST' ? `?branchId"=${branchId}` : ''}`,
      method,
      data,
    ),

  remove: (id) => callApi(`building-floors/${id}`, 'DELETE'),
};

export default Floor;
