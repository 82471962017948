import React from 'react';
import { Table } from 'antd';

const columns = [
  {
    title: 'Account Name',
    dataIndex: 'accountName',
    key: 'accountName',
  },
  {
    title: 'Dr',
    dataIndex: 'debit',
  },
  {
    title: 'Cr',
    dataIndex: 'credit',
  },
];

const AntdTrialBalance = ({ data, loading }) => {
  function replaceSubAccountArrayWithChildren(data, prefix = '') {
    if (data)
      return data?.map((item, index) => {
        const key = `${prefix}${index + 1}`;
        const newItem = { ...item, key };
        if (newItem.subAccountArray) {
          newItem.children = replaceSubAccountArrayWithChildren(
            newItem.subAccountArray,
            `${key}-`,
          );
          delete newItem.subAccountArray;
        }
        return newItem;
      });
  }

  return (
    <>
      <Table
        className="table-striped"
        loading={loading}
        columns={columns}
        dataSource={replaceSubAccountArrayWithChildren(data)}
        defaultExpandAllRows
        size="small"
      />
    </>
  );
};

export default AntdTrialBalance;
