import axios from 'axios';
import { baseUrl, branchId } from '../config/EnVariables';
import callApi from '../helpers/callApi';
const token = localStorage.getItem('access_token'); // Replace with your actual Bearer Token
const headers = {
  Authorization: `Bearer ${token}`,
  'Content-Type': 'application/json', // Assuming you're sending JSON data
};

const Payment = {
  // getAll: () => callApi(`payments/branch-payments/?branchId=${branchId}`),
  getAll: () =>
    callApi(`general-journals/received-payments/?branchId=${branchId}`),

  getOne: (ID) =>
    axios.post(`/rents/findRentById`, {
      critarion: { _id: `${ID}`, active: 'true' },
    }),

  getAccounts: () => callApi('/transfer/accounts'),

  pay: (data) =>
    axios.post(`${baseUrl}/payments/?branchId=${branchId}`, data, {
      headers: headers,
    }),

  update: (data, ID) =>
    axios.post(`/rents/updateRent`, { shopkeeperid: ID, ...data }),

  remove: (data) => axios.post(`/rents/removeRent`, data),
};

export default Payment;
