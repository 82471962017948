import axios from 'axios';
import { baseUrl, branchId } from '../config/EnVariables';
import callApi from '../helpers/callApi';
const token = localStorage.getItem('access_token');
const headers = {
  Authorization: `Bearer ${token}`,
  'Content-Type': 'application/json',
};
const Account = {
  downloadFile: async (file) =>
    await axios.get(
      `${baseUrl}/download?fileName=${file}&entity=GENERAL%20JOURNAL`,
      {
        headers: headers,
      },
    ),
  getAllAccounts: async () =>
    callApi(`acc-accounts/?branchId=${branchId}&size=100`),

  getOne: async (id) => callApi(`acc-accounts/${id}`),

  getAllBankCashAccounts: async () =>
    callApi(`acc-accounts/bank-and-cash-accounts`),

  getAccountsByType: async (type) =>
    callApi(`acc-accounts/?branchId=${branchId}&accountTypeName=${type}`),

  filterAccount: async (account) =>
    callApi(`acc-accounts/?branchId=${branchId}&name=${account}`),

  getExpandingAccounts: async () =>
    callApi(`acc-accounts/chart-of-accounts/?branchId=${branchId}&size=100`),

  addJournelEntry: (method, data) => callApi(`general-journals`, method, data),

  transferAssets: (data) =>
    axios.post(`${baseUrl}/general-journals/asset-transfer`, data, {
      headers: headers,
    }),

  getAccountTypes: () => callApi(`account-types`),

  getActivatedCurrencies: () => callApi(`currencies/activated`),

  getCurrencyRate: async () =>
    callApi(`currency-rates/latest-branch-rates/?branchId=${branchId}`),

  updateCurrencyRate: async (data) =>
    callApi(`currency-rates/?branchId=${branchId}`, 'POST', data),

  createAccount: async (data) => {
    const response = await axios.post(`${baseUrl}/acc-accounts`, data, {
      headers: headers,
    });
    return response;
  },
  updateAccount: async (data) => {
    const response = await axios.put(`${baseUrl}/acc-accounts`, data, {
      headers: headers,
    });
    return response;
  },
  //Reports
  getAccountQuickReport: (data) =>
    callApi(
      `transaction-lines/account-transaction-report?accountId=${data.accountId}&startDate=${data.startDate}&endDate=${data.endDate}`,
    ),

  getGeneralLedger: (data) =>
    callApi(`transaction-lines/general-ledger?branchId=${branchId}`),

  getJournals: (data) =>
    callApi(`general-journals?branchId=${branchId}&sort=id,desc`),

  getTransactionLines: (journelId) =>
    axios.get(
      `${baseUrl}/transaction-lines/general-journal-lines?generalJournalId=${journelId}`,
      {
        headers: headers,
      },
    ),
  getTrialBalance: (data) =>
    callApi(`transaction-lines/trial-balance/?branchId=${branchId}`),

  getIncomeStatement: (data, branchId) =>
    callApi(
      `transaction-lines/income-statement/?branchId=${branchId}&startDate=${data.startDate}&endDate=${data.endDate}`,
    ),

  getBalanceSheet: (data) =>
    callApi(`transaction-lines/balance-sheet/?branchId=${branchId}`),

  remove: (data) => axios.post('/buildings/removeBuilding', data),
};

export default Account;
