import { browserData } from '../config/EnVariables';
import callApi from '../helpers/callApi';
const branchId = JSON.parse(localStorage.getItem(browserData.globalProject));

const Shop = {
  getAllShops: () =>
    callApi(`building-shops/branch-shops/?branchId=${branchId}`),

  getAllCurrencies: () => callApi(`currencies/activated/?branchId=${branchId}`),

  getFloorShops: (floorId) =>
    callApi(
      `building-shops/floor-shops/?floorId=${floorId}&branchId=${branchId}`,
    ),

  getRents: (shopId) =>
    callApi(
      `general-journals/received-rent-payments-by-shop?shopId=${shopId}&branchId=${branchId}`,
    ),

  getAllShopkeepers: (shopId) =>
    callApi(
      `shop-contracts/building-shop-shopkeepers/?shopId=${shopId}&branchId=${branchId}`,
    ),

  getAvailableShops: (num) =>
    callApi(
      `building-shops/non-occupied-shops/?branchId=${branchId}&${
        num ? 'size=' + num : ''
      }`,
    ),

  getOne: (id) => callApi(`building-shops/${id}`),

  addContract: (data) => callApi(`shop-contracts`, 'POST', data),
  cancellContract: (data) =>
    callApi(`shop-contracts/cancel/${data.id}`, 'PUT', data),

  // Add and Updated both based on method provided
  add: (data, method) => callApi(`building-shops`, method, data),

  importShops: (data) =>
    callApi(
      `building-shops/upload/?branchId=${branchId}`,
      'POST',
      data,
      null,
      'multipart/form-data',
    ),

  remove: (id) => callApi(`building-shops/${id}`, 'DELETE'),
};

export default Shop;
