import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import USDFigure from '../../../Components/USDFigure';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    render: (balance) =>
      balance > 0 ? (
        <p style={{ color: 'green' }}>
          <USDFigure amount={balance} />
        </p>
      ) : (
        <p style={{ color: 'red' }}>
          <USDFigure amount={balance} />
        </p>
      ),
  },
];

const AntdBalanceSheet = ({ data, loading, isAllExpanded }) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  useEffect(() => {
    if (isAllExpanded && data) {
      const keys = data?.map((item, index) => `${index + 1}`);
      setExpandedRowKeys(keys);
    } else {
      setExpandedRowKeys([]);
    }
  }, [isAllExpanded, data]);
  function handleRowExpand(status, expanded) {
    setExpandedRowKeys((prevKeys) =>
      expanded && status
        ? [...prevKeys, expanded.key]
        : prevKeys.filter((k) => k !== expanded.key),
    );
  }
  function replaceSubAccountArrayWithChildren(data, prefix = '') {
    if (data)
      return data?.map((item, index) => {
        const key = `${prefix}${index + 1}`;
        const newItem = { ...item, key };
        if (newItem.accountTypes) {
          newItem.children = replaceSubAccountArrayWithChildren(
            newItem.accountTypes,
            `${key}-`,
          );
          delete newItem.accountTypes;
        }
        return newItem;
      });
  }
  const tableData = replaceSubAccountArrayWithChildren(data);
  return (
    <Table
      className="table-striped"
      loading={loading}
      columns={columns}
      dataSource={tableData}
      expandable={{
        expandedRowKeys,
        expandRowByClick: true,
        onExpand: handleRowExpand,
        defaultExpandAllRows: isAllExpanded,
      }}
      size="small"
    />
  );
};

export default AntdBalanceSheet;
