import React, { useContext } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import Login from '../Pages/Login';
import ShopkeeperFullDetails from '../Pages/shopKeeper/ShopkeeperFullDetails';
import PageNotFound from '../Pages/PageNotFound';
import { AuthContext } from './Context/AuthContext';
function Authentication(props) {
  const { user } = useContext(AuthContext);
  return (
    <Router>
      <Routes>
        {!user ? (
          <Route path="/*" element={<Navigate to="/" />} />
        ) : (
          <Route element={<PageNotFound />} path="*" />
        )}
        <Route Component={Login} path="/" />
        <Route Component={Login} path="/signin" />
        <Route
          element={<ShopkeeperFullDetails />}
          path="/shopkeeper/:shopkeeperId/full-details"
        />
      </Routes>
    </Router>
  );
}

export default Authentication;
