import React, { useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';
import USDFigure from '../../Components/USDFigure';
import { Table as TB } from 'antd';
import Payment from '../../Services/paymentServices';
import ExportComponents from '../../Components/ExportComponents';
function RentList(props) {
  const columns = [
    {
      dataIndex: 'name',
      title: 'Payment',
    },
    {
      dataIndex: 'account',
      title: 'Account',
    },
    {
      dataIndex: 'shopName',
      title: 'Shop',
      sorter: (a, b) => a.shopName - b.shopName,
    },

    {
      dataIndex: 'amount',
      title: 'Amount',
    },
    {
      dataIndex: 'date',
      title: 'Date',
    },
  ];

  const [shopList, setShopList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
    },
  });
  const handleTableChange = (pagination, filter, sorter) => {
    setTableParams({ pagination, filter, ...sorter });
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setShopList([]);
    }
  };
  const getPayments = async () => {
    setLoading(true);
    await Payment.getAll()
      .then((data) => {
        setLoading(false);
        setShopList(data.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getPayments();
  }, []);

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="no-print">
            <NavLink to="/add-payment" className="btn btn-success">
              <i className="ion-plus"></i> Add
            </NavLink>
            <ExportComponents excelData={shopList} fileName="Payments.xlsx" />
          </div>
          <TB
            size="large"
            columns={columns}
            pagination={tableParams.pagination}
            onChange={handleTableChange}
            loading={loading}
            dataSource={shopList?.map((item, index) => ({
              key: item._id,
              name: <p>{item.name}</p>,

              shopName: item.shopName,
              account: item.accountName,
              amount: <USDFigure amount={item.amount} />,

              date: item.date,
            }))}
          />
        </div>
      </div>
    </>
  );
}

export default RentList;
