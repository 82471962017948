import { QRCode } from 'antd';
import React from 'react';
import { branchName, frontBaseUrl } from '../../../config/EnVariables';

function VisitingCard({ activeShop, shopKeeper, shopKeeperId }) {
  return (
    <div
      className="card"
      style={{
        padding: '10px',
        backgroundImage: `url(${require('../../../Assets/Images/card.jpg')})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <img
        src="/assets/img/logo.png"
        alt="logo"
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          opacity: 0.2,
          width: '100px',
          height: 'auto',
        }}
      />
      <h3 className="card-header">{branchName}</h3>
      <div className="d-flex justify-content-between">
        <QRCode
          errorLevel="H"
          size={160}
          iconSize={50}
          value={`${frontBaseUrl}/shopkeeper/${shopKeeperId}/full-details`}
          icon="/assets/img/logo.png"
        />
        <h6 className="text-white">{shopKeeper?.name} </h6>
      </div>
      <div className="card-body">
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div className="text-white">
            <div>Active Shop:&nbsp;&nbsp;&nbsp;{activeShop}</div>
            <div>
              Address:&nbsp;&nbsp;&nbsp;
              {shopKeeper?.address}
            </div>
            <div>Contact:&nbsp;&nbsp;&nbsp;{shopKeeper?.phone}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisitingCard;
