import { message } from 'antd';
import React from 'react';
import * as XLSX from 'xlsx';
function ExportComponents({ excelData, fileName }) {
  const downloadExcel = (data) => {
    if (data.length < 1) return message.error('Data Does not Exists');
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName);
  };

  return (
    <div className="table-top no-print">
      <div className="wordset">
        <ul>
          <li>
            <a
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              onClick={() => window.print()}
            >
              <img src="/assets/img/icons/pdf.svg" alt="img" />
            </a>
          </li>
          <li>
            <a
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              onClick={() => downloadExcel(excelData)}
            >
              <img src="/assets/img/icons/excel.svg" alt="img" />
            </a>
          </li>
          <li>
            <a
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              onClick={() => window.print()}
            >
              <img src="/assets/img/icons/printer.svg" alt="img" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ExportComponents;
