import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import Shopkeeper from '../../Services/shopkeeperServices';
import { AuthContext } from '../../Auth/Context/AuthContext';
import { Modal, message } from 'antd';
import TitleHeader from '../accounts/Components/TitleHeader';
import { branchId, branchName, userRoles } from '../../config/EnVariables';
import User from '../../Services/userServices';
import { InputField } from '../../Components/InputFormFields';
import { addShopkeeperSchemaValidation } from '../../config/Validations';
import { MyButton } from '../../Components/MyButton';
function AddShopKeeper(props) {
  const { user } = useContext(AuthContext);
  const shopKeeperId = useParams().id;
  const [loading, setLoading] = useState(false);
  const [shopkeeper, setShopkeeper] = useState();
  const getShopKeeperEdit = async () => {
    if (shopKeeperId) {
      setLoading(true);
      await Shopkeeper.getOne(shopKeeperId)
        .then((data) => {
          setShopkeeper(data.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };
  const initialValues = {
    name: shopkeeper?.name || '',
    address: shopkeeper?.address || '',
    phone: shopkeeper?.phone || '',
    email: shopkeeper?.email || '',
  };
  const navigate = useNavigate();

  const onSubmit = (values) => {
    Modal.confirm({
      title: 'Please provide username and password for this user!',
      content: (
        <div>
          <label>Username</label>
          <input
            className="form-control"
            name="username"
            type="text"
            // onChange={(e) => setUserName(e.target.value)}
            onChange={(e) => (values.username = e.target.value)}
          />
          <label>Password</label>
          <input
            className="form-control"
            name="password"
            type="password"
            // onChange={(e) => setPassword(e.target.value)}
            onChange={(e) => (values.password = e.target.value)}
          />
        </div>
      ),
      onOk: () => submitForm(values),
    });
  };
  const submitForm = async (values) => {
    setLoading(true);
    const permissions = [];
    permissions.push({ name: 'READ' });
    const userRegisteringData = {
      email: values.email,
      username: values.username,
      password: values.password,
      phone: values.phone,
      role: userRoles.shopkeeper,
      permissions,
      branchId,
      branchName: branchId === 1 ? 'Main Office' : branchName,
      isShopkeeper: true,
    };
    values.branchId = branchId;
    if (shopKeeperId) {
      values.id = shopKeeperId;
      Shopkeeper.update(values)
        .then((data) => {
          message.success('Updated Successfully');
          setLoading(false);
          navigate('/shopkeeper-list');
        })
        .catch((err) => {
          setLoading(false);
          message.error('Failed to Save');
        });
    } else {
      if (!values.name || !values.address || !values.phone) {
        return message.warning('Please fill the form correctly!');
      }

      await Shopkeeper.add(values)
        .then((data) => {
          if (data.data.status === 'Fail') {
            setLoading(false);
            return message.error('Failed to save');
          }
          if (user.role === userRoles.superadmin) {
            userRegisteringData.shopkeeperId = data.data.id;
            userRegisteringData.shopkeeperName = data.data.name;

            User.addUser(userRegisteringData, 'POST')
              .then((data) => {
                message.success('User Saved Successfully');
              })
              .catch((err) => {
                console.log(err);
              });
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (shopKeeperId) getShopKeeperEdit();
  }, []);

  return (
    <>
      {loading && (
        <div id="global-loader">
          <div className="whirly-loader"> </div>
        </div>
      )}
      <div className="page-wrapper">
        <div className="content">
          <TitleHeader title="Add New Shopkeeper" />
          <div className="container card">
            <div className="card-body">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={addShopkeeperSchemaValidation}
              >
                {({ errors, values, touched, handleSubmit, setFieldValue }) => (
                  <>
                    <div className="row">
                      <InputField
                        label="Name"
                        name="name"
                        type="text"
                        required={true}
                        errors={errors['name']}
                        touched={touched['name']}
                      />
                      <InputField
                        label="Phone"
                        name="phone"
                        type="text"
                        required={true}
                        errors={errors['phone']}
                        touched={touched['phone']}
                      />
                      <InputField
                        label="Email"
                        name="email"
                        type="text"
                        errors={errors['email']}
                        touched={touched['email']}
                      />
                      <InputField
                        label="Address"
                        name="address"
                        type="text"
                        required={true}
                        errors={errors['address']}
                        touched={touched['address']}
                      />
                      <div className="col-lg-12">
                        {/* <Button type="primary" onClick={handleSubmit}>
                          {shopKeeperId ? 'Update' : 'Save'}
                        </Button> */}
                        <MyButton
                          label={shopKeeperId ? 'Update' : 'Save'}
                          icon="ion-plus"
                          type="success"
                          loading={loading}
                          onClick={handleSubmit}
                        />
                        <NavLink
                          className="btn btn-secondary"
                          to="/shopkeeper-list"
                        >
                          Cancel
                        </NavLink>
                      </div>
                    </div>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddShopKeeper;
