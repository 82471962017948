import React, { useContext } from 'react';
import { AuthContext } from '../Auth/Context/AuthContext';
import { userRoles } from '../config/EnVariables';
import { Authorize } from '../helpers/Authorize';
import {
  accountReportsMenu,
  accountsMenu,
  appartmentsMenu,
  shopkeepersMenu,
  shopsMenu,
  tenantsMenu,
} from '../config/SideBarItems';
import { NavLink } from 'react-router-dom';
function SideBar() {
  const { currentBranch } = useContext(AuthContext);
  const businessTypeShops =
    currentBranch?.businessType === 'SHOP' ? shopsMenu : appartmentsMenu;
  const businessTypeShopkeepers =
    currentBranch?.businessType === 'SHOP' ? shopkeepersMenu : tenantsMenu;

  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <ul>
            <li className="active">
              <NavLink to="/">
                <img src="/assets/img/icons/dashboard.svg" alt="img" />
                <span>Dashboard</span>
              </NavLink>
            </li>
            <>
              {Authorize(userRoles.finance) ||
              Authorize(userRoles.admin_finance) ||
              Authorize(userRoles.superadmin) ? (
                <>
                  <li className="submenu">
                    <a href="#">
                      <img src="/assets/img/icons/purchase1.svg" alt="img" />
                      <span> Accounts</span>{' '}
                      <span className="menu-arrow"></span>
                    </a>
                    <ul>
                      {accountsMenu.map((op, i) => (
                        <li key={i + 1}>
                          <NavLink to={op.link}>
                            <i class={op.icon}></i> {op.label}
                          </NavLink>
                        </li>
                      ))}

                      {Authorize(userRoles.superadmin) && (
                        <li>
                          <NavLink to="/transfer-assets">
                            <i className="ion-plus"></i>Transfer Assets
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </li>
                  <li className="submenu">
                    <a href="#">
                      <img src="/assets/img/icons/time.svg" alt="img" />
                      <span> Financial Report</span>
                      <span className="menu-arrow"></span>
                    </a>
                    <ul>
                      {accountReportsMenu.map((op, i) => (
                        <li key={i + 1}>
                          <NavLink to={op.link}>
                            <i class={op.icon}></i> {op.label}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </li>
                </>
              ) : null}
              {Authorize(userRoles.admin) ||
              Authorize(userRoles.superadmin) ||
              Authorize(userRoles.admin_finance) ? (
                <>
                  {/* <li className="submenu">
                    <a href="#">
                      <img src="/assets/img/icons/sales1.svg" alt="img" />
                      <span>
                        {currentBranch?.businessType === 'SHOP'
                          ? 'Shops'
                          : 'Appartments'}
                      </span>{' '}
                      <span className="menu-arrow"></span>
                    </a>
                    <ul>
                      {businessTypeShops?.map((op, i) => (
                        <li key={i + 1}>
                          <NavLink to={op.link}>
                            <i class={op.icon}></i> {op.label}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </li> */}
                  {businessTypeShops?.map((op, i) => (
                    <li>
                      <NavLink to={op.link}>
                        <i class={op.icon}></i> <span> {op.label}</span>
                      </NavLink>
                    </li>
                  ))}
                  <li className="submenu">
                    <a href="#">
                      <img src="/assets/img/icons/users1.svg" alt="img" />
                      <span>
                        {currentBranch?.businessType === 'SHOP'
                          ? 'Shopkeepers'
                          : 'Tenants'}
                      </span>{' '}
                      <span className="menu-arrow"></span>
                    </a>
                    <ul>
                      {businessTypeShopkeepers.map((op, i) => (
                        <li key={i + 1}>
                          <NavLink to={op.link}>
                            <i class={op.icon}></i> {op.label}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </li>

                  <li>
                    <NavLink to="/floor-list">
                      <img src="/assets/img/icons/floor.svg" alt="img" />
                      <span> Floors</span>
                    </NavLink>
                  </li>
                </>
              ) : null}

              {Authorize(userRoles.superadmin) && (
                <>
                  {false && (
                    <li>
                      <NavLink to="/invoices">
                        <img src="/assets/img/icons/printer.svg" alt="img" />
                        <span>Invoices</span>
                      </NavLink>
                    </li>
                  )}
                  <li>
                    <NavLink to="/user-list">
                      <img src="/assets/img/icons/users1.svg" alt="img" />
                      <span> Users</span>
                    </NavLink>
                  </li>
                </>
              )}
            </>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
