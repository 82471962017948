import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { message } from 'antd';
import { branchId } from '../../config/EnVariables';
import { AntdSelectField, InputField } from '../../Components/InputFormFields';
import Watermark from '../../Components/Watermark';
import { colors } from '../../config/theme';
import { addPaymentSchemaValidation } from '../../config/Validations';
import {
  accountTypes,
  paymentSourceOptions,
  transactionTypeOpions,
} from '../../Utils/options';
import Shop from '../../Services/shopServices';
import Account from '../../Services/accountServices';
import { MyButton } from '../../Components/MyButton';
import { getTodayDate } from '../../helpers/quick';

function PaymentModal({ shop, getData, shopkeepers }) {
  const [loading, setLoading] = useState(false);
  const [shops, setShops] = useState([]);
  const [accounts, setAccounts] = useState([]);

  const getAllShops = async () => {
    await Shop.getAllShops()
      .then((shops) => {
        setShops(shops?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAccounts = async () => {
    await Account.getAccountsByType(accountTypes.bankAndCash)
      .then((response) => {
        setAccounts(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getAllShops();
    getAccounts();
  }, []);
  const initialValues = {
    shopkeeperId: shop?.shopkeeperId || shop?.secondShopkeeperId || '',
    accountId: '',
    memo: '',
    amount: '',
    shopId: shop?.id || '',
    currencyRate: '',
    paymentSource: '',
    date: getTodayDate(),
  };
  const submitPayment = async (values) => {
    if (values.amount < 0 || values.currencyRate < 0)
      return message.warning('Amount must be a positive number');
    values.destAccountId =
      values.paymentSource == 'RENT'
        ? shop?.revenueAccountId
        : values.paymentSource == 'MAINTENANCE'
        ? shop?.maintenanceAccountId
        : values.paymentSource == 'UTILITIES'
        ? shop?.utilitiesAccountId
        : values.paymentSource === 'SERVICES'
        ? shop?.servicesAccountId
        : values.accountId;
    const data = {
      ...values,
      shopName: shop?.name,
      accountName: accounts?.find((op) => op.id === parseInt(values.accountId))
        ?.name,
      shopkeeperName: shopkeepers?.find(
        (op) => op.id === parseInt(values.shopkeeperId),
      )?.name,
      shopName: shops?.find((op) => op.id === parseInt(values.shopId))?.name,
      date: new Date(values.date).toISOString(),
      branchId,
      transactionType: transactionTypeOpions.received_payment,
    };
    const lineItem = [
      // source
      {
        memo: values.memo,
        debit: values.amount,
        credit: null,
        accountId: data.accountId,
      },
      // distination
      {
        memo: values.memo,
        debit: null,
        credit: values.amount,
        accountId: data.destAccountId,
      },
    ];
    for (let i = 0; i < lineItem.length; i++) {
      if (!lineItem[i].accountId)
        return message
          .error(
            'Destination account is not specified for amount: ' + values.amount,
          )
          .then(() => {
            return message.warning(
              'Please add destination account to the selected shop first',
            );
          });
    }
    data.lines = lineItem;
    setLoading(true);
    await Account.addJournelEntry('POST', data)
      .then((response) => {
        message.success('Entry Saved');
        getData();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error('Network Error');
      });
  };
  return (
    <div
      className="modal fade"
      id="paymentModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="paymentModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header" style={{ background: colors.blue }}>
            <h5 className="modal-title" id="paymentModalLabel">
              Create Payment for Shop: {shop?.name}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Watermark />
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={submitPayment}
              validationSchema={addPaymentSchemaValidation}
            >
              {({ errors, touched, handleSubmit, setFieldValue, values }) => (
                <>
                  <div className="row">
                    {/* <AntdSelectField
                      disabled={true}
                      name={'shopId'}
                      label={'Shop'}
                      required={true}
                      value={values.shopId}
                      options={shops?.map((shop) => ({
                        value: shop.id,
                        label: shop.name,
                      }))}
                      onChange={(e) => {
                        setFieldValue('shopId', e);
                        shop = shops.find((op) => op.id === e);
                      }}
                      errors={errors['shopId']}
                      touched={touched['shopId']}
                    /> */}

                    <AntdSelectField
                      name={'shopkeeperId'}
                      label={'Shopkeeper'}
                      value={values.shopkeeperId}
                      required={true}
                      options={shopkeepers?.map((shopkeeper) => ({
                        value: shopkeeper.shopkeeperId,
                        label: shopkeeper.shopkeeperName,
                      }))}
                      onChange={(e) => setFieldValue('shopkeeperId', e)}
                      errors={errors['shopkeeperId']}
                      touched={touched['shopkeeperId']}
                    />

                    <AntdSelectField
                      name="paymentSource"
                      required={true}
                      label="Payment Source"
                      value={values.paymentSource}
                      options={paymentSourceOptions}
                      onChange={(e) => setFieldValue('paymentSource', e)}
                      errors={errors['paymentSource']}
                      touched={touched['paymentSource']}
                    />

                    <InputField
                      label="Amount"
                      name="amount"
                      type="number"
                      required={true}
                      errors={errors['amount']}
                      touched={touched['amount']}
                    />

                    <AntdSelectField
                      name={'accountId'}
                      label={'Account'}
                      required={true}
                      value={values.accountId}
                      options={accounts.map((acc) => ({
                        value: acc.id,
                        label: acc.name,
                      }))}
                      onChange={(e) => setFieldValue('accountId', e)}
                      errors={errors['accountId']}
                      touched={touched['accountId']}
                    />

                    {accounts.find((op) => op.id === values.accountId)
                      ?.currencyName === 'AFN' ? (
                      <InputField
                        name="currencyRate"
                        label="Currency Rate"
                        type="number"
                        required={true}
                        placeholder="Currency Rate e.g (83.5)"
                        errors={errors['currencyRate']}
                      />
                    ) : null}

                    <InputField
                      name="date"
                      label="Date"
                      type="date"
                      value={values.date}
                      required={true}
                      placeholder="Date"
                      errors={errors['date']}
                      touched={touched['date']}
                    />
                    <InputField
                      name="memo"
                      label="Memo"
                      type="text"
                      required={true}
                      placeholder="Memo"
                      errors={errors['memo']}
                      touched={touched['memo']}
                    />
                  </div>
                  <div className="modal-footer">
                    <MyButton
                      label="Save"
                      icon="ion-plus"
                      type="success"
                      loading={loading}
                      onClick={handleSubmit}
                    />

                    <button
                      type="button"
                      className="btn btn-danger"
                      data-dismiss="modal"
                    >
                      <i className="ion-close"></i> Cancel
                    </button>
                  </div>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentModal;
