import callApi from '../helpers/callApi';
const endPoint = 'branches';

const Branch = {
  getAllBranches: async () => callApi(`branches`),

  getOne: async (id) => callApi(`${endPoint}/${id}`),
  update: async (data) => callApi(endPoint, 'PUT', data),

  createBranch: async (data, method) => callApi(endPoint, method, data),
};

export default Branch;
