import { useEffect, useState } from 'react';
import { AuthContext } from './Auth/Context/AuthContext';
import Application from './Auth/Application';
import Authentication from './Auth/Authentication';
import Floor from './Services/floorServices';
import Log from './Services/logServices';
// import 'primereact/resources/themes/lara-light-indigo/theme.css';
// import 'primereact/resources/primereact.min.css';
import Account from './Services/accountServices';
import { browserData, userRoles } from './config/EnVariables';
import { ShopkeeperView } from './Auth/ShopkeeperView';
import Branch from './Services/branchServices';
function App() {
  const [language, setLanguage] = useState();
  const currentUser = JSON.parse(localStorage.getItem(browserData.user));
  const globalPro = JSON.parse(localStorage.getItem(browserData.globalProject));
  const [globalProject, setGlobalProject] = useState(globalPro);
  const [user, setUser] = useState(currentUser);
  const [floors, setFloors] = useState([]);
  const [logs, setLogs] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [branches, setBranches] = useState([]);
  const [currentBranch, setCurrentBranch] = useState();
  const [controlSideBar, setControlSideBar] = useState(true);
  const [loaded, setLoaded] = useState(false);

  const getfloors = async () => {
    Floor.getAllFloors().then((res) => {
      setFloors(res?.data);
    });
  };

  const getLogs = async () => {
    await Log.getAll().then((data) => {
      setLogs(data?.data);
    });
  };
  const fetchAllAccounts = () => {
    Account.getExpandingAccounts().then((acc) => {
      setAccounts(acc.data);
    });
  };
  const fetchAllbranches = () => {
    Branch.getAllBranches().then((acc) => {
      setBranches(acc?.data);
    });
  };
  const getBranch = () => {
    Branch.getOne(user?.branchId).then((acc) => {
      setCurrentBranch(acc?.data);
    });
  };
  // useEffect(() => {
  //   if (user && user?.branchId) {
  //     getBranch();
  //     getfloors();
  //     fetchAllbranches();
  //     fetchAllAccounts();
  //     if (user.role === userRoles.superadmin) getLogs();
  //   }
  // }, [user]);
  useEffect(() => {
    if (user && user?.branchId) {
      Promise.all([
        getBranch(),
        getfloors(),
        fetchAllbranches(),
        fetchAllAccounts(),
        user.role === userRoles.superadmin ? getLogs() : null,
      ])
        .then(() => {
          setLoaded(true);
        })
        .catch((error) => {
          setLoaded(false);
        });
    }
  }, [user]);
  return (
    <AuthContext.Provider
      value={{
        language,
        setLanguage,
        user,
        setUser,
        globalProject,
        setGlobalProject,
        floors,
        getfloors,
        logs,
        controlSideBar,
        setControlSideBar,
        accounts,
        branches,
        currentBranch,
        loaded,
      }}
    >
      {user ? (
        user.role === userRoles.shopkeeper ? (
          <ShopkeeperView />
        ) : (
          <Application />
        )
      ) : (
        <Authentication />
      )}
    </AuthContext.Provider>
  );
}

export default App;
