import React, { useState } from 'react';
import { Formik } from 'formik';
import { message } from 'antd';
import {
  AntdSelectField,
  InputField,
} from '../../../Components/InputFormFields';
import { addProjectSchemaValidation } from '../../../config/Validations';
import { MyButton } from '../../../Components/MyButton';
import Watermark from '../../../Components/Watermark';
import { colors } from '../../../config/theme';
import { businessTypeOptions } from '../../../Utils/options';
import Branch from '../../../Services/branchServices';

function ProjectModal({ onClose, updatingProject, setUpdatingProject }) {
  const [loading, setLoading] = useState(false);

  const initialValues = {
    name: updatingProject?.name || '',
    businessType: updatingProject?.businessType || 'SHOP',
    description: updatingProject?.description || '',
  };

  const AddBranch = async (formValues, { resetForm }) => {
    let reqMethod = 'POST';
    if (updatingProject?.id) {
      reqMethod = 'PATCH';
      return message.warning('Update feature will be added soon');
    }
    setLoading(true);
    Branch.createBranch(formValues, reqMethod)
      .then((response) => {
        if (response.data) {
          setLoading(false);
          resetForm();
          message.success('Branch Created Successfully');
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
        message.error('Error in Branch Creation');
        setLoading(false);
      });
  };

  return (
    <div
      className="modal fade"
      id="projectModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="projectModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header" style={{ background: colors.blue }}>
            <h5 className="modal-title" id="projectModalLabel">
              {updatingProject?.id
                ? 'Update Project - ' + updatingProject?.name
                : 'Create New Project'}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setUpdatingProject(null);
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Watermark />
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={AddBranch}
              validationSchema={addProjectSchemaValidation}
            >
              {({ errors, touched, values, handleSubmit, setFieldValue }) => (
                <>
                  <InputField
                    size="full"
                    label="Project Name"
                    name="name"
                    type="text"
                    required={true}
                    value={values.name}
                    errors={errors['name']}
                    touched={touched['name']}
                  />
                  <AntdSelectField
                    size="full"
                    label="Business Type"
                    name="businessType"
                    value={values.businessType}
                    options={businessTypeOptions}
                    onChange={(value) => setFieldValue('businessType', value)}
                    required={true}
                    disabled={updatingProject?.businessType ? true : false}
                    errors={errors['businessType']}
                    touched={touched['businessType']}
                  />

                  <InputField
                    size="full"
                    label="Description"
                    name="description"
                    type="text"
                    value={values.description}
                    errors={errors['description']}
                    touched={touched['description']}
                  />

                  <div className="modal-footer">
                    <MyButton
                      label={updatingProject?.id ? 'Update' : 'Save'}
                      icon="ion-plus"
                      type="success"
                      loading={loading}
                      onClick={handleSubmit}
                    />

                    <button
                      type="button"
                      className="btn btn-danger"
                      data-dismiss="modal"
                      onClick={() => {
                        onClose();
                      }}
                    >
                      <i className="ion-close"></i> Cancel
                    </button>
                  </div>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectModal;
