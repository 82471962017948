import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Account from '../../../Services/accountServices';
import USDFigure from '../../../Components/USDFigure';
import ExportComponents from '../../../Components/ExportComponents';
import TitleHeader from '../Components/TitleHeader';
import { getLastTenYearDate, getTodayDate } from '../../../helpers/quick';
import Loader from '../../../Components/Loader';
import { colors } from '../../../config/theme';

function AccountQuickReport(props) {
  const { accountId } = useParams();
  const [reports, setReports] = useState([]);
  const [accountName, setAccountName] = useState();
  const [startDate, setStartDate] = useState(getLastTenYearDate);
  const [endDate, setEndDate] = useState(getTodayDate);
  const [downloadingData, setDownloadingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const getAccountQuickReport = () => {
    setLoading(true);
    const data = {
      accountId,
      startDate: startDate,
      endDate: endDate,
    };
    Account.getAccountQuickReport(data)
      .then((response) => {
        const data = response.data;
        setReports(data);
        const excelData = data.map((item) => ({
          transaction: item.name,
          date: item.date,
          memo: item.memo,
          debit: item.debit,
          credit: item.credit,
        }));
        setDownloadingData(excelData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const fetchAccounts = async () => {
    await Account.getAllAccounts().then((acc) => {
      const name = acc.data?.find((op) => op.id === parseInt(accountId))?.name;
      setAccountName(name);
    });
  };
  useEffect(() => {
    fetchAccounts();
    getAccountQuickReport();
  }, []);

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <TitleHeader title="Account Quick Report" />

        <div className="row">
          <div className="col-sm-12">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex' }}>
                <div>
                  Start Date
                  <input
                    className="form-control "
                    type="date"
                    value={startDate}
                    name="start_date"
                    onChange={(val) => setStartDate(val.target.value)}
                  />
                </div>
                <div>
                  End Date
                  <input
                    className="form-control "
                    type="date"
                    value={endDate}
                    name="end_date"
                    onChange={(val) => setEndDate(val.target.value)}
                  />
                </div>
                <div className="no-print">
                  <br />
                  <a
                    href="#"
                    className="btn btn-dark"
                    onClick={getAccountQuickReport}
                  >
                    <i className="ion-ios7-search-strong"></i>Search
                  </a>
                </div>
              </div>
              <p>
                Acc: <span className="badge badge-info">{accountName}</span>
              </p>
              <ExportComponents
                excelData={downloadingData}
                fileName={endDate + 'QuickReport.xlsx'}
              />
            </div>
            <div className="card">
              <div className="card-body">
                <Loader loading={loading} />

                <div className="table-responsive">
                  <table className="table">
                    <thead style={{ background: colors.primary }}>
                      <tr>
                        <th>Transaction</th>
                        <th>Date</th>
                        <th>Memo</th>
                        <th>Debit</th>
                        <th>Credit</th>
                      </tr>
                    </thead>

                    <tbody>
                      {reports?.map((item, i) => {
                        return (
                          <tr key={i + 1}>
                            <td>{item.name}</td>
                            <td>{item.date}</td>
                            <td>{item.memo}</td>
                            <td>
                              <USDFigure amount={item.debit} />
                            </td>
                            <td>
                              <USDFigure amount={item.credit} />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td></td>
                      <td></td>
                      <td>
                        <strong>
                          <USDFigure
                            amount={reports.reduce(
                              (accumulator, currentObject) => {
                                return accumulator + currentObject.debit;
                              },
                              0,
                            )}
                          />
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <USDFigure
                            amount={reports.reduce(
                              (accumulator, currentObject) => {
                                return accumulator + currentObject.credit;
                              },
                              0,
                            )}
                          />
                        </strong>
                      </td>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountQuickReport;
