import axios from 'axios';
import { baseUrl, branchId } from '../config/EnVariables';
import callApi from '../helpers/callApi';

const User = {
  login: (ID, Password) =>
    new Promise((resolve, reject) => {
      axios
        .post('users/signin', {
          email: ID,
          password: Password,
        })
        .then((data) => {
          data.data && localStorage.setItem('user', JSON.stringify(data.data));
          data.token && localStorage.setItem('access_token', data.token);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    }),

  signin: async (ID, Password) => {
    const response = await axios.post(
      `${baseUrl}/signin`,
      {
        username: ID,
        password: Password,
      },
      {
        headers: {
          'Content-type': 'application/json',
        },
      },
    );
    return response;
  },

  getLoggedUser: async (token) => {
    const response = await axios.get(`${baseUrl}/users/current-logged-user`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json;charset=UTF-8',
      },
    });
    return response.data;
  },
  getReports: () => callApi(`reporting/dashboard/?branchId=${branchId}`),
  // axios.get(`${baseUrl}/reporting/dashboard/?branchId=${branchId}`, {
  //   headers,
  // }),

  getGlobalReports: () =>
    callApi(`transaction-lines/balance-sheet/all-branches`),
  // axios.get(`${baseUrl}/transaction-lines/balance-sheet/all-branches`, {
  //   headers,
  // }),

  getBranches: () => callApi(`branches`),
  // axios.get(`${baseUrl}/branches`, { headers }),

  getOne: (id) => callApi(`users/${id}`),
  // axios.get(`${baseUrl}/users/${id}`, { headers: headers }),

  getAll: () => callApi(`users`),
  // axios.get(`${baseUrl}/users`, { headers: headers }),

  remove: (id) => callApi(`users/${id}`, 'DELETE'),
  //  axios.delete(`${baseUrl}/users/${id}`, { headers: headers }),

  // addUser: (data) => callApi(`users`, 'POST', data),
  addUser: (data, method) => callApi(`users`, method, data),

  // axios.post(`${baseUrl}/users`, data, { headers: headers }),

  update: (data) => callApi(`users`, 'PUT', data),
  // axios.put(`${baseUrl}/users`, data, { headers: headers }),
};

export default User;
