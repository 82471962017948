export const Edit = ({ onClick }) => {
  return (
    <button
      style={{
        backgroundColor: 'transparent',
        border: '0px',
      }}
      onClick={onClick}
    >
      <img src="assets/img/icons/edit.svg"></img>
    </button>
  );
};
export const Delete = ({ onClick }) => {
  return (
    <button
      style={{
        backgroundColor: 'transparent',
        border: '0px',
      }}
      onClick={onClick}
    >
      <img src="assets/img/icons/delete.svg"></img>
    </button>
  );
};
