import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import Shopkeeper from '../../Services/shopkeeperServices';
import Shop from '../../Services/shopServices';
import { contractTypeOptions } from '../../Utils/options';
import { AntdSelectField, InputField } from '../../Components/InputFormFields';
import { message } from 'antd';
import TitleHeader from '../accounts/Components/TitleHeader';
import { getContractEndDate, getTodayDate } from '../../helpers/quick';
import { branchId } from '../../config/EnVariables';
import { MyButton } from '../../Components/MyButton';
import { addContractSchemaValidation } from '../../config/Validations';
function AddContract(props) {
  const contractId = useParams().id;
  const location = useLocation();
  const [shop, setShop] = useState(location.state?.shop);
  const [loading, setLoading] = useState(false);
  const [shops, setShops] = useState([]);
  const [shopkeepers, setShopkeepers] = useState([]);

  const navigate = useNavigate();
  const initialValues = {
    shopkeeperId: shop?.shopkeeperId || '',
    shopId: shop?.id || '',
    contractType: '',
    note: '',
    feePerSquareMeter: 0,
    date: getTodayDate(),
    startDate: '',
    endDate: '',
  };

  const addContract = (values) => {
    setLoading(true);
    const data = {
      shopkeeperId: values.shopkeeperId,
      shopId: shop?.id || values.shopId,
      contractType: values.contractType,
      note: values?.note,
      contractDate: new Date(values.date).toISOString(),
      startDate: new Date(values.startDate).toISOString(),
      endDate:
        (values.endDate && new Date(values.endDate).toISOString()) || null,
      branchId: branchId,
      feePerSquareMeter: values.feePerSquareMeter,
    };
    Shop.addContract(data)
      .then((data) => {
        message.success('Contract Made Successfully');
        setLoading(false);
        navigate('/contract-list');
      })
      .catch((err) => {
        message.error('Contract Not Made');
        setLoading(false);
        console.log(err);
      });
  };

  const getAllShops = async () => {
    await Shop.getAvailableShops(99999999).then((shops) => {
      setShops(shops.data);
    });
  };

  const getAllShopkeepers = async () => {
    await Shopkeeper.getAll(99999999).then((shops) => {
      const available = shops.data?.filter((op) => !op.activeShopsList);
      const updatedData = available?.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setShopkeepers(updatedData);
    });
  };
  useEffect(() => {
    getAllShops();
    getAllShopkeepers();
  }, []);
  return (
    <>
      {loading && (
        <div id="global-loader">
          <div className="whirly-loader"> </div>
        </div>
      )}
      {/* <div className=" "> */}
      <div className="page-wrapper">
        <div className="content">
          <TitleHeader title="Create New Contract" />
          {shop && (
            <h3>{`Contract for Shop: F${shop.buildingFloorFloorNumber}-${
              shop.name
            }-${shop.id || ''}`}</h3>
          )}
          <div className="card">
            <div className="card-body">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={addContract}
                validationSchema={addContractSchemaValidation}
              >
                {({ errors, touched, values, setFieldValue, handleSubmit }) => (
                  <>
                    <div className="row">
                      <AntdSelectField
                        label="Shopkeeper"
                        name="shopkeeperId"
                        options={shopkeepers}
                        onChange={(value) =>
                          setFieldValue('shopkeeperId', value)
                        }
                        required={true}
                        errors={errors['shopkeeperId']}
                        touched={touched['shopkeeperId']}
                      />
                      {!shop && (
                        <AntdSelectField
                          label="Shop"
                          name="shopId"
                          options={shops.map((op) => ({
                            value: op.id,
                            label: `${op.name}-F#${op.buildingFloorFloorNumber}`,
                          }))}
                          onChange={(value) => setFieldValue('shopId', value)}
                          required={true}
                          errors={errors['shopId']}
                          touched={touched['shopId']}
                        />
                      )}

                      <AntdSelectField
                        label="Contract Type"
                        name="contractType"
                        options={contractTypeOptions}
                        onChange={(value) => {
                          setFieldValue('contractType', value);
                          setFieldValue('endDate', getContractEndDate(value));
                        }}
                        required={true}
                        errors={errors['contractType']}
                        touched={touched['contractType']}
                      />

                      <InputField
                        name="date"
                        label="Date"
                        type="date"
                        value={values.date}
                        required={true}
                        errors={errors['date']}
                        touched={touched['date']}
                      />

                      <InputField
                        name="startDate"
                        label="Contract Start Date"
                        type="date"
                        value={values.startDate}
                        onChange={(e) => {
                          setFieldValue('startDate', e.target.value);
                          setFieldValue(
                            'endDate',
                            getContractEndDate(
                              values.contractType,
                              e.target.value,
                            ),
                          );
                        }}
                        required={true}
                        errors={errors['startDate']}
                        touched={touched['startDate']}
                      />
                      {values.contractType != contractTypeOptions[4].value && ( //Permanent
                        <InputField
                          name="endDate"
                          label="Contract End Date"
                          type="date"
                          value={values.endDate}
                          required={true}
                          errors={errors['endDate']}
                          touched={touched['endDate']}
                        />
                      )}
                      <InputField
                        name="feePerSquareMeter"
                        label="Fee per Square Meter"
                        type="number"
                        errors={errors['feePerSquareMeter']}
                        touched={touched['feePerSquareMeter']}
                      />

                      <InputField
                        name="note"
                        label="Note"
                        type="text"
                        placeholder="Note"
                        errors={errors['note']}
                        touched={touched['note']}
                      />
                    </div>

                    <br />
                    <div className="col-lg-12">
                      <MyButton
                        icon="ion-plus"
                        loading={loading}
                        label={contractId ? 'Update' : 'Save'}
                        type="success"
                        onClick={handleSubmit}
                      />
                      <NavLink to="/shop-list" className="btn btn-secondary">
                        Cancel
                      </NavLink>
                    </div>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddContract;
