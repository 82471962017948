import React from 'react';
import PrintModal from '../../../Components/PrintModal';
import { QRCode } from 'antd';
import { branchName, frontBaseUrl } from '../../../config/EnVariables';
import AreaUnit from '../../../Components/AreaUnit';
function CardPrint({ shop, shopId }) {
  return (
    <PrintModal
      content={
        <div
          className="card"
          style={{
            padding: '10px',
            backgroundImage: `url(${require('../../../Assets/Images/card.jpg')})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <img
            src="/assets/img/logo.png"
            alt="logo"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              opacity: 0.2,
              width: '100px',
              height: 'auto',
            }}
          />
          <h3 className="card-header">{branchName}</h3>
          <div className="d-flex justify-content-between">
            <QRCode
              errorLevel="H"
              size={160}
              iconSize={50}
              value={`${frontBaseUrl}/shop/${shopId}/full-details`}
              icon="/assets/img/logo.png"
            />
            <p style={{ fontSize: 25 }}>{shop.name} </p>
            <p style={{ fontSize: 25, color: '#fff' }}>
              {shop.shopkeeperName}{' '}
            </p>
          </div>
          <div className="card-body">
            <p style={{ fontSize: 22 }}>
              {shop?.isSold ? 'Sold Shop' : 'Rental Shop'}
            </p>

            <div className="d-flex justify-content-between">
              <div>
                <ul style={{ fontSize: 22 }}>
                  <li>
                    Revenue Account: <span>{shop?.revenueAccountName}</span>
                  </li>
                  <li>
                    Maintenance Account:{' '}
                    <span>{shop?.maintenanceAccountName}</span>
                  </li>
                  <li>
                    Services Account: <span>{shop?.servicesAccountName}</span>{' '}
                  </li>
                  <li>
                    Utilities Account: <span>{shop?.utilitiesAccountName}</span>{' '}
                  </li>
                </ul>
              </div>
              <div>
                <ul className="text-white" style={{ fontSize: 22 }}>
                  <li className="d-flex">
                    Area: <AreaUnit area={shop?.area} />
                  </li>
                  <li>
                    Monthly Rent:
                    {shop?.monthlyRent}
                  </li>
                  <li>
                    Occupied:
                    {shop?.isOccupied ? 'Yes' : 'No'}
                  </li>
                  <li>
                    Is Spliteable:
                    {shop?.isSpliteable ? 'Yes' : 'No'}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default CardPrint;
