// export const baseUrl = "http://localhost:8081/api";
// export const frontBaseUrl = 'http://localhost:3000';

export const baseUrl = 'https://api.sunsky-mis.com/api';
export const frontBaseUrl = 'https://sunsky-mis.com';

export const userRoles = {
  admin: 'admin',
  admin_finance: 'admin_finance',
  finance: 'finance',
  superadmin: 'superadmin',
  shopkeeper: 'shopkeeper',
  user_manager: 'user_manager',
};
export const userPermissions = {
  create: 'CREATE',
  read: 'READ',
  update: 'UPDATE',
  delete: 'DELETE',
};
export const browserData = {
  user: 'user',
  access_token: 'access_token',
  globalProject: 'globalProject',
  globalProjectName: 'globalProjectName',
};

const globalProject = JSON.parse(
  localStorage.getItem(browserData.globalProject),
);
export const branchId = globalProject;
export const branchName = localStorage.getItem(browserData.globalProjectName);
