import React, { useContext, useEffect, useState } from 'react';
import User from '../../Services/userServices';
import { AuthContext } from './../../Auth/Context/AuthContext';
import { Table, message } from 'antd';
import { userRoles } from '../../config/EnVariables';
import UserModal from './Components/UserModal';
import DeleteConfirmation from '../../Components/DeleteConfirmation';
function UserList(props) {
  const { user } = useContext(AuthContext);

  const columns = [
    {
      dataIndex: 'profile_picture_url',
      title: 'Profile',
      formatter: (cell, row) => (
        <img
          style={{ maxWidth: '30%' }}
          src={row.profile_picture_url || 'images/profile.png'}
          alt="img"
        />
      ),
    },
    {
      dataIndex: 'username',
      title: 'User Name',
    },
    {
      dataIndex: 'email',
      title: 'Email',
    },
    {
      dataIndex: 'phone',
      title: 'Phone',
    },
    {
      dataIndex: 'role',
      title: 'Role',
    },
    {
      dataIndex: 'branch',
      title: 'Project',
    },
    {
      dataIndex: 'action',
      title: 'Action',
    },
  ];
  const [userList, setUserList] = useState([]);
  const [updatingUser, setUpdatingUser] = useState();
  const [loading, setLoading] = useState(false);

  const getUserList = async () => {
    setLoading(true);
    await User.getAll()
      .then((data) => {
        const filterUsers = data.data.filter(
          (op) => op.role !== userRoles.superadmin,
        );
        setUserList(filterUsers);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const deleteUser = async (id) => {
    if (user.id === id) {
      message.error("Current user can't be deleted");
      return;
    }
    setLoading(true);
    await User.remove(id)
      .then((response) => {
        message.success('Deleted Successfully');
        getUserList();
      })
      .catch((err) => {
        if (err.response?.data) {
          setLoading(false);
          message.error(err.response.data.message);
        }
        console.log(err);
      });
  };

  useEffect(() => {
    getUserList();
  }, []);

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-success"
              data-target="#printModal"
              data-toggle="modal"
              data-whatever="@mdo"
            >
              <i className="ion-plus"></i> Add
            </button>
          </div>
          <UserModal
            updatingUser={updatingUser}
            onClose={() => setUpdatingUser(null)}
            setUpdatingUser={setUpdatingUser}
            getUsers={getUserList}
          />
          <Table
            size="small"
            loading={loading}
            className="table-striped"
            columns={columns}
            scroll={{ x: 1000 }}
            dataSource={userList?.map((item) => ({
              profile_picture_url: (
                <img
                  src={item.profile_picture_url || 'images/profile.png'}
                  width={25}
                  height={25}
                  alt="img"
                  style={{ borderRadius: '50%' }}
                />
              ),
              username: item.username,
              email: item.email,
              phone: item.phone,
              role: item.role,
              branch: (
                <span className="badge badge-pill badge-info">
                  {item.branchName || item.branchId}
                </span>
              ),
              action: (
                <div>
                  <button
                    style={{
                      backgroundColor: 'transparent',
                      border: '0px',
                    }}
                    data-target="#printModal"
                    data-toggle="modal"
                    data-whatever="@mdo"
                    onClick={() => {
                      setUpdatingUser(item);
                    }}
                  >
                    <img src="assets/img/icons/edit.svg" alt="img" />
                  </button>
                  <DeleteConfirmation
                    onConfirm={() => deleteUser(item.id)}
                    itemName={item.username}
                  />
                </div>
              ),
            }))}
          />
        </div>
      </div>
    </>
  );
}

export default UserList;
