import React, { useEffect, useState } from 'react';
import Account from '../../../Services/accountServices';
import { message } from 'antd';
import USDFigure from '../../../Components/USDFigure';
import TitleHeader from '../Components/TitleHeader';
import ExportComponents from '../../../Components/ExportComponents';
import { getTodayDate } from '../../../helpers/quick';
import AntdBalanceSheet from './AntdBalanceSheet';
function BalanceSheet({ data }) {
  const [balanceSheet, setBalanceSheet] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchBalanceSheet = async () => {
    setLoading(true);
    await Account.getBalanceSheet()
      .then((acc) => {
        let items = acc.data?.map((p, i) => ({
          name: p.name,
          balance: p.balance,
          id: i + 1,
          accountTypes: p.accountTypes,
        }));
        setBalanceSheet(items);
        setLoading(false);
      })
      .catch((err) => {
        message.error('Error Generating Balance Sheet');
        setLoading(false);
      });
  };
  useEffect(() => {
    if (!data) fetchBalanceSheet();
  }, []);
  const [expandedAccounts, setExpandedAccounts] = useState([]);
  const [isAllExpanded, setIsAllExpanded] = useState(true);
  const toggleAllRows = () => {
    if (isAllExpanded) {
      setExpandedAccounts([]);
    } else {
      const allAccountIds = balanceSheet.map((acc) => acc.id);
      setExpandedAccounts(allAccountIds);
    }
    setIsAllExpanded(!isAllExpanded);
  };
  const toggleAccount = (accountId) => {
    if (expandedAccounts.includes(accountId)) {
      setExpandedAccounts(expandedAccounts.filter((id) => id !== accountId));
    } else {
      setExpandedAccounts([...expandedAccounts, accountId]);
    }
  };
  const renderAccounts = (accounts, level = 0) => {
    return accounts.map((acc) => (
      <>
        <tr>
          <td style={{ paddingLeft: level * 20 }}>
            <a key={acc.id} onClick={() => toggleAccount(acc.id)}>
              {acc.accountTypes?.length > 0 && (
                <i
                  class={
                    expandedAccounts.includes(acc.id)
                      ? 'ion-arrow-down-b'
                      : 'ion-arrow-right-b'
                  }
                ></i>
              )}
            </a>
            &nbsp;&nbsp;
            {acc.name}
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          {acc.balance > 0 ? (
            <p style={{ color: 'green' }}>
              <USDFigure amount={acc.balance} />
            </p>
          ) : (
            <p style={{ color: 'red' }}>
              <USDFigure amount={acc.balance} />
            </p>
          )}
        </tr>
        {expandedAccounts.includes(acc.id) &&
          acc.accountTypes?.map((sub) => (
            <>
              <tr key={sub.id}>
                <td style={{ paddingLeft: (level + 1) * 20 }}>
                  {sub.accountTypes?.length > 0 && (
                    <i
                      class={
                        expandedAccounts.includes(sub.id)
                          ? 'ion-arrow-down-b'
                          : 'ion-arrow-right-b'
                      }
                    ></i>
                  )}
                  &nbsp;&nbsp;
                  {sub.name}
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                {sub.balance > 0 ? (
                  <p style={{ color: 'green' }}>
                    <USDFigure amount={sub.balance} />
                  </p>
                ) : (
                  <p style={{ color: 'red' }}>
                    <USDFigure amount={sub.balance} />
                  </p>
                )}
              </tr>
              {sub.accountTypes && renderAccounts(sub.accountTypes, level + 2)}
            </>
          ))}
      </>
    ));
  };
  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <TitleHeader title="Balance Sheet" />
        <div className="d-flex align-items-center justify-content-between">
          <ExportComponents
            excelData={balanceSheet}
            fileName={getTodayDate() + 'balanceSheet.xlsx'}
          />
          <button
            onClick={() => toggleAllRows()}
            className="btn btn-dark no-print"
          >
            {isAllExpanded ? 'Collapse All' : 'Expand All'}
          </button>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <AntdBalanceSheet
              data={balanceSheet}
              loading={loading}
              isAllExpanded={isAllExpanded}
            />

            {/* <div className="table-responsive">
              <table className="w-100 table-striped">
                <thead className="table-bg table-head">
                  <tr>
                    <th>Account</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Balance</th>
                  </tr>
                </thead>
                <Loader loading={loading} />
                <tbody>{renderAccounts(data || balanceSheet)}</tbody>
              </table>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BalanceSheet;
