// import SETTINGS_OVERRIDE from './copies/mcom'; //Muhammad Communication
// import SETTINGS_OVERRIDE from './copies/bcom'; //Barum Communication
// import SETTINGS_OVERRIDE from './copies/dtcom'; //Duad Telecom
let BASIC_SETTINGS = {
  TITLE: 'Sun Sky',
  fullTitle: 'Sun Sky (Khairkhwa Electronic Complex)',
  developer: {
    name: 'Suhaib Qanooni',
    email: 'qanoonisuhaib@aol.com',
    website: 'https://suhaib-qanooni.vercel.app/',
    company: 'https://black-box-softwares.netlify.app/',
  },
  currencies: {
    afn: 'AFN',
    usd: '$',
  },
  rowsPerPageList: [10, 30, 50, 100, 500],
  defaultPerPage: 100,
  BackendURL: 'http://localhost:8080/',
  InternetCheckInterval: 5 * 60 * 1000,

  LogoImage: '/static/logo.png',
  FaviconImage: '/favicon/logo.png',
  UserLoginImage: '/static/illustrations/user.png',
  ForgetPageImage: '/static/illustrations/forgot.png',

  Image404: '/static/illustrations/404.png',
  Image401: '/static/illustrations/401.webp',
  DefaultAvatar: '/static/mock-images/avatars/avatar_default.png',

  listOfAvatars: [
    'avatar_default.png',
    'avatar_1.jpg',
    'avatar_19.jpg',
    'avatar_20.jpg',
    'avatar_21.jpg',
    'avatar_22.jpg',
    'avatar_23.jpg',
    'avatar_24.jpg',
  ],

  DefaultUploadedFileImage: '/static/icons/file.png',
  NotificationSound: '/static/sounds/notification.mp3',

  NotificationReload: 1000 * 30,

  allowedExtensions: [
    'png',
    'jpg',
    'jpeg',
    'mp3',
    'pdf',
    'zip',
    'rar',
    'docx',
    'doc',
    'ppt',
    'pptx',
    'txt',
  ],

  acceptImageUpload: 'image/png, image/jpg, image/jpeg',
  acceptFileUpload:
    'image/png, image/jpg, image/jpeg, audio/mp3, application/pdf, application/zip, application/doc, application/docx, application/ppt, application/pptx, application/txt',
  maxUploadFileSize: 10 * 1024 * 1024, //10 Mbs
  hideFileAlertIn: 3000,

  RANKS: {
    rso: 1,
    dso: 2,
    employee: 3,
    manager: 4,
    owner: 5,
    admin: 6,
  },

  RANKS_NAMES: [
    'Member',
    'RSO',
    'DSO',
    'Employee',
    'Manager',
    'Owner',
    'Admin',
  ],

  //PALETTE

  PRIMARY: {
    lighter: '#ff6cb7',
    light: '#ff309a',
    lightest: '#ff8ac6',
    main: '#E40076',
    dark: '#d5006c',
    darker: '#b7005c',
    contrastText: '#fff',
  },

  SECONDARY: {
    lighter: '#e3f1d1',
    light: '#badd8b',
    main: '#99CC54',
    dark: '#7db135',
    darker: '#648e2a',
    contrastText: '#fff',
  },
};

BASIC_SETTINGS = {
  ...BASIC_SETTINGS,
  // ...SETTINGS_OVERRIDE, //Comment this in local testing
};

BASIC_SETTINGS.BackendURLAPI = BASIC_SETTINGS.BackendURL;
BASIC_SETTINGS.ResetPageImage = BASIC_SETTINGS.ForgetPageImage;
BASIC_SETTINGS.DataReload = BASIC_SETTINGS.NotificationReload;

export const SETTINGS = BASIC_SETTINGS;
