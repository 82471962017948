import React, { useEffect, useState } from 'react';

import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { FormLabel } from 'react-bootstrap';
import { Formik } from 'formik';
import User from '../../Services/userServices';
import Branch from '../../Services/branchServices';
import { Select, Space, message } from 'antd';
import { userRoles } from '../../config/EnVariables';
import TitleHeader from '../accounts/Components/TitleHeader';
import {
  AntdSelectField,
  CheckBox,
  InputField,
} from '../../Components/InputFormFields';
import Shopkeeper from '../../Services/shopkeeperServices';
import { addUserSchemaValidation } from '../../config/Validations';
import { MyButton } from '../../Components/MyButton';
import Loader from '../../Components/Loader';

const options = [
  {
    label: 'CREATE',
    value: 'CREATE',
    emoji: <i className="ion-plus" />,
    desc: 'CREATE',
  },
  {
    label: 'READ',
    value: 'READ',
    emoji: <i className="fa fa-book" />,
    desc: 'READ',
  },
  {
    label: 'UPDATE',
    value: 'UPDATE',
    emoji: <i className="ion-edit" />,
    desc: 'UPDATE',
  },
  {
    label: 'DELETE',
    value: 'DELETE',
    emoji: <i className="ion-minus" />,
    desc: 'DELETE',
  },
];
function AddUser(props) {
  const userId = useParams().id;

  const navigate = useNavigate();
  const [updatingUser, setUpdatingUser] = useState();
  const [loading, setLoading] = useState(false);
  const [isShopkeeper, setIsShopkeeper] = useState(
    updatingUser?.shopkeeperId ? true : false,
  );
  const [branches, setBranches] = useState([]);
  const [shopkeepers, setShopkeepers] = useState([]);
  const [permissions, setPermissions] = useState(['READ']);
  const getAllShopkeepers = async () => {
    await Shopkeeper.getAll().then((shops) => {
      const updatedData = shops.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setShopkeepers(updatedData);
    });
  };
  useEffect(() => {
    getAllShopkeepers();
  }, []);
  const getUserEdit = async () => {
    if (userId) {
      setLoading(true);
      await User.getOne(userId)
        .then((data) => {
          setLoading(false);
          setUpdatingUser(data.data);
          const perms = data.data?.permissions?.map((op) => op.name);
          setPermissions(perms);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };
  const getAllBranches = () => {
    Branch.getAllBranches()
      .then((b) => {
        setBranches(b.data);
      })
      .catch((err) => console.log('Error', err));
  };
  useEffect(() => {
    getAllBranches();
  }, []);
  const initialValues = {
    branchId: updatingUser?.branchId || '',
    shopkeeperId: updatingUser?.shopkeeperId || '',
    email: updatingUser?.email || '',
    username: updatingUser?.username || '',
    phone: updatingUser?.phone || '',
    password: '',
    address: updatingUser?.address || '',
    role: updatingUser?.role || '',
  };
  function removeEmptyAttributes(obj) {
    const filteredAttributes = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
          filteredAttributes[key] = obj[key];
        }
      }
    }
    return filteredAttributes;
  }
  const registerUser = (values) => {
    values.permissions = permissions?.map((p) => ({
      name: p,
    }));
    values.branchName = branches.find(
      (op) => op.id === parseInt(values.branchId),
    )?.name;
    if (isShopkeeper) {
      values.role = userRoles.shopkeeper;
      values.permissions = [{ name: 'READ' }];
    }
    values = removeEmptyAttributes(values);
    if (userId) {
      values.id = userId;
      User.update(values)
        .then((data) => {
          message.success('Saved Successfully');
          navigate('/user-list');
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (!values.password) return message.error('Password is Required');
      User.addUser(values)
        .then((data) => {
          message.success('Saved Successfully');
          navigate('/user-list');
          console.log(data);
        })
        .catch((err) => {
          message.error(err.response?.data?.message);
          console.log(err.response);
        });
    }
  };

  useEffect(() => {
    getUserEdit();
  }, []);

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <TitleHeader title="User Management" />
          <div className="container card">
            <div className="card-body">
              <Loader loading={loading} />
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={registerUser}
                validationSchema={addUserSchemaValidation}
              >
                {({ errors, touched, values, handleSubmit, setFieldValue }) => (
                  <>
                    <div className="row">
                      <AntdSelectField
                        label="Project"
                        name="branchId"
                        value={values.branchId}
                        options={branches?.map((op) => ({
                          value: op.id,
                          label: op.name,
                        }))}
                        onChange={(value) => setFieldValue('branchId', value)}
                        required={true}
                        errors={errors['branchId']}
                        touched={touched['branchId']}
                      />

                      <InputField
                        label="Username"
                        name="username"
                        type="text"
                        required={true}
                        value={values.username}
                        disabled={userId && true}
                        errors={errors['username']}
                        touched={touched['username']}
                      />

                      <InputField
                        label="Email"
                        name="email"
                        type="email"
                        value={values.email}
                        errors={errors['email']}
                        touched={touched['email']}
                      />

                      <InputField
                        label="Phone"
                        name="phone"
                        type="text"
                        required={true}
                        value={values.phone}
                        errors={errors['phone']}
                        touched={touched['phone']}
                      />
                      {!userId && (
                        <InputField
                          label="Password"
                          name="password"
                          type="password"
                          value={values.password}
                          required={true}
                          errors={errors['password']}
                          touched={touched['password']}
                        />
                      )}
                      <CheckBox
                        label="Is Shopkeeper?"
                        value={isShopkeeper}
                        checked={isShopkeeper}
                        onChange={(e) => {
                          setIsShopkeeper(e.target.checked);
                          setFieldValue('shopkeeperId', null);
                        }}
                      />
                      {isShopkeeper && (
                        <AntdSelectField
                          label="Shopkeeper"
                          name="shopkeeperId"
                          value={values.shopkeeperId}
                          options={shopkeepers}
                          onChange={(value) =>
                            setFieldValue('shopkeeperId', value)
                          }
                          required={true}
                        />
                      )}

                      {!isShopkeeper && (
                        <>
                          <AntdSelectField
                            label="Role"
                            name="role"
                            value={values.role}
                            options={Object.keys(userRoles)
                              .filter(
                                (op) =>
                                  op !== userRoles.superadmin &&
                                  op !== userRoles.shopkeeper &&
                                  op !== userRoles.user_manager,
                              )
                              .map((op) => ({
                                value: op,
                                label: op,
                              }))}
                            onChange={(value) => setFieldValue('role', value)}
                            required={true}
                            errors={errors['role']}
                            touched={touched['role']}
                          />

                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="form-group">
                              <FormLabel>Permissions</FormLabel>
                              <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="select one country"
                                value={permissions}
                                onChange={(val) => setPermissions(val)}
                                optionLabelProp="label"
                                options={options}
                                optionRender={(option) => (
                                  <Space>
                                    <span
                                      role="img"
                                      aria-label={option.data.label}
                                    >
                                      {option.data.emoji}
                                    </span>
                                    {option.data.desc}
                                  </Space>
                                )}
                              />
                            </div>
                          </div>
                        </>
                      )}

                      <div className="col-lg-12">
                        <MyButton
                          label={userId ? 'Update' : 'Save'}
                          icon="ion-plus"
                          type="success"
                          loading={loading}
                          onClick={handleSubmit}
                        />
                        <NavLink to="/user-list" className="btn btn-secondary">
                          Cancel
                        </NavLink>
                      </div>
                    </div>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddUser;
