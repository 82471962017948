import React, { useContext, useState } from 'react';
import { Formik } from 'formik';
import { InputField } from '../../../Components/InputFormFields';
import { addShopkeeperSchemaValidation } from '../../../config/Validations';
import { MyButton } from '../../../Components/MyButton';
import Watermark from '../../../Components/Watermark';
import { branchId, userRoles } from '../../../config/EnVariables';
import { colors } from '../../../config/theme';
import { Modal, message } from 'antd';
import Shopkeeper from '../../../Services/shopkeeperServices';
import { AuthContext } from '../../../Auth/Context/AuthContext';
import User from '../../../Services/userServices';

function ShopkeeperModal({
  updatingRecord,
  setUpdatingRecord,
  shopkeeperId,
  getShopkeepers,
}) {
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const initialValues = {
    name: updatingRecord?.name || '',
    address: updatingRecord?.address || '',
    phone: updatingRecord?.phone || '',
    email: updatingRecord?.email || '',
  };

  const onSubmit = (values) => {
    Modal.confirm({
      title: 'Please provide username and password for this user!',
      content: (
        <div>
          <label>Username</label>
          <input
            className="form-control"
            name="username"
            type="text"
            // onChange={(e) => setUserName(e.target.value)}
            onChange={(e) => (values.username = e.target.value)}
          />
          <label>Password</label>
          <input
            className="form-control"
            name="password"
            type="password"
            // onChange={(e) => setPassword(e.target.value)}
            onChange={(e) => (values.password = e.target.value)}
          />
        </div>
      ),
      onOk: () => submitForm(values),
    });
  };
  const submitForm = async (values) => {
    setLoading(true);
    const permissions = [];
    permissions.push({ name: 'READ' });
    const userRegisteringData = {
      name: values.name,
      email: values.email,
      username: values.username,
      password: values.password,
      phone: values.phone,
      address: values.address,
      role: userRoles.shopkeeper,
      permissions,
      branchId,
      branchName: user.branchName,
    };
    values.branchId = branchId;
    if (shopkeeperId) {
      values.id = shopkeeperId;
      Shopkeeper.update(values)
        .then((data) => {
          message.success('Updated Successfully');
          setLoading(false);
          getShopkeepers();
        })
        .catch((err) => {
          setLoading(false);
          message.error('Failed to Save');
        });
    } else {
      if (!values.name || !values.address || !values.phone) {
        return message.warning('Please fill the form correctly!');
      }

      await Shopkeeper.add(values)
        .then((data) => {
          if (data.data.status === 'Fail') {
            setLoading(false);
            return message.error('Failed to save');
          }
          if (user.role === userRoles.superadmin && !shopkeeperId) {
            userRegisteringData.shopkeeperId = data.data.id;
            userRegisteringData.shopkeeperName = data.data.name;

            User.addUser(userRegisteringData)
              .then((data) => {
                message.success('User Saved Successfully');
              })
              .catch((err) => {
                console.log(err);
              });
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <div
      className="modal fade"
      id="shopkeeperModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="shopkeeperModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header" style={{ background: colors.blue }}>
            <h5 className="modal-title" id="shopkeeperModalLabel">
              {updatingRecord?.id
                ? 'Update Shopkeeper - ' + updatingRecord?.name
                : 'Create New Shopkeeper'}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setUpdatingRecord(null);
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Watermark />
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={shopkeeperId ? submitForm : onSubmit}
              validationSchema={addShopkeeperSchemaValidation}
            >
              {({ errors, values, touched, handleSubmit, setFieldValue }) => (
                <>
                  <div>
                    <InputField
                      size="full"
                      label="Name"
                      name="name"
                      type="text"
                      required={true}
                      errors={errors['name']}
                      touched={touched['name']}
                    />
                    <InputField
                      size="full"
                      label="Phone"
                      name="phone"
                      type="text"
                      required={true}
                      errors={errors['phone']}
                      touched={touched['phone']}
                    />
                    <InputField
                      size="full"
                      label="Email"
                      name="email"
                      type="text"
                      errors={errors['email']}
                      touched={touched['email']}
                    />
                    <InputField
                      size="full"
                      label="Address"
                      name="address"
                      type="text"
                      errors={errors['address']}
                      touched={touched['address']}
                    />
                    <div className="col-lg-12">
                      {/* <Button type="primary" onClick={handleSubmit}>
                          {shopKeeperId ? 'Update' : 'Save'}
                        </Button> */}
                      <MyButton
                        label={shopkeeperId ? 'Update' : 'Save'}
                        icon="ion-plus"
                        type="success"
                        loading={loading}
                        onClick={handleSubmit}
                      />
                      <button
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={() => setUpdatingRecord(null)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShopkeeperModal;
