import { colors } from '../config/theme';

export const LabelHead = ({ label, children }) => {
  return (
    <div
      style={{
        width: '100%',
        padding: 3,
        background: colors.blue,
        borderRadius: 3,
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <h5 style={{ color: colors.white }}>{label}</h5>
      {children}
    </div>
  );
};
