import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import callApi from '../../helpers/callApi';
import { branchId } from '../../config/EnVariables';
function AuditLogs(props) {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterUsername, setFilterUsername] = useState(null);
  let pageSizeValue = 20;
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: pageSizeValue,
      showSizeChanger: true,
      pageSizeOptions: [10, 15, 20, 25, 30, 35, 40, 45, 50, 100],
    },
  });
  let paging = { current: 1 };
  const handleTableChange = async (pagination, filter, sorter) => {
    pageSizeValue = pagination.pageSize;
    setTableParams({ pagination, filter, ...sorter });
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setLogs([]);
    }
    const params = { size: pagination.pageSize };
    paging.current = pagination.current;
    await fetchData(params);
  };

  async function fetchData(params = {}) {
    setLoading(true);
    let endpoint = `audit-logs/?branchId=${branchId}`;
    if (filterUsername)
      endpoint = `audit-logs/?branchId=${branchId}&username=${filterUsername}`;
    const params1 = {
      ...params,
      page: paging.current - 1 || 0,
      size: pageSizeValue || null,
    };
    try {
      const response = await callApi(endpoint, null, null, params1);
      setLogs(response?.data);
      const totalLogs = response?.headers?.get('X-Total-Count');
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: totalLogs,
          current: params1.page + 1,
          pageSize: pageSizeValue,
        },
      });
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);
  const columns = [
    {
      title: 'Sno',
      dataIndex: 'sno',
    },
    {
      title: 'Username',
      dataIndex: 'username',
    },
    {
      title: 'Activity',
      dataIndex: 'activity',
    },
    {
      title: 'Date',
      dataIndex: 'date',
    },
    {
      title: 'Entity',
      dataIndex: 'entity',
    },
    {
      title: 'Transaction',
      dataIndex: 'transaction',
    },
  ];
  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group d-flex">
                <input
                  className="form-control"
                  placeholder="Filter by username"
                  name="filterUsername"
                  value={filterUsername}
                  onChange={(e) => setFilterUsername(e.target.value)}
                />
                <button onClick={() => fetchData()} className="btn btn-primary">
                  <i className="ion-search" /> Search
                </button>
                <button
                  className="btn btn-dark"
                  onClick={() => {
                    setFilterUsername('');
                    fetchData();
                  }}
                >
                  <i className="ion-loop" /> Reset
                </button>
              </div>

              <div className="table-responsive">
                <Table
                  className="table-striped"
                  size="small"
                  columns={columns}
                  scroll={{ x: 1000 }}
                  pagination={tableParams.pagination}
                  onChange={handleTableChange}
                  loading={loading}
                  dataSource={logs?.map((item, index) => ({
                    key: item._id,
                    sno: index + 1,
                    activity: <p style={{ color: 'green' }}>{item.activity}</p>,
                    username: <p>{item.username}</p>,
                    userEmail: item.result?.email,
                    date: <p style={{ color: 'red' }}>{item.createdDate}</p>,
                    entity: item.entityName,
                    transaction: item.transactionName,
                  }))}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AuditLogs;
