import React, { useContext, useState } from 'react';
import { Formik } from 'formik';
import {
  AntdSelectField,
  InputField,
} from '../../../Components/InputFormFields';
import { addShopSchemaValidation } from '../../../config/Validations';
import { MyButton } from '../../../Components/MyButton';
import Watermark from '../../../Components/Watermark';
import { branchId } from '../../../config/EnVariables';
import { colors } from '../../../config/theme';
import { message } from 'antd';
import { AuthContext } from '../../../Auth/Context/AuthContext';
import Shop from '../../../Services/shopServices';

function ShopModal({ updatingRecord, setUpdatingRecord, shopId, getShops }) {
  const [loading, setLoading] = useState(false);
  const { floors, accounts } = useContext(AuthContext);
  const initialValues = {
    name: updatingRecord?.name || '',
    floor: updatingRecord?.buildingFloorId || '',
    area: updatingRecord?.area || 0,
    revenueAccountId: updatingRecord?.revenueAccountId || [],
    maintenanceAccountId: updatingRecord?.maintenanceAccountId || [],
    servicesAccountId: updatingRecord?.servicesAccountId || [],
    utilitiesAccountId: updatingRecord?.utilitiesAccountId || [],
    isSpliteable: updatingRecord?.isSpliteable || false,
  };
  const addShop = (values) => {
    let apiMethod = 'POST';
    setLoading(true);
    values.branchId = branchId;
    values.buildingFloorId = parseInt(values.floor) || 1;

    values.maintenanceAccountName = accounts.find(
      (op) => op.id === parseInt(values.maintenanceAccountId),
    )?.name;
    values.servicesAccountName = accounts.find(
      (op) => op.id === parseInt(values.servicesAccountId),
    )?.name;
    values.utilitiesAccountName = accounts.find(
      (op) => op.id === parseInt(values.utilitiesAccountId),
    )?.name;
    values.revenueAccountName = accounts.find(
      (op) => op.id === parseInt(values.revenueAccountId),
    )?.name;
    values.buildingFloorFloorNumber = floors.find(
      (op) => op.id === parseInt(values.floor),
    )?.floorNumber;
    const data = {
      ...values,
      revenueAccountId: parseInt(values.revenueAccountId),
      maintenanceAccountId: parseInt(values.maintenanceAccountId),
      serviceAccountId: parseInt(values.serviceAccountId),
      utilitiesAccountId: parseInt(values.utilitiesAccountId),

      monthlyRent: 0,
    };
    if (shopId) {
      data.id = shopId;
      apiMethod = 'PUT';
    }
    Shop.add(data, apiMethod)
      .then((data) => {
        message.success('Saved Successfully');
        setLoading(false);
        getShops();
      })
      .catch((err) => {
        setLoading(false);
        message.error('Faild to Save Shop');
        console.log(err);
      });
  };

  return (
    <div
      className="modal fade"
      id="shopModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="shopModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header" style={{ background: colors.blue }}>
            <h5 className="modal-title" id="shopModalLabel">
              {updatingRecord?.id
                ? 'Update Shop - ' + updatingRecord?.name
                : 'Create New Shop'}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setUpdatingRecord(null);
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Watermark />
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={addShop}
              validationSchema={addShopSchemaValidation}
            >
              {({ errors, touched, values, setFieldValue, handleSubmit }) => (
                <>
                  <div className="d-flex">
                    <InputField
                      label="Shop Number"
                      name="name"
                      type="text"
                      required={true}
                      errors={errors['name']}
                      touched={touched['name']}
                    />

                    <InputField
                      label={
                        <>
                          Shop Area (m<sup>2</sup>)
                        </>
                      }
                      name="area"
                      type="number"
                      required={true}
                      errors={errors['area']}
                      touched={touched['area']}
                    />
                  </div>
                  <div className="d-flex">
                    <AntdSelectField
                      name="floor"
                      required={true}
                      label="Floor #"
                      value={values.floor}
                      options={floors?.map((item) => ({
                        value: item.id,
                        label: 'Floor #' + item.floorNumber,
                      }))}
                      onChange={(e) => setFieldValue('floor', e)}
                      errors={errors['floor']}
                      touched={touched['floor']}
                    />

                    <AntdSelectField
                      name="revenueAccountId"
                      label="Revenue Account"
                      value={values.revenueAccountId}
                      options={accounts
                        .filter((op) => op.generalAccountCategory === 'Income')
                        ?.map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))}
                      onChange={(e) => setFieldValue('revenueAccountId', e)}
                      errors={errors['revenueAccountId']}
                      touched={touched['revenueAccountId']}
                    />

                    <AntdSelectField
                      name="maintenanceAccountId"
                      label="Maintenance Account"
                      value={values.maintenanceAccountId}
                      options={accounts
                        .filter((op) => op.generalAccountCategory === 'Income')
                        ?.map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))}
                      onChange={(e) => setFieldValue('maintenanceAccountId', e)}
                      errors={errors['maintenanceAccountId']}
                      touched={touched['maintenanceAccountId']}
                    />
                  </div>
                  <div className="d-flex">
                    <AntdSelectField
                      name="servicesAccountId"
                      label="Service Account"
                      value={values.servicesAccountId}
                      options={accounts
                        .filter((op) => op.generalAccountCategory === 'Income')
                        ?.map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))}
                      onChange={(e) => setFieldValue('servicesAccountId', e)}
                      errors={errors['servicesAccountId']}
                      touched={touched['servicesAccountId']}
                    />

                    <AntdSelectField
                      name="utilitiesAccountId"
                      label="Utility Account"
                      value={values.utilitiesAccountId}
                      options={accounts
                        .filter((op) => op.generalAccountCategory === 'Income')
                        ?.map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))}
                      onChange={(e) => setFieldValue('utilitiesAccountId', e)}
                      errors={errors['utilitiesAccountId']}
                      touched={touched['utilitiesAccountId']}
                    />

                    <AntdSelectField
                      name="isSpliteable"
                      label="Is Spliteable?"
                      value={values.isSpliteable}
                      options={[
                        { value: true, label: 'Yes' },
                        { value: false, label: 'No' },
                      ]}
                      onChange={(e) => setFieldValue('isSpliteable', e)}
                      errors={errors['isSpliteable']}
                      touched={touched['isSpliteable']}
                    />
                  </div>
                  <br />
                  <div className="col-lg-12">
                    <MyButton
                      icon="ion-plus"
                      loading={loading}
                      label="Save"
                      type="success"
                      onClick={handleSubmit}
                    />
                    <button
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      onClick={() => setUpdatingRecord(null)}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShopModal;
