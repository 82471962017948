import { browserData, userRoles } from '../config/EnVariables';

const user = JSON.parse(localStorage.getItem(browserData.user));
export const Authorize = (role) => {
  if (user?.role === role) return true;
  else return false;
};
export const permit = (permission) => {
  return (
    user.role === userRoles.superadmin ||
    user.permissions.some((item) => item.name === permission)
  );
};
