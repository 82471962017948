import React from 'react';

export default function Watermark() {
  return (
    <img
      src="/assets/img/logo.png"
      alt="logo"
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        opacity: 0.2,
        width: '100px',
        height: 'auto',
      }}
    />
  );
}
