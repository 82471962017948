import React, { useContext } from 'react';
import { AuthContext } from '../../Auth/Context/AuthContext';
import { message } from 'antd';
import PasswordResetModal from './PasswordResetModal';

function Profile(props) {
  const { user } = useContext(AuthContext);
  return (
    <>
      {/* <div className=" "> */}
      <div className="page-wrapper">
        <div className="content">
          <div className="card">
            <div className="card-body">
              <div className="profile-set">
                <div className="profile-head"></div>
                <div className="profile-top">
                  <div className="profile-content">
                    <div className="profile-contentimg">
                      <img
                        src="/assets/img/user-avatar.png"
                        alt="img"
                        id="blah"
                      />
                      <div className="profileupload">
                        <input type="file" id="imgInp" />
                        <a href="#">
                          <img src="/assets/img/icons/edit-set.svg" alt="img" />
                        </a>
                      </div>
                    </div>
                    <div className="profile-contentname">
                      <h2>{user.username}</h2>
                      <h4>Update Your Photo and Personal Details.</h4>
                    </div>
                  </div>
                  <div className="ms-auto">
                    <button
                      className="btn btn-dark"
                      data-target="#passwordResetModal"
                      data-toggle="modal"
                      data-whatever="@mdo"
                    >
                      <i className="ion-settings" /> Reset Password
                    </button>
                    <a
                      href="#"
                      className="btn btn-primary me-2"
                      onClick={() =>
                        message.warning('This feature will be added')
                      }
                    >
                      <img src="/assets/img/icons/edit-set.svg" alt="img" />{' '}
                      Update
                    </a>
                    <PasswordResetModal />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="text"
                      placeholder="example@example.com"
                      value={user.email}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <label>Phone</label>
                    <input
                      type="text"
                      placeholder="07**********"
                      value={user.phone}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <label>Username</label>
                    <input
                      type="text"
                      placeholder="Username"
                      value={user.username}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <label>Role</label>
                    <input
                      type="text"
                      disabled
                      placeholder="ROle"
                      value={user.role}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
