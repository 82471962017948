import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { colors } from '../config/theme';
function PrintModal({ content }) {
  let printRef = useRef(null);
  return (
    <div
      className="modal fade"
      id="printModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="printModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header" style={{ background: colors.blue }}>
            <h5 className="modal-title" id="printModalLabel">
              Print
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" ref={(el) => (printRef = el)}>
            {content}
          </div>
          <div className="modal-footer">
            <ReactToPrint
              trigger={() => (
                <button className="btn btn-success">
                  <i className="ion-printer" /> Print
                </button>
              )}
              content={() => printRef}
            />
            <button
              type="button"
              className="btn btn-danger"
              data-dismiss="modal"
            >
              <i className="ion-close" /> Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrintModal;
