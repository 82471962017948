import { Spin } from 'antd';

export const MyButton = ({ label, type, loading, onClick, icon }) => {
  return (
    <button
      disabled={loading}
      href="#"
      class={`btn btn-${type}`}
      onClick={onClick}
    >
      {loading ? (
        <Spin />
      ) : (
        <div>
          <i className={icon}></i> {label}
        </div>
      )}
    </button>
  );
};
