import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../Auth/Context/AuthContext';
import { branchId, browserData, userRoles } from '../config/EnVariables';
import { Authorize } from '../helpers/Authorize';
import Branch from '../Services/branchServices';
import { Modal, Spin, message } from 'antd';
import { NavLink } from 'react-router-dom';

function Header(props) {
  const {
    user,
    setUser,
    globalProject,
    setGlobalProject,
    controlSideBar,
    setControlSideBar,
  } = useContext(AuthContext);
  const [modal, contextHolder] = Modal.useModal();
  const [projects, setProjects] = useState([]);
  const fetchAllbranches = () => {
    Branch.getAllBranches().then((acc) => {
      const transofrmedData = acc.data?.map((op) => ({
        value: op.id,
        label: op.name,
      }));
      setProjects(transofrmedData);
    });
  };
  useEffect(() => {
    fetchAllbranches();
  }, []);
  const logout = () => {
    localStorage.removeItem(browserData.access_token);
    localStorage.removeItem(browserData.user);
    localStorage.removeItem(browserData.globalProject);
    setUser(null);
    setGlobalProject(null);
    window.location.replace('/');
  };

  const updateProject = (e) => {
    if (user.role === userRoles.superadmin) {
      let secondsToGo = 3;
      const instance = modal.success({
        title: 'Switching',
        content: `Please wait while the project is switched in ${secondsToGo} second.`,
        okText: <Spin />,
        okType: 'light',
      });
      const timer = setInterval(() => {
        secondsToGo -= 1;
        instance.update({
          content: `Please wait while the project is switched in ${secondsToGo} second.`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(timer);
        instance.destroy();
        window.location.reload();
      }, secondsToGo * 1000);
      setGlobalProject(e.target.value);
      localStorage.removeItem(browserData.globalProject);
      localStorage.setItem(browserData.globalProject, e.target.value);
      const label = projects.find(
        (op) => op.value === parseInt(e.target.value),
      )?.label;
      localStorage.setItem(browserData.globalProjectName, label);
    } else message.error("You don't have permission for this action!");
  };

  return (
    <div className="header no-print">
      {contextHolder}
      <div className="header-left active">
        <NavLink to="/" className="logo">
          <img src="/assets/img/logo.png" alt="" />
        </NavLink>
        <NavLink to="/" className="logo-small">
          <img src="/assets/img/logo-small.png" alt="" />
        </NavLink>
        <a
          id="toggle_btn"
          href="#"
          onClick={() => setControlSideBar(!controlSideBar)}
        ></a>
      </div>

      <a id="mobile_btn" className="mobile_btn" href="#sidebar">
        <span className="bar-icon">
          <span></span>
          <span></span>
          <span></span>
        </span>
      </a>

      <ul
        className="nav user-menu"
        style={{ height: '100%', alignItems: 'center' }}
      >
        <li>
          {user.role != userRoles.superadmin && (
            <span className="badge badge-pill badge-info">
              {user?.branchName || branchId === 1
                ? 'Main Office'
                : '' || user?.branchId}
            </span>
          )}
        </li>
        {branchId === 1 && (
          <li className="no-print">
            <span className="badge badge-info">
              <i className="ion-tick" />
              HQ
            </span>{' '}
            &nbsp;
          </li>
        )}
        {user.role === userRoles.superadmin && (
          <div className="no-print">
            <li className="nav-item dropdown has-arrow main-drop">
              <select
                className="form-control"
                onChange={(e) => {
                  updateProject(e);
                }}
                value={globalProject}
              >
                {projects?.map((item, i) => (
                  <option key={i + 1} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </li>
          </div>
        )}
        <li
          className="nav-item dropdown has-arrow main-drop no-print"
          style={{ marginTop: -15 }}
        >
          <a
            href="#"
            className="dropdown-toggle nav-link userset"
            data-bs-toggle="dropdown"
          >
            <span className="user-img">
              <img src="/assets/img/user-avatar.png" alt="" />
              <span className="status online"></span>
            </span>
          </a>
          <div className="dropdown-menu menu-drop-user">
            <div className="profilename">
              <div className="profileset">
                <span className="user-img">
                  <img src="/assets/img/user-avatar.png" alt="" />
                  <span className="status online"></span>
                </span>
                <div className="profilesets">
                  <h6>
                    {user.username}({user.branchName || branchId})
                  </h6>
                  <h5>{user.role}</h5>
                </div>
              </div>
              <hr className="m-0" />
              <NavLink className="dropdown-item" to="user-profile">
                <i className="me-2" data-feather="user"></i> My Profile
              </NavLink>
              {Authorize(userRoles.superadmin) && (
                <>
                  <NavLink className="dropdown-item" to="/branches">
                    <i className="me-2" data-feather="list"></i>
                    Projects
                  </NavLink>
                  <NavLink className="dropdown-item" to="/admin/audit-logs">
                    <i className="me-2" data-feather="list"></i>Audit Logs
                  </NavLink>
                </>
              )}
              <hr className="m-0" />
              <button className="dropdown-item logout pb-0" onClick={logout}>
                <img
                  src="/assets/img/icons/log-out.svg"
                  className="me-2"
                  alt="img"
                />
                Logout
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default Header;
