import React, { useContext } from 'react';
import Dashboard from './Dashboard';
import Footer from './Footer';
import { AuthContext } from '../Auth/Context/AuthContext';
import { userRoles } from '../config/EnVariables';
import { MyGraph } from './UI/Graph';
import { FinanceDashboard } from './UI/FinanceDashboard';
import Loader from './Loader';
function Main(props) {
  const { user, loaded } = useContext(AuthContext);
  return loaded ? (
    <div
      className="main-wrapper"
      style={user.role !== userRoles.superadmin ? { marginTop: 10 } : {}}
    >
      {user.role === userRoles.superadmin ? (
        <Dashboard />
      ) : user.role === userRoles.admin ? (
        <MyGraph />
      ) : user.role === userRoles.finance ||
        user.role === userRoles.admin_finance ? (
        <FinanceDashboard />
      ) : (
        <div className="page-wrapper">
          <h2>You are not the authorized user!</h2>
        </div>
      )}
      <Footer />
    </div>
  ) : (
    <Loader loading={loaded} />
  );
}

export default Main;
