import React, { useContext, useEffect, useState } from 'react';
import User from '../Services/userServices';
import { Pie } from '@ant-design/charts';
import { userRoles } from '../config/EnVariables';
import { AuthContext } from '../Auth/Context/AuthContext';
import BalanceSheet from '../Pages/accounts/reports/BalanceSheet';
import { Authorize } from '../helpers/Authorize';
import { CardComponent } from './CardComponent';
import { CardDashboard } from './CardDashboard';
import { CounterUp } from './CounterUp';

function Dashboard() {
  const { user } = useContext(AuthContext);
  const [report, setReport] = useState();
  const [globalReport, setGlobalReport] = useState();
  const getReports = () => {
    User.getReports()
      .then((response) => {
        setReport(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getGlobalReports = () => {
    User.getGlobalReports()
      .then((response) => {
        let items = response.data?.map((p, i) => ({
          name: p.name,
          balance: p.balance,
          id: i + 1,
          accountTypes: p.accountTypes,
        }));
        setGlobalReport(items);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getReports();
    if (user.role === userRoles.superadmin) getGlobalReports();
  }, []);

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            {globalReport?.map((item, i) => (
              <CounterUp key={i + 1} title={item.name} value={item.balance} />
            ))}
          </div>

          <div className="row">
            <div className="d-flex">
              <CardComponent
                title="Total Shops"
                icon="/assets/img/icons/dash1.svg"
                count={report?.totalShops}
                link="/shop-list"
              />

              <CardComponent
                title="Total Sold Shops"
                icon="/assets/img/icons/dash2.svg"
                count={report?.totalSoldShops}
                link="/shop-list"
              />

              <CardComponent
                title="Shops on Rent"
                icon="/assets/img/icons/dash3.svg"
                count={report?.totalRentalShops}
                link="/shop-list"
              />

              <CardComponent
                title="Other Shops"
                icon="/assets/img/icons/dash4.svg"
                count={
                  report?.totalShops -
                    report?.totalSoldShops -
                    report?.totalRentalShops || 0
                }
                link="/shop-list"
              />
            </div>
            <CardDashboard
              className="dash-count"
              title="Total Shopkeepers"
              count={report?.totalShopkeepers || 0}
              icon="user"
              link="/shopkeeper-list"
            />

            <CardDashboard
              className="dash-count das3"
              title="Active Shopkeepers"
              count={report?.totalActiveShopkeepers || 0}
              icon="user"
              link="/shopkeeper-list"
            />

            <CardDashboard
              className="dash-count das2"
              title="Others"
              count={
                report?.totalShopkeepers - report?.totalActiveShopkeepers || 0
              }
              icon="user"
              link="/shopkeeper-list"
            />
          </div>

          <div className="row">
            <div className="col-lg-6 col-sm-12 col-12 d-flex">
              <div className="card flex-fill">
                <h3>Shops ({report?.totalShops})</h3>
                <Pie
                  {...{
                    radius: 0.8,
                    data: [
                      {
                        type: 'Free Shops',
                        value:
                          report?.totalShops -
                            report?.totalSoldShops -
                            report?.totalRentalShops || 0,
                      },
                      {
                        type: 'Sold Shops',
                        value: report?.totalSoldShops || 0,
                      },
                      {
                        type: 'Shops on Rent',
                        value: report?.totalRentalShops || 0,
                      },
                    ],
                    angleField: 'value',
                    colorField: 'type',
                    label: {
                      visible: true,
                      type: 'inner',
                    },
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 col-12 d-flex">
              <div className="card flex-fill">
                <h3>Shopkeepers ({report?.totalShopkeepers})</h3>
                <Pie
                  {...{
                    radius: 0.8,
                    data: [
                      {
                        type: 'Total Shopkeeper',
                        value: report?.totalShopkeepers,
                      },
                      {
                        type: 'Active Shopkeepers',
                        value: report?.totalActiveShopkeepers,
                      },
                      {
                        type: 'Others',
                        value:
                          report?.totalShopkeepers -
                          report?.totalActiveShopkeepers,
                      },
                    ],
                    angleField: 'value',
                    colorField: 'type',
                    label: {
                      visible: true,
                      type: 'inner',
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {globalReport?.length > 0 && Authorize(userRoles.superadmin) && (
        <div>
          <div className="page-wrapper">
            <div className="card flex-fill">
              <h3>Balance Sheet</h3>
              <Pie
                {...{
                  radius: 0.8,
                  data: globalReport?.map((op) => ({
                    type: op.name,
                    value: Math.round(op.balance),
                  })),
                  angleField: 'value',
                  colorField: 'type',
                  label: {
                    visible: true,
                    type: 'inner',
                  },
                }}
              />
            </div>
          </div>
          <BalanceSheet data={globalReport} />
        </div>
      )}
    </>
  );
}

export default Dashboard;
