export const accountsMenu = [
  {
    link: '/accounts-list',
    icon: 'ion-navicon',
    label: 'Chart of Accounts',
  },
  // {
  //   link: '/add-account',
  //   icon: 'ion-plus',
  //   label: 'Create Account',
  // },
  {
    link: '/journel-entry',
    icon: 'ion-plus',
    label: 'Journal Entry',
  },
];
export const accountReportsMenu = [
  {
    link: '/genral-journel',
    icon: 'ion-navicon',
    label: 'General Journal',
  },
  {
    link: '/journel-ledger',
    icon: 'ion-navicon',
    label: 'General Ledgers',
  },
  {
    link: '/trial-balance',
    icon: 'ion-navicon',
    label: 'Trial Balance',
  },
  {
    link: '/income-statement',
    icon: 'ion-navicon',
    label: 'Income Statement',
  },
  {
    link: '/balance-sheet',
    icon: 'ion-navicon',
    label: 'Balance Sheet',
  },
];
export const shopsMenu = [
  {
    link: '/shop-list',
    icon: 'ion-navicon',
    label: 'Shops',
  },
  // {
  //   link: '/add-shop',
  //   icon: 'ion-plus',
  //   label: 'Create Shop',
  // },
  {
    link: '/contract-list',
    icon: 'ion-navicon',
    label: 'Contracts',
  },
  // {
  //   link: '/add-contract',
  //   icon: 'ion-plus',
  //   label: 'Add Contract',
  // },
];
export const appartmentsMenu = [
  {
    link: '/shop-list',
    icon: 'ion-navicon',
    label: 'Appartment List',
  },
  {
    link: '/add-shop',
    icon: 'ion-plus',
    label: 'Create Appartment',
  },
  {
    link: '/contract-list',
    icon: 'ion-navicon',
    label: 'Contracts List',
  },
  {
    link: '/add-contract',
    icon: 'ion-plus',
    label: 'Add Contract',
  },
];
export const shopkeepersMenu = [
  {
    link: '/shopkeeper-list',
    icon: 'ion-navicon',
    label: 'Shopkeepers List',
  },
  {
    link: '/add-shopkeeper',
    icon: 'ion-plus',
    label: 'Register Shopkeeper',
  },
];
export const tenantsMenu = [
  {
    link: '/shopkeeper-list',
    icon: 'ion-navicon',
    label: 'Tenants List',
  },
  {
    link: '/add-shopkeeper',
    icon: 'ion-plus',
    label: 'Register Tenant',
  },
];
export const floorsMenu = [
  {
    link: '/floor-list',
    icon: 'ion-navicon',
    label: 'Floors List',
  },
  {
    link: '/add-floor',
    icon: 'ion-plus',
    label: 'Create floor',
  },
];
export const usersMenu = [
  {
    link: '/user-list',
    icon: 'ion-navicon',
    label: 'Users List',
  },
  // {
  //   link: '/add-user',
  //   icon: 'ion-plus',
  //   label: 'Create User',
  // },
];
