import React, { useEffect, useState } from 'react';

import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import Floor from '../../Services/floorServices';
import TitleHeader from '../accounts/Components/TitleHeader';
import Loader from '../../Components/Loader';
import { branchId } from '../../config/EnVariables';
import { MyButton } from '../../Components/MyButton';
import { InputField } from '../../Components/InputFormFields';
import { addFloorSchemaValidation } from '../../config/Validations';
function AddFloor(props) {
  const { edit } = props;
  const floorId = useParams().id;

  const [loading, setLoading] = useState(false);
  const [floorRecord, setFloorRecord] = useState(false);

  const getFloorEdit = async () => {
    if (edit && floorId) {
      setLoading(true);
      await Floor.getOne(floorId)
        .then((data) => {
          setFloorRecord(data.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (floorId) {
      getFloorEdit();
    }
  }, []);
  const navigate = useNavigate();

  const initialValues = {
    floorNumber: floorRecord?.floorNumber || '',
    area: floorRecord?.area || 0,
  };
  const onSubmit = (values) => {
    setLoading(true);
    values.branchId = branchId;
    if (floorId) {
      values.id = parseInt(floorId);
      Floor.update(values)
        .then((data) => {
          setLoading(false);

          navigate('/floor-list');
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else
      Floor.addFloor(values)
        .then((data) => {
          setLoading(false);

          navigate(`/floor-details/${data.data.id}`);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
  };
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <TitleHeader title="Add Floor" />

          <div className="container card">
            <div className="card-body">
              <Loader loading={loading} />
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={addFloorSchemaValidation}
              >
                {({ errors, touched, handleSubmit }) => (
                  <>
                    <div className="row">
                      <InputField
                        label="Floor Number"
                        name="floorNumber"
                        type="text"
                        required={true}
                        errors={errors['floorNumber']}
                        touched={touched['floorNumber']}
                      />
                      <InputField
                        label={
                          <>
                            Shop Area (m<sup>2</sup>)
                          </>
                        }
                        name="area"
                        type="number"
                        required={true}
                        errors={errors['area']}
                        touched={touched['area']}
                      />

                      <div className="col-lg-12">
                        <MyButton
                          label={floorId ? 'Update' : 'Save'}
                          icon="ion-plus"
                          type="success"
                          loading={loading}
                          onClick={handleSubmit}
                        />
                        <NavLink className="btn btn-secondary" to="/floor-list">
                          Cancel
                        </NavLink>
                      </div>
                    </div>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddFloor;
