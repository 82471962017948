import axios from 'axios';
import { baseUrl, branchId } from '../config/EnVariables';
import callApi from '../helpers/callApi';
const token = localStorage.getItem('access_token'); // Replace with your actual Bearer Token
const headers = {
  Authorization: `Bearer ${token}`,
  'Content-Type': 'application/json', // Assuming you're sending JSON data
};

const Shopkeeper = {
  getAll: (num) =>
    callApi(`shopkeepers/?branchId=${branchId}&${num ? 'size=' + num : ''}`),

  getRents: (shopkeeperId) =>
    callApi(
      `general-journals/received-payments-by-shopkeeper?shopkeeperId=${shopkeeperId}`,
    ),

  getContracts: (shopkeeperId) =>
    callApi(
      `shop-contracts/shopkeeper-shops-history?shopkeeperId=${shopkeeperId}`,
    ),

  getOne: (shopkeeperId) => callApi(`shopkeepers/${shopkeeperId}`),
  add: (data) =>
    axios.post(`${baseUrl}/shopkeepers`, data, {
      headers: headers,
    }),

  update: (data) =>
    axios.put(`${baseUrl}/shopkeepers/?branchId=${branchId}`, data, {
      headers: headers,
    }),

  remove: (id) =>
    axios.delete(`${baseUrl}/shopkeepers/${id}`, { headers: headers }),
};

export default Shopkeeper;
