import { contractTypeOptions } from '../Utils/options';

let now = new Date();
export const getDates = (key) => {
  let dates = {
    startDate: `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`,
    endDate: `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`,
  };
  if (key === 'today') {
    dates.startDate = getTodayDate();
    dates.endDate = getTodayDate();
    return dates;
  } else if (key === 'week') {
    dates.startDate = getLastWeekDate();
    return dates;
  } else if (key === 'month') {
    dates.startDate = getLastMonthDate();
    return dates;
  } else if (key === 'months-3') {
    dates.startDate = getLastThreeMonthsDate();
    return dates;
  } else if (key === 'year') {
    dates.startDate = getLastYearDate();
    return dates;
  }
};

export const getTodayDate = () => {
  return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
    2,
    '0',
  )}-${String(now.getDate()).padStart(2, '0')}`;
};

export const getStartMonthDate = () => {
  return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
    2,
    '0',
  )}-01`;
};

const getLastWeekDate = () => {
  const date = new Date(now.getTime());
  date.setMonth(date.getMonth() + 1);
  date.setDate(date.getDate() - 7);
  return setDateFormat(date);
};

const getLastMonthDate = () => {
  const previousMonth = new Date(now.getTime());
  previousMonth.setMonth(previousMonth.getMonth());
  return setDateFormat(previousMonth);
};

const getLastThreeMonthsDate = () => {
  const date = new Date(now.getTime());
  date.setMonth(date.getMonth() - 3);
  return setDateFormat(date);
};

const getLastYearDate = () => {
  const date = new Date(now.getTime());
  date.setFullYear(date.getFullYear() - 1);
  date.setMonth(date.getMonth() + 1);
  return setDateFormat(date);
};
export const getLastTenYearDate = () => {
  const date = new Date(now.getTime());
  date.setFullYear(date.getFullYear() - 10);
  date.setMonth(date.getMonth() + 1);
  return setDateFormat(date);
};

function setDateFormat(date) {
  return `${date.getFullYear()}-${String(date.getMonth()).padStart(
    2,
    '0',
  )}-${String(date.getDate()).padStart(2, '0')}`;
}

export const getContractEndDate = (value, startDate) => {
  if (startDate) {
    now = new Date(startDate);
  }
  const date = new Date(now.getTime());

  if (value === contractTypeOptions[0].value) {
    date.setMonth(date.getMonth() + 2);
    return setDateFormat(date);
  }
  if (value === contractTypeOptions[1].value) {
    date.setMonth(date.getMonth() + 4);
    return setDateFormat(date);
  }
  if (value === contractTypeOptions[2].value) {
    date.setMonth(date.getMonth() + 7);
    return setDateFormat(date);
  }
  if (value === contractTypeOptions[3].value) {
    date.setFullYear(date.getFullYear() + 1);
    date.setMonth(date.getMonth() + 1);
    return setDateFormat(date);
  }
};

export const amount = (num) => Number(num)?.toFixed(2).toLocaleString('en-US');

export const removeEmptyAttributes = (obj) => {
  const filteredAttributes = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
        filteredAttributes[key] = obj[key];
      }
    }
  }
  return filteredAttributes;
};
