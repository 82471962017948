import React, { useEffect, useState } from 'react';
import { FormLabel } from 'react-bootstrap';
import { Formik } from 'formik';
import User from '../../../Services/userServices';
import Branch from '../../../Services/branchServices';
import { Select, Space, message } from 'antd';
import { userRoles } from '../../../config/EnVariables';
import {
  AntdSelectField,
  InputField,
} from '../../../Components/InputFormFields';
import Shopkeeper from '../../../Services/shopkeeperServices';
import { addUserSchemaValidation } from '../../../config/Validations';
import { MyButton } from '../../../Components/MyButton';
import Watermark from '../../../Components/Watermark';
import { removeEmptyAttributes } from '../../../helpers/quick';
import { colors } from '../../../config/theme';
import { booleanOptions } from '../../../Utils/options';
const options = [
  {
    label: 'CREATE',
    value: 'CREATE',
    emoji: <i className="ion-plus" />,
    desc: 'CREATE',
  },
  {
    label: 'READ',
    value: 'READ',
    emoji: <i className="fa fa-book" />,
    desc: 'READ',
  },
  {
    label: 'UPDATE',
    value: 'UPDATE',
    emoji: <i className="ion-edit" />,
    desc: 'UPDATE',
  },
  {
    label: 'DELETE',
    value: 'DELETE',
    emoji: <i className="ion-minus" />,
    desc: 'DELETE',
  },
];
function UserModal({ onClose, updatingUser, setUpdatingUser, getUsers }) {
  const [loading, setLoading] = useState(false);

  const [branches, setBranches] = useState([]);
  const [shopkeepers, setShopkeepers] = useState([]);
  const perms = updatingUser?.permissions?.map((op) => op.name);

  const getAllShopkeepers = async () => {
    await Shopkeeper.getAll(999999).then((shops) => {
      const updatedData = shops.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setShopkeepers(updatedData);
    });
  };
  useEffect(() => {
    getAllShopkeepers();
  }, []);

  const getAllBranches = () => {
    Branch.getAllBranches()
      .then((b) => {
        setBranches(b.data);
      })
      .catch((err) => console.log('Error', err));
  };
  useEffect(() => {
    getAllBranches();
  }, []);
  const initialValues = {
    branchId: updatingUser?.branchId || '',
    shopkeeperId: updatingUser?.shopkeeperId || '',
    email: updatingUser?.email || '',
    username: updatingUser?.username || '',
    phone: updatingUser?.phone || '',
    password: '',
    address: updatingUser?.address || '',
    role: updatingUser?.role || '',
    permissions: perms || ['READ'],
    isShopkeeper: updatingUser?.role === userRoles.shopkeeper || false,
  };

  const registerUser = (values) => {
    let apiMethod = 'POST';
    setLoading(true);
    values.permissions = values.permissions?.map((p) => ({
      name: p,
    }));
    values.branchName = branches.find(
      (op) => op.id === parseInt(values.branchId),
    )?.name;
    if (values.isShopkeeper) {
      values.role = userRoles.shopkeeper;
      values.permissions = [{ name: 'READ' }];
    }
    values = removeEmptyAttributes(values);

    if (updatingUser?.id) {
      values.id = updatingUser?.id;
      apiMethod = 'PUT';
    } else if (!values.password) {
      setLoading(false);
      return message.error('Password is Required');
    }
    User.addUser(values, apiMethod)
      .then((data) => {
        message.success('Saved Successfully');
        setLoading(false);
        setUpdatingUser(null);
        getUsers();
      })
      .catch((err) => {
        message.error(err.response?.data?.message);
        setLoading(false);
      });
  };

  return (
    <div
      className="modal fade"
      id="printModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="printModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header" style={{ background: colors.blue }}>
            <h5 className="modal-title" id="printModalLabel">
              {updatingUser?.id
                ? 'Update User - ' +
                  updatingUser?.username +
                  '(' +
                  updatingUser?.role +
                  ')'
                : 'Create New User'}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setUpdatingUser(null);
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Watermark />
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={registerUser}
              validationSchema={addUserSchemaValidation}
            >
              {({ errors, touched, values, handleSubmit, setFieldValue }) => (
                <>
                  <div className="row">
                    <AntdSelectField
                      label="Project"
                      name="branchId"
                      value={values.branchId}
                      options={branches?.map((op) => ({
                        value: op.id,
                        label: op.name,
                      }))}
                      onChange={(value) => setFieldValue('branchId', value)}
                      required={true}
                      errors={errors['branchId']}
                      touched={touched['branchId']}
                    />

                    <InputField
                      label="Username"
                      name="username"
                      type="text"
                      required={true}
                      value={values.username}
                      disabled={updatingUser?.id && true}
                      errors={errors['username']}
                      touched={touched['username']}
                    />

                    <InputField
                      label="Email"
                      name="email"
                      type="email"
                      value={values.email}
                      errors={errors['email']}
                      touched={touched['email']}
                    />

                    <InputField
                      label="Phone"
                      name="phone"
                      type="text"
                      value={values.phone}
                      errors={errors['phone']}
                      touched={touched['phone']}
                    />
                    {!updatingUser?.id && (
                      <InputField
                        label="Password"
                        name="password"
                        type="password"
                        value={values.password}
                        required={true}
                        errors={errors['password']}
                        touched={touched['password']}
                      />
                    )}
                    {/* <CheckBox
                      label="Is Shopkeeper?"
                      value={values.isShopkeeper}
                      checked={values.isShopkeeper}
                      onChange={(e) => {
                        // setIsShopkeeper(e.target.checked);
                        setFieldValue('isShopkeeper', e.target.checked);
                        setFieldValue('shopkeeperId', null);
                      }}
                    /> */}
                    <AntdSelectField
                      label="Is Shopkeeper?"
                      name="isShopkeeper"
                      value={values.isShopkeeper}
                      options={booleanOptions}
                      onChange={(value) => {
                        setFieldValue('isShopkeeper', value);
                        setFieldValue('shopkeeperId', null);
                      }}
                    />

                    {values.isShopkeeper && (
                      <AntdSelectField
                        label="Shopkeeper"
                        name="shopkeeperId"
                        value={shopkeepers.find(
                          (op) => op.value === values.shopkeeperId,
                        )}
                        options={shopkeepers}
                        onChange={(value) =>
                          setFieldValue('shopkeeperId', value)
                        }
                        required={true}
                      />
                    )}

                    {!values.isShopkeeper && (
                      <>
                        <AntdSelectField
                          label="Role"
                          name="role"
                          value={values.role}
                          options={Object.keys(userRoles)
                            .filter(
                              (op) =>
                                op !== userRoles.superadmin &&
                                op !== userRoles.shopkeeper &&
                                op !== userRoles.user_manager,
                            )
                            .map((op) => ({
                              value: op,
                              label: op,
                            }))}
                          onChange={(value) => setFieldValue('role', value)}
                          required={true}
                          errors={errors['role']}
                          touched={touched['role']}
                        />

                        <div className="d-flex justify-content-between">
                          <div className="form-group w-50">
                            <FormLabel>Permissions</FormLabel>
                            <Select
                              mode="multiple"
                              style={{ width: '100%' }}
                              placeholder="select one country"
                              value={values.permissions}
                              onChange={(val) =>
                                setFieldValue('permissions', val)
                              }
                              optionLabelProp="label"
                              options={options}
                              optionRender={(option) => (
                                <Space>
                                  <span
                                    role="img"
                                    aria-label={option.data.label}
                                  >
                                    {option.data.emoji}
                                  </span>
                                  {option.data.desc}
                                </Space>
                              )}
                            />
                          </div>
                        </div>
                      </>
                    )}

                    <div className="modal-footer">
                      <MyButton
                        label={updatingUser?.id ? 'Update' : 'Save'}
                        icon="ion-plus"
                        type="success"
                        loading={loading}
                        onClick={handleSubmit}
                      />

                      <button
                        type="button"
                        className="btn btn-danger"
                        data-dismiss="modal"
                        onClick={() => {
                          onClose();
                        }}
                      >
                        <i className="ion-close"></i> Cancel
                      </button>
                    </div>
                  </div>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserModal;
