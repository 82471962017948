import { message } from 'antd';
import axios from 'axios';
import { baseUrl } from '../config/EnVariables';
const token = localStorage.getItem('access_token');
const headers = {
  Authorization: `Bearer ${token}`,
  'Content-Type': 'application/json',
};
if (!token) {
  delete headers.Authorization;
}
// make API calls
const callApi = async (
  endpoint,
  method = 'get',
  data = null,
  params = null,
  dataType = null,
) => {
  const url = `${baseUrl}/${endpoint}`;
  try {
    const response = await axios({
      method,
      url,
      headers: dataType
        ? { ...headers, 'Content-Type': 'multipart/form-data' }
        : headers,
      data,
      params,
    });
    return response;
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        window.localStorage.removeItem('user');
        window.location.reload();
      }
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.status === 403) {
        return window.location.reload();
      }
      if (error.response.status === 404) {
        return message.warning('404: Resource Not Found');
      }
      message.error('400: Bad Request');
    } else if (error.request) {
      // The request was made but no response was received
      return message.warning(
        'Request has been sent to the Server, but response not received',
      );
    } else {
      // Something happened in setting up the request that triggered an Error
      message.warning('Error in Request making');
    }
    throw error;
    // return false;
  }
};
export default callApi;
