import { FormLabel } from 'react-bootstrap';
import Select from 'react-select';
import { Select as AntdSelect } from 'antd';
import { Field } from 'formik';
export const AntdSelectField = ({
  label,
  name,
  options,
  value,
  onChange,
  errors,
  touched,
  required,
  placeholder = 'Search to Select',
  size,
  ...rest
}) => {
  return (
    <div className={size ? '' : 'col-md-4'}>
      <FormLabel>
        {label} {required && <span style={{ color: 'red' }}>*</span>}
      </FormLabel>
      <AntdSelect
        size="large"
        status={errors ? 'error' : ''}
        name={name}
        showSearch
        style={{
          width: '100%',
        }}
        placeholder={placeholder}
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label.toLowerCase() ?? '').localeCompare(
            optionB?.label.toLowerCase() ?? '',
          )
        }
        value={value}
        options={options}
        onChange={onChange}
        {...rest}
      />
      {errors && touched && <p style={{ color: 'red' }}>{errors}</p>}
    </div>
  );
};

export const SelectField = ({
  label,
  name,
  options,
  onChange,
  searchable,
  className = 'form-group',
}) => {
  return (
    <div className={className}>
      <FormLabel>{label}</FormLabel>
      <Select
        name={name}
        options={options}
        onChange={onChange}
        isSearchable={searchable || true}
        placeholder="Select"
      />
    </div>
  );
};

export const CheckBox = ({
  label,
  name,
  value,
  type,
  onChange,
  size,
  ...rest
}) => {
  return (
    <div className={size ? '' : 'col-md-4'}>
      <label> </label>
      <div class={`form-check ${type === 'switch' ? 'form-switch' : ''}`}>
        <input
          className="form-check-input"
          type="checkbox"
          id={name}
          onChange={onChange}
          {...rest}
        />
        {label && (
          <label className="form-check-label" for={name}>
            {label}
          </label>
        )}
      </div>
    </div>
  );
};

export const InputField = ({
  label,
  name,
  required,
  type,
  errors,
  touched,
  size,
  ...rest
}) => {
  return (
    <div className={size ? '' : 'col-md-3'}>
      <div className="form-group">
        <FormLabel>
          {label} {required && <span style={{ color: 'red' }}>*</span>}
        </FormLabel>
        <Field
          type={type}
          name={name}
          required={required}
          {...rest}
          style={{
            border: errors ? `1px solid red` : '',
            borderRadius: 10,
          }}
        />
        {errors && touched && <p style={{ color: 'red' }}>{errors}</p>}
      </div>
    </div>
  );
};
