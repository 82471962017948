import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Shop from '../../Services/shopServices';
import callApi from '../../helpers/callApi';
import AreaUnit from '../../Components/AreaUnit';
import TitleHeader from '../accounts/Components/TitleHeader';
import { branchId, frontBaseUrl, userRoles } from '../../config/EnVariables';
import { LabelHead } from '../../Components/LableHead';
import '../style.css';
import { QRCode, Table } from 'antd';
import CardPrint from './Components/CardPrint';
import Loader from '../../Components/Loader';
import { Authorize, permit } from '../../helpers/Authorize';
import PaymentModal from '../rents/PaymentModal';
import { getTodayDate } from '../../helpers/quick';
import { colors } from '../../config/theme';
import { MyButton } from '../../Components/MyButton';
import ContractModal from './Components/ContractModal';
function ShopDetails(props) {
  const shopId = useParams().id;
  const [shop, setShop] = useState();
  const [shopContracts, setShopContracts] = useState();
  const [shopkeepers, setShopkeepers] = useState();
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [endDate, setEndDate] = useState(getTodayDate());
  const [reason, setReason] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const getShop = async () => {
    setLoading(true);
    await Shop.getOne(shopId)
      .then((data) => {
        setShop(data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getContracts = async () => {
    await callApi(
      `shop-contracts/building-shop-contracts/?shopId=${shopId}&branchId=${branchId}`,
    )
      .then((response) => {
        setShopContracts(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPayments = async () => {
    await callApi(`general-journals/received-payments-by-shop?shopId=${shopId}`)
      .then((response) => {
        setPayments(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getShopShopkeepers = () => {
    Shop.getAllShopkeepers(shopId)
      .then((response) => {
        setShopkeepers(response.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getShop();
    if (shopId) {
      getPayments();
      getContracts();
      getShopShopkeepers();
    }
  }, []);

  const endContract = async (item) => {
    if (item?.state !== 'ACTIVE')
      return setErrorMessage('Contract is already Cancelled');
    if (!endDate || !reason)
      return setErrorMessage('Please mention the date and reason both.');
    setLoading(true);
    const data = {
      ...item,
      cancelledDate: endDate,
      cancellationReason: reason,
    };
    await Shop.cancellContract(data)
      .then((response) => {
        setErrorMessage('');
        setLoading(false);
        getContracts();
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const shopkeepersColumns = [
    {
      title: 'Sno',
      dataIndex: 'sno',
      key: 'sno',
      responsive: ['sm'],
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      sorter: (a, b) => a.address.length - b.address.length,
      sortDirections: ['descend', 'ascend'],
    },
  ];
  const paymentsColumns = [
    {
      title: 'Sno',
      dataIndex: 'sno',
      key: 'sno',
      responsive: ['sm'],
    },
    {
      title: 'Transaction',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Account',
      dataIndex: 'account',
      key: 'account',
      sorter: (a, b) => a.account.length - b.account.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Shopkeeper',
      dataIndex: 'shopkeeper',
      key: 'shopkeeper',
      responsive: ['sm'],
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      responsive: ['sm'],
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Memo',
      dataIndex: 'memo',
      key: 'memo',
      responsive: ['sm'],
    },
  ];
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      responsive: ['sm'],
    },
    {
      title: 'Contract',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Shopkeeper',
      dataIndex: 'shopkeeperName',
      key: 'shopkeeperName',
    },
    {
      title: 'Contract Type',
      dataIndex: 'contractType',
      key: 'contractType',
      sorter: (a, b) => a.contractType.length - b.contractType.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Date',
      dataIndex: 'contractDate',
      key: 'contractDate',
      responsive: ['sm'],
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
    },
    {
      title: 'Status',
      dataIndex: 'state',
      key: 'state',
      render: (_, item) =>
        item.state === 'ACTIVE' ? (
          <p className="badge badge-success">{item.state}</p>
        ) : (
          <p className="badge badge-danger">{item.state}</p>
        ),
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
      responsive: ['sm'],
    },
    {
      title: 'Cancel Contract',
      dataIndex: 'action',
      key: 'action',
      render: (_, item) => (
        <td>
          {permit('CREATE') && item.state === 'ACTIVE' ? (
            <button
              type="button"
              className="btn btn-dark no-print"
              data-toggle="modal"
              data-target="#exampleModal"
              data-whatever="@mdo"
              onClick={() => setErrorMessage('')}
            >
              Cancel Contract
            </button>
          ) : (
            <p className="badge badge-danger">{item.state}</p>
          )}

          <div
            className="modal fade"
            id="exampleModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div
                  className="modal-header"
                  style={{ background: colors.blue }}
                >
                  <h5 className="modal-title" id="exampleModalLabel">
                    Contract Cancellation
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p style={{ color: 'red' }}>{errorMessage}</p>
                  <form>
                    <div className="form-group">
                      <label className="col-form-label">Date:</label>
                      <input
                        type="date"
                        className="form-control"
                        id="date"
                        value={endDate}
                        onChange={(e) =>
                          setEndDate(new Date(e.target.value).toISOString())
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label className="col-form-label">Reason:</label>
                      <textarea
                        className="form-control"
                        id="message-text"
                        onChange={(e) => setReason(e.target.value)}
                      ></textarea>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <MyButton
                    label="Save"
                    icon="ion-plus"
                    type="success"
                    loading={loading}
                    onClick={() => endContract(item)}
                  />

                  <button
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                  >
                    <i className="ion-close"></i> Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </td>
      ),
    },
  ];

  return (
    <>
      {shop && (
        <div className="page-wrapper">
          <div className="content">
            <div className="no-print">
              <TitleHeader title="Shop Details" />
            </div>

            <div className="text-end">
              <a
                class="btn bg-white btn-light mx-1px text-95"
                data-target="#printModal"
                data-toggle="modal"
              >
                <i class="mr-1 fa fa-print text-primary-m1 text-120 w-2"></i>
                Print Card
              </a>
            </div>

            <div
              className="card"
              style={{
                padding: '10px',
                backgroundImage: `url(${require('../../Assets/Images/card.jpg')})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <Loader loading={loading} />
              <div className="d-flex justify-content-between">
                <QRCode
                  errorLevel="H"
                  size={160}
                  iconSize={50}
                  value={`${frontBaseUrl}/shop-details/${shopId}`}
                  icon="/assets/img/logo.png"
                />
                <h6>{shop.name} </h6>
                <h6 style={{ color: '#fff' }}>{shop.shopkeeperName} </h6>
              </div>
              <div className="card-body">
                <span className="badge badge-primary">
                  {shop?.isSold ? 'Sold Shop' : 'Rental Shop'}
                </span>

                <div className="d-flex justify-content-between">
                  <div>
                    <ul>
                      <li>
                        Revenue Account:{' '}
                        <span className="badge badge-info">
                          {shop?.revenueAccountName}
                        </span>
                      </li>
                      <li>
                        Maintenance Account:{' '}
                        <span className="badge badge-info">
                          {shop?.maintenanceAccountName}
                        </span>
                      </li>
                      <li>
                        Services Account:{' '}
                        <span className="badge badge-info">
                          {shop?.servicesAccountName}
                        </span>{' '}
                      </li>
                      <li>
                        Utilities Account:{' '}
                        <span className="badge badge-info">
                          {shop?.utilitiesAccountName}
                        </span>{' '}
                      </li>
                    </ul>
                  </div>
                  <div>
                    <ul className="text-white">
                      <li className="d-flex">
                        Area: <AreaUnit area={shop?.area} />
                      </li>
                      <li>
                        Monthly Rent:
                        <p className="badge">{shop?.monthlyRent}</p>
                      </li>
                      <li>
                        Occupied:
                        {shop?.isOccupied ? 'Yes' : 'No'}
                      </li>
                      <li>
                        Is Spliteable:
                        {shop?.isSpliteable ? 'Yes' : 'No'}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <LabelHead label="Shopkeepers" />
              <Table
                size="small"
                loading={loading}
                columns={shopkeepersColumns}
                dataSource={shopkeepers?.map((item, i) => ({
                  sno: i + 1,
                  name: item.shopkeeperName,
                  phone: item.shopkeeperPhone,
                  address: item.shopkeeperAddress,
                }))}
              />
              {shop?.isSold || shopkeepers?.length == 0 ? null : (
                <>
                  <LabelHead label="Payments">
                    <button
                      disabled={
                        (!Authorize(userRoles.admin_finance) ||
                          !Authorize(userRoles.admin)) &&
                        !permit('CREATE') &&
                        shopkeepers?.length == 0
                      }
                      style={{ color: '#fff' }}
                      className="btn btn-success no-print"
                      data-target="#paymentModal"
                      data-toggle="modal"
                      data-whatever="@mdo"
                    >
                      Create Payment
                    </button>
                  </LabelHead>
                  <PaymentModal
                    shop={shop}
                    getData={getPayments}
                    shopkeepers={shopkeepers}
                  />
                  <Table
                    size="small"
                    loading={loading}
                    columns={paymentsColumns}
                    dataSource={payments?.map((item, i) => ({
                      sno: i + 1,
                      name: item.name,
                      date: item.date,
                      account: item.lines[0]?.accountName,
                      shopkeeper: item.shopkeeperName,
                      type: item.lines[1]?.accountName.includes('Revenue')
                        ? 'RENT'
                        : item.lines[1]?.accountName.includes('Maintenance')
                        ? 'MAINTENANCE'
                        : item.lines[1]?.accountName.includes('Services')
                        ? 'SERVICES'
                        : item.lines[1]?.accountName.includes('Utilities')
                        ? 'UTILITIES'
                        : 'OTHER',
                      amount: item.amount?.toLocaleString('en-US'),
                      memo: item.lines[0]?.memo,
                    }))}
                  />
                </>
              )}

              <LabelHead label="Contracts">
                <button
                  className="btn btn-success no-print"
                  disabled={
                    shopContracts?.some((op) => op.state === 'ACTIVE')
                      ? true
                      : false
                  }
                  data-target="#contractModal"
                  data-toggle="modal"
                  data-whatever="@mdo"
                >
                  Make a Contract
                </button>
              </LabelHead>
              <ContractModal getContracts={getContracts} shop={shop} />
              <Table
                size="small"
                loading={loading}
                columns={columns}
                dataSource={shopContracts}
              />
            </div>
          </div>
          <CardPrint shop={shop} shopId={shopId} />
        </div>
      )}
    </>
  );
}

export default ShopDetails;
