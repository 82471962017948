import { Field, Formik } from 'formik';

import React, { useContext, useState } from 'react';
import { AuthContext } from '../Auth/Context/AuthContext';
import { loginValidation } from '../config/Validations';
import User from '../Services/userServices';
import { browserData } from '../config/EnVariables';
import { Spin, message } from 'antd';
import { colors } from '../config/theme';
function Login(props) {
  const { setUser, setGlobalProject } = useContext(AuthContext);
  const [responseMessage, setResponseMessage] = useState('');
  const [isLoading, setIsloading] = useState(false);

  const logginIn = async (values) => {
    setIsloading(true);
    await User.signin(values.email, values.password)
      .then(async (response) => {
        setIsloading(false);
        const token = response.data;
        localStorage.setItem(browserData.access_token, token);
        await User.getLoggedUser(token).then((user) => {
          const jsonUser = JSON.stringify(user);
          localStorage.setItem(browserData.user, jsonUser);
          localStorage.setItem(browserData.globalProject, user.branchId);
          localStorage.setItem(browserData.globalProjectName, user.branchName);
          setUser(user);
          setGlobalProject(user.branchId);
          user && window.location.reload();
        });
        setResponseMessage(response?.message);
      })
      .catch((err) => {
        setIsloading(false);
        setResponseMessage('Invalid username or password');
      });
  };

  return (
    <div className="main-wrapper">
      <div className="account-content">
        <div
          className="login-wrapper"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${require('../Assets/Images/loginBg.gif')})`,
            backgroundSize: 'cover',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div className="login-content col-md-3 col-lg-3">
            <div
              className="login-userset"
              style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${require('../Assets/Images/login_bg.jpg')})`,
                backgroundSize: 'cover',
                borderRadius: 20,
                padding: 10,
              }}
            >
              <div className="login-userheading text-center">
                <img
                  src="/assets/img/logo.png"
                  alt="img"
                  width={200}
                  height={200}
                />
              </div>
              <p style={{ color: 'red' }}>{responseMessage}</p>

              <Formik
                enableReinitialize
                initialValues={{ email: '', password: '' }}
                onSubmit={logginIn}
                validationSchema={loginValidation}
              >
                {({ errors, touched, handleSubmit }) => (
                  <>
                    <div className="form-login">
                      <label style={{ color: colors.primary }}>Email</label>
                      <div className="form-addons">
                        <Field
                          style={{ background: 'none', color: colors.primary }}
                          type="email"
                          placeholder="Enter your email or username"
                          name="email"
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              event.preventDefault();
                              handleSubmit();
                            }
                          }}
                        />
                        <img src="/assets/img/icons/mail.svg" alt="img" />
                      </div>
                      {errors.email && touched.email && (
                        <p style={{ color: 'red' }}>{errors.email}</p>
                      )}
                    </div>

                    <div className="form-login">
                      <label style={{ color: colors.primary }}>Password</label>
                      <div className="pass-group">
                        <Field
                          style={{ background: 'none', color: colors.primary }}
                          type="password"
                          className="pass-input"
                          name="password"
                          placeholder="***********"
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              event.preventDefault();
                              handleSubmit();
                            }
                          }}
                        />
                        <span className="fas toggle-password fa-eye-slash"></span>
                      </div>
                      {errors.password && touched.password && (
                        <p style={{ color: 'red' }}>{errors.password}</p>
                      )}
                    </div>
                    <div className="form-login">
                      <div className="alreadyuser">
                        <h4>
                          <a
                            className="hover-a"
                            onClick={() =>
                              message.info('Feature will be added')
                            }
                          >
                            Forgot Password?
                          </a>
                        </h4>
                      </div>
                    </div>
                    <div className="form-login">
                      <button
                        className="btn btn-login"
                        onClick={handleSubmit}
                        disabled={isLoading}
                      >
                        {isLoading && <Spin />}
                        GO
                      </button>
                    </div>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
